import {deepCopy, isDeepEqual} from "../../../../blinkpay/UtilsLib.js";
import {CycleUnit} from "../../../../client/state/RecurringPaymentStoreObject.js";
import {apiMerchantEditDonationOffer} from "../../../../client/state/DonationOfferStore.js";
import {BaseEditorState} from "../../user-experience/components/BaseEditorState.js";


// Make a proxy class that keeps track of what was changed
// TODO this is a pattern still in progress, arrays in value for instance are not safe to edit
export function makeChangeProxy(obj) {
    const scratchObj = deepCopy({}, obj);
    return new Proxy(scratchObj, {
        get: (scratchObj, key, receiver) => {
            if (key === "valueOf") {
                return () => {
                    const payload = {};
                    for (const [key, value] of Object.entries(scratchObj)) {
                        if (!isDeepEqual(value, obj[key])) {
                            payload[key] = value;
                        }
                    }
                    return payload;
                };
            }
            return Object.hasOwn(scratchObj, key) ? scratchObj[key] : obj[key];
        },
        set: (scratchObj, key, value) => Reflect.set(scratchObj, key, value),
    })
}


export class DonationOfferEditorState extends BaseEditorState {
    static ALLOWED_FREQUENCIES = CycleUnit.all().filter(cu => cu != CycleUnit.DAY);

    constructor(donationOffer) {
        super();
        this.donationOffer = donationOffer;
        this.load();
    }

    update(options, dispatch = true) {
        for (const [key, value] of Object.entries(options)) {
            this.value[key] = value;
        }
        //Object.assign(this.value, options);
        if (dispatch) {
            this.dispatchChange();
        }
    }

    getAllowedFrequencies() {
        return this.constructor.ALLOWED_FREQUENCIES;
    }

    getDefaultFrequency() {
        return this.value.defaultFrequency;
    }

    setDefaultFrequency(frequency, dispatchChange = true) {
        if (!this.hasFrequencyEnabled(frequency)) {
            return;
        }
        this.update({defaultFrequency: frequency}, dispatchChange);
    }

    hasFrequencyEnabled(frequency) {
        for (const value of this.value.frequencyOptions) {
            if (value == frequency) {
                return true;
            }
        }
        return false;
    }

    setFrequencyEnabled(frequency, enabled) {
        if (!enabled && this.value.frequencyOptions.length === 1) {
            this.dispatchChange(); // Undo the change, can't disable the very last option.
            return;
        }

        // We want to preserve the order, regenerate from scratch
        let newValues = [];
        for (const value of this.getAllowedFrequencies()) {
            const isEnabled = (value == frequency) ? enabled : this.hasFrequencyEnabled(value);
            if (isEnabled) {
                newValues.push(value);
            }
        }

        this.value.frequencyOptions = newValues;

        // Disabling the default changes the default to the first value
        if (this.getDefaultFrequency() == frequency && !enabled) {
            this.setDefaultFrequency(this.value.frequencyOptions[0], false);
        }

        this.dispatchChange();
    }

    setPredefinedAmount(index, value) {
        this.value.predefinedAmounts[index] = value.getAmount();
        this.dispatchChange();
    }

    removePredefinedAmount(index) {
        // TODO Add functionality to remove the default recommended amount
        this.value.predefinedAmounts = Array.from(this.value.predefinedAmounts);
        this.value.predefinedAmounts.splice(index, 1);
        if (index <= this.value.defaultPredefinedAmountIndex && this.value.defaultPredefinedAmountIndex > 0) {
            this.value.defaultPredefinedAmountIndex -= 1;
        }
        this.dispatchChange();
    }

    addNewPredefinedAmount() {
        this.value.predefinedAmounts.push(0);
        this.dispatchChange();
    }

    valueFromObject() {
        const value = makeChangeProxy(this.donationOffer);
        value.frequencyOptions = value.frequencyOptions.map(value => CycleUnit.fromValue(value));
        value.defaultFrequency = this.donationOffer.defaultFrequency || value.frequencyOptions[0];
        return value;
    }

    load() {
        this.value = this.valueFromObject();
    }

    isEdited() {
        return !isDeepEqual(this.value.valueOf(), this.valueFromObject().valueOf());
    }

    async saveChanges() {
        const payload = this.value.valueOf();
        payload.recurringOfferId = this.donationOffer.id;
        await apiMerchantEditDonationOffer(payload);
        this.discardChanges();
    }
}
