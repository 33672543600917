import {UI} from "../../../stem-core/src/ui/UIBase";
import {PanelEditor} from "./panels/PanelEditor.jsx";
import {AVAILABLE_PANELS, getMerchantPanelsForVersion} from "./panels/Helpers";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {NoClickPropagate} from "../../ui/SimpleTable";
import {CENTER_COLUMN, LEFT_COLUMN, RESOURCE_ACTIONS_COLUMN} from "../../common/theme/TableStyle";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {apiEditMerchantPanel, MerchantPanelStore} from "../../../client/state/merchant/MerchantPanelStore";
import {InputConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {CreatePanelModal, DeletePanelModal} from "./components/Modals.jsx";
import {Level} from "../../../stem-core/src/ui/Constants";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {
    getEditingOrLiveSettingsVersion,
    MerchantSDKSettingsStore
} from "../../../client/state/merchant/MerchantSDKSettingsStore";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {NON_BREAKING_SPACE} from "../../../stem-core/src/base/Formatting.js";
import {DashboardLabel} from "../../common/DashboardLabel.jsx";
import {LabelFilter} from "../../ui/LabelFilter.jsx";


@autoredraw(MerchantPanelStore, MerchantSDKSettingsStore)
export class MerchantPanelsPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: MerchantPanelStore,
            title: "Panels",
            useAliasInURL: true,
            description: () => <div>
                Edit your panels here. Panels are primarily delivered on your website
                by the Blink SDK through <Link href="/journeys">user{NON_BREAKING_SPACE}journey</Link> logic.
                You can create multiple panels of the same type here and use them in different
                journeys to address specific segments of visitors or to test out ideas.
            </div>,
        }
    }

    updateEntryById(entryId) {
        this.selectedEntry = getMerchantPanelsForVersion(this.options.merchant).find(panel => panel.alias == entryId);
    }

    async promptCreatePanelModal() {
        const panel = await CreatePanelModal.prompt({
            sdkSettings: getEditingOrLiveSettingsVersion(this.options.merchant),
        });
        if (panel != null) {
            this.goToEntry(panel);
        }
    }

    async promptDeletePanelModal(panel) {
        await DeletePanelModal.prompt({panel});
    }

    async promptRenamePanelModal(panel) {
        // TODO @Mihai passing in a confirmAction seems to be an antipattern
        const response = await InputConfirmationModal.prompt({
            title: `Rename panel ${panel}`,
            inputLabel: "Enter a new name for this panel",
            inputPlaceholder: "Name",
            inputInitialValue: panel.name,
            confirmAction: (modal) => modal.resolveWithAsyncAction(() => apiEditMerchantPanel({
                sdkSettingsId: panel.settingsVersionId,
                panelId: panel.id,
                name: modal.input.getValue(),
            })),
        });
        if (response != null) {
            this.redraw();
        }
    }

    renderTypeAndLabels(panel) {
        const type = AVAILABLE_PANELS[panel.options.type] || panel.options.type;

        const labels = panel.dashboardOptions.labels;
        return [
            type,
            labels && <div>
                {labels.map(label => <DashboardLabel label={label} autocolor />)}
            </div>,
        ]
    }

    render() {
        if (this.selectedEntry) {
            return <PanelEditor parent={this} panel={this.selectedEntry} key={`${this.selectedEntry.id}-${this.selectedEntry.settingsVersionId}`}/>;
        }

        const labelFilterOptions = {
            merchant: this.options.merchant,
        }

        // TODO @cool would be great to have an inline preview like Denis did.
        const allEntries = getMerchantPanelsForVersion(this.options.merchant);
        const filteredEntries = LabelFilter.filterEntries(allEntries, this.labelFilter, labelFilterOptions);

        const columns = [
            ["Name", panel => panel.name, LEFT_COLUMN],
            ["Alias", panel => panel.alias],
            ["Type", panel => this.renderTypeAndLabels(panel), CENTER_COLUMN],
            ["Actions", panel => !panel.isBasePanel() && <NoClickPropagate>
                {<Button label="Rename" onClick={() => this.promptRenamePanelModal(panel)}/>}
                {<Button level={Level.SECONDARY} label="Delete" onClick={() => this.promptDeletePanelModal(panel)}/>}
            </NoClickPropagate>, RESOURCE_ACTIONS_COLUMN],
        ];

        return [
            this.renderTitle(),
            <div style={{paddingBottom: 12}}>
                <LabelFilter
                    ref="labelFilter"
                    {...labelFilterOptions}
                    onChange={() => this.redraw()}
                    style={{display: "inline-block"}}
                />
                <Button onClick={() => this.promptCreatePanelModal()} label="New Panel" />
            </div>,
            this.makeTable(filteredEntries, columns),
        ];
    }
}
