import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTitle} from "../common/DashboardTitle";
import {BlinkInputField} from "../common/Input";
import {Select} from "../../stem-core/src/ui/input/Input";
import {Timezone} from "../../stem-core/src/localization/Timezone.js";
import {dashboardSettings} from "../misc/DashboardSettings";

export class DashboardSettingsPage extends UI.Element {
    setDateFormat(dateFormat) {
        dashboardSettings.dateFormat = dateFormat;
    }

    setQueryTimezone(timezone) {
        dashboardSettings.queryTimezone = timezone;
    }

    setDisplayTimezone(timezone) {
        dashboardSettings.displayTimezone = timezone;
    }

    render() {
        return [
            <DashboardTitle
                title="Dashboard Settings"
                description="Control how this dashboard works, how data is queried and displayed by default."
            />,
            <div>
                <BlinkInputField label="Filters Timezone" helpInfo="The timezone in which queries are made from our servers">
                    <Select
                        options={Timezone.all()}
                        initialValue={dashboardSettings.queryTimezone}
                        serializer={timezone => timezone.getInputName()}
                        onChange={(e, input) => this.setQueryTimezone(input.getValue())} />
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Dashboard Timezone" helpInfo="The timezone in which data is displayed in the dashboard.">
                    <Select
                        options={Timezone.all()}
                        initialValue={dashboardSettings.displayTimezone}
                        serializer={timezone => timezone.getInputName()}
                        onChange={(e, input) => this.setDisplayTimezone(input.getValue())} />
                </BlinkInputField>
            </div>,
            // <div>
            //     <BlinkInputField label="Date format">
            //         <Select options={[
            //             "MMM DD, YYYY, h:mm AA",
            //             "MMM DD, YYYY, HH:mm.ss",
            //         ]}
            //         initialValue={dashboardSettings.dateFormat}
            //         onChange={(e, input) => this.setDateFormat(input.getValue())} />
            //     </BlinkInputField>
            //     <BlinkInputField label="Date format options" helpInfo={"The year can be omitted in dated in the current year."}>
            //         <LabeledCheckbox label={"Always include the year"} initialValue={false} />
            //     </BlinkInputField>
            // </div>,
            // <div>
            //     <BlinkInputField label="Theme">
            //         <RadioButtonsInput values={["Light", "Dark"]}
            //                       onSelect={(theme) => console.warn("Change theme", theme)}/>
            //     </BlinkInputField>
            // </div>
        ]
    }

    onMount() {
        this.attachChangeListener(dashboardSettings, () => this.redraw());
    }
}