import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {SaveOrDiscardButtons} from "../../../common/input/SaveOrDiscardButtons.jsx";
import {DonationOfferEditorState} from "./DonationOfferEditorState.js";
import {DonationFrequencyInput} from "./DonationFrequencyInput.jsx";
import {DonationAmountOptionsInput} from "./DonationAmountOptionsInput.jsx";
import {DonationOfferMiscInput} from "./DonationOfferMiscInput.jsx";


export class DonationOfferEditor extends UI.Element {
    setOptions(options) {
        super.setOptions(options);
        this.state = DonationOfferEditorState.instantiate(this.options.donationOffer, this);
    }

    render() {
        const {state} = this;

        return [
            <SaveOrDiscardButtons state={state} />,
            <DonationAmountOptionsInput state={state} />,
            <DonationFrequencyInput state={state} />,
            <DonationOfferMiscInput state={state} />,
        ]
    }

    onMount() {
        // TODO @branch should be done in set Options
        this.attachChangeListener(this.state, () => this.redraw());
    }
}
