import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {DashboardSection} from "../../../common/DashboardSection.jsx";
import {SimpleTable} from "../../../ui/SimpleTable.jsx";
import {DashboardRadio} from "../../../../core/ui/input/radio/RadioListInput.jsx";


export class DonationFrequencyInput extends DashboardSection {
    getDefaultOptions() {
        return {
            title: "Allowed frequencies",
            description: "You can select what type of donations you want to accept (one-time or recurring). We recommend nudging your donors to be recurring by default."
        }
    }

    render() {
        const {state} = this.options;

        const tableColumns = [
            ["Frequency", (frequency) => <CheckboxInput
                label={frequency}
                initialValue={state.hasFrequencyEnabled(frequency)}
                onChange={(value, input) => {
                    state.setFrequencyEnabled(frequency, value);
                    input.setValue(state.hasFrequencyEnabled(frequency)); // This might be rejected
                }}
            />],
            ["Default", frequency => <DashboardRadio
                selected={state.getDefaultFrequency() == frequency}
                disabled={!state.hasFrequencyEnabled(frequency)}
                onClick={() => state.setDefaultFrequency(frequency)}
            />],
        ]

        return [
            <SimpleTable
                style={{width: 240}}
                entries={state.getAllowedFrequencies()}
                columns={tableColumns}
            />
        ]
    }
}
