import {field} from "../../../stem-core/src/state/StoreField.js";
import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store.js";
import {TaskStatus} from "../misc/GenericEnums.js";
import {apiClient} from "../../connection/BlinkApiClient.js";

class MerchantRecurringTask extends StoreObject {
    @field("Merchant") merchant;
    @field(Object) publicConfig;
    @field(Number) sleepMinutes;
    @field(Boolean) isActive;
    @field(String) provider;
    @field(String) type;
    @field(Date) nextScheduled;
    @field(TaskStatus) status;
    @field(Object) statusDetails;
    @field(Date) enqueuedAt;
    @field(Date) startedAt;
    @field(Date) updatedAt;
    @field(Date) finishedAt;
    @field(String) output;
}

export const MerchantRecurringTaskStore = MakeStore("MerchantRecurringTask", MerchantRecurringTask);

export function apiMerchantRestartTask(task) {
    return apiClient.post("/merchant/restart_custom_task", {
        taskId: task.id,
    });
}
