// TODO You're a primitive if you think this file should be called Primitives
import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {InputableElement, TextInput} from "../../../../stem-core/src/ui/input/Input.jsx";
import {BlinkInputField, BlinkInputFieldStyle} from "../../../common/Input.jsx";
import {DashboardBaseInput} from "../../../../core/ui/input/BaseInput.js";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../../../stem-core/src/decorators/Style.js";
import {ArrayInput} from "../../../common/input/ArrayInput.jsx";
import {deepGetAttr, deepSetAttr, isDeepEqual, toArray} from "../../../../blinkpay/UtilsLib.js";
import {Level, Size} from "../../../../stem-core/src/ui/Constants.js";
import {Button} from "../../../../stem-core/src/ui/button/Button.jsx";
import {MakeInfoElement} from "../../../common/InfoTooltip.jsx";
import {HTMLEditor} from "../../../common/HTMLEditor.jsx";

// TODO @cleanup the word JSON should be deleted almost everywhere in this folder
class BlinkSDKInputFieldStyle extends BlinkInputFieldStyle {
    @styleRule
    inputFieldLabelDisabled = {
        color: "#AAA",
    };

    @styleRule
    editButton = {
        borderRadius: 3,
        padding: "2px 6px",
    };
}

// TODO @branch rename
@registerStyle(BlinkSDKInputFieldStyle)
export class BlinkSDKInputField extends BlinkInputField {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            state: null,
            stateKey: [], // The array of keys that we use to access within the state object
            optional: false,
            defaultValue: null,
            initialValue: null,
            InputClass: TextInput,
            inputOptions: {},
            editLabel: "Edit",
        };
    }

    setOptions(options) {
        super.setOptions(options);
        const {state, stateKey} = this.options;
        if (state) {
            this.options.initialValue = deepGetAttr(state, toArray(stateKey));
        }
    }

    render() {
        const {styleSheet} = this;
        const {label, editLabel, helpInfo, InputClass, inputOptions, initialValue, defaultValue, optional, editCallback} = this.options;
        const isEnabled = !optional || (this.getValue() != null);

        const handleChange = () => {
            const {state, stateKey} = this.options;
            const value = this.getValue();

            if (state) {
                deepSetAttr(state, toArray(stateKey), value);
                state.dispatchChange();
            }

            // Pass along the change from the raw input in a standard format
            this.dispatch("change", this.getValue(), this);
        };

        // TODO @cleanup should only be onChange anywhere
        inputOptions.onChange = handleChange;
        if (InputClass.prototype instanceof InputableElement) {
            inputOptions.onInput = handleChange;
        }
        if (InputClass === HTMLEditor) {
            inputOptions.onAceChange = handleChange;
        }

        return [
            <div>
                <span className={styleSheet.inputFieldLabel + (isEnabled ? "" : styleSheet.inputFieldLabelDisabled)}>
                     {optional ?
                         <CheckboxInput
                             ref="enabledCheckbox"
                             initialValue={isEnabled}
                             label={label}
                             onChange={handleChange}
                         />
                         : label}
                    {MakeInfoElement(helpInfo)}
                </span>
                {editCallback && <Button
                    level={Level.SECONDARY}
                    size={Size.SMALL}
                    className={styleSheet.editButton}
                    label={editLabel}
                    onClick={() => editCallback(this)}
                />}
            </div>,
            <InputClass
                ref="input"
                {...inputOptions}
                disabled={!isEnabled}
                initialValue={initialValue ?? defaultValue}/>,
        ];
    }

    getValue() {
        if (this.enabledCheckbox && !this.enabledCheckbox.getValue()) {
            return undefined;
        }
        if (!this.input) {
            return this.options.initialValue;
        }
        return this.input.getValue();
    }
}

// A base input where the value is a plain object.
export class JSONObjectBaseInput extends DashboardBaseInput {
    setOptions(options) {
        options.initialValue = options.initialValue || {};
        return super.setOptions(options);
    }

    setField(key, value) {
        this.value[key] = value;
        this.setAndDispatchValue(this.value);
    }

    getField(key, defaultValue) {
        return this.getValue()?.[key] ?? defaultValue;
    }
}

export class SDKArrayInput extends ArrayInput {
    setOptions(options) {
        options.initialValue = toArray(options.initialValue);
        super.setOptions(options);
    }

    setValue(newValue) {
        newValue = toArray(newValue);
        return super.setValue(newValue);
    }

    isEqual(valueA, valueB) {
        return isDeepEqual(toArray(valueA), toArray(valueB));
    }

    getValue() {
        // Match the SDK rules to save space over always having explicit arrays
        const value = super.getValue();
        if (value.length === 0) {
            // TODO @branch see that this isn't saved
            return undefined;
        }
        if (value.length === 1) {
            return value[0];
        }
        return value;
    }
}
