import {UI} from "../../stem-core/src/ui/UIBase";

export class BorderedLabel extends UI.Primitive("span") {
    getDefaultOptions() {
        return {
            borderWidth: 1,
        }
    }

    extraNodeAttributes(attr) {
        const {borderWidth, borderColor} = this.options;
        attr.setStyle({
            padding: "1px 8px",
            paddingTop: 3,
            border: `${borderWidth}px solid ${borderColor || ""}`,
        });
    }
}
