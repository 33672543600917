import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {SubscriptionCoverageStore} from "../../../client/state/SubscriptionCoverageStore.js";
import {SubscriptionCoverageMultiselectInput} from "../../common/input/SubscriptionCoverageMultiselectInput.jsx";
import {SubscriptionBenefitStore} from "../../../client/state/SubscriptionBenefitStore.js";
import {BaseInputElement} from "../../../stem-core/src/ui/input/BaseInputElement.js";
import {BlinkInputField} from "../../common/Input.jsx";
import {DashboardLabel} from "../../common/DashboardLabel.jsx";


// TODO implement a multi-choice filter with search that generates labels
export class SubscriptionBenefitSetInput extends BaseInputElement {
    getValue() {
        const {value} = this;
        if (!value && value.length === 0) {
            return null;
        }
    }

    render() {
        return [
        ]
    }
}


export class AudienceSubscriptionsFilter extends UI.Element {
    getValue() {
        return {
            subscriptionCoverageIds: this.subscriptionCoverageFilter.getSelectedSubscriptionCoverageIds(),
            benefitIds: this.options?.initialValue?.benefitIds,
        }
    }

    render() {
        const {audience, initialValue} = this.options;
        const {subscriptionCoverageIds, subscriptionOfferIds, benefitIds} = initialValue || {};
        const subscriptionCoverageDefaultValue = (subscriptionCoverageIds || []).map(id => SubscriptionCoverageStore.get(id));
        const benefitsDefaultValue = (benefitIds || []).map(id => SubscriptionBenefitStore.get(id));
        return [
            <div>
                Is subscribed to one of
            </div>,
            <SubscriptionCoverageMultiselectInput
                ref="subscriptionCoverageFilter"
                merchant={audience.merchant}
                initialValue={subscriptionCoverageDefaultValue}
                includeInactive={true}
            />,
            (benefitsDefaultValue.length > 0) && <BlinkInputField label="Has a subscription with ANY of these benefits">
                {benefitsDefaultValue.map(benefit => <DashboardLabel autocolor label={benefit} tooltip={benefit.description}/>)}
            </BlinkInputField>
        ];
    }
}