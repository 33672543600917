import {UI} from "../../../stem-core/src/ui/UIBase";
import {
    apiMerchantCreateEmailCampaign,
    MerchantEmailCampaignStore
} from "../../../client/state/merchant/MerchantEmailCampaignStore";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {NoClickPropagate} from "../../ui/SimpleTable";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {BlinkInputField} from "../../common/Input";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {MerchantEmailCampaignInstancePage} from "./MerchantEmailCampaignInstancePage";
import {TimeUnit} from "../../../stem-core/src/time/Duration";
import {StemDate} from "../../../stem-core/src/time/Date";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";

class CreateEmailCampaignDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(),
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve(this.getValue()),
        };
    }

    getTitle() {
        const {baseCampaign} = this.options;
        return baseCampaign ? "Clone email campaign" : "Create new email campaign";
    }

    getDescription() {
        const {baseCampaign} = this.options;
        return [
            baseCampaign ?
                <p>Create a new campaign, that uses the email templates of campaign {baseCampaign.name}, aimed initially for the audience {baseCampaign.audience}</p> :
                <p>Create a new email campaign, starting from an empty template. If you want to use an existing template, clone a campaign.</p>,
            <p>You can edit the email you want to send, and can send different emails to different subsections of your audience.</p>
        ]
    }

    getValue() {
        const {baseCampaign} = this.options;
        const audience = this.audienceInput.getValue();
        const suggestedSendDate = new StemDate().add(TimeUnit.DAY).round(TimeUnit.HOUR);
        return {
            name: this.nameInput.getValue().trim(),
            audienceId: audience.id,
            scheduledSendDate: suggestedSendDate,
            ...(baseCampaign && {fromEmailCampaignId: baseCampaign.id}),
        }
    }

    render() {
        const {merchant, baseCampaign} = this.options;
        const audienceOptions = MerchantAudienceStore.filterBy({merchantId: merchant.id});
        const defaultAudience = baseCampaign?.audience || audienceOptions[0];

        return [
            <div>
                <BlinkInputField label="Audience">
                    <Select ref="audienceInput" initialValue={defaultAudience} options={audienceOptions} onChange={() => this.resetFromAudience()}/>
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Campaign name">
                    <TextInput style={{minWidth: 320}} ref="nameInput" placeholder={"Can be the date (ex \"Jan 16th\")"}/>
                </BlinkInputField>,
            </div>,
        ];
    }
}


@autoredraw(MerchantEmailCampaignStore, MerchantAudienceStore)
export class MerchantEmailCampaignsPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: MerchantEmailCampaignStore,
            title: "Email Campaigns",
            description: "Individual email messages to be sent to a target audience.",
        }
    }
    
    async createEmailCampaign(baseCampaign) {
        const {merchant} = this.options;
        const request = await CreateEmailCampaignDialog.prompt({merchant, baseCampaign});
        if (!request) {
            return;
        }
        const emailCampaign = await apiMerchantCreateEmailCampaign(request);
        emailCampaign && this.goToEntry(emailCampaign);
    }

    render() {
        const {merchant} = this.options;
        if (this.selectedEntry) {
            return <MerchantEmailCampaignInstancePage parent={this} emailCampaign={this.selectedEntry} />
        }

        const emailCampaigns = MerchantEmailCampaignStore.filterBy({merchantId: merchant.id}).sort((a, b) => b.id - a.id);
        const audiences = MerchantAudienceStore.filterBy({merchantId: merchant.id});

        const columns = [
            ["Name", campaign => campaign.name],
            ["Audience", campaign => campaign.audience.name],
            ["Sent emails", campaign => campaign.numSentEmails],
            ["Read emails", campaign => campaign.numReadEmails],
            ["Bounced emails", campaign => campaign.numBouncedEmails],
            ["Failed emails", campaign => campaign.numFailedEmails],
            ["", campaign => <NoClickPropagate>
                <Button onClick={() => this.createEmailCampaign(campaign)}>Clone</Button>
                {/*<Button onClick={() => this.archiveCampaign(campaign)}>Archive</Button>*/}
                {/*<Button onClick={() => this.deleteCampaign(campaign)}>Delete</Button>*/}
            </NoClickPropagate>]
        ]

        return [
            this.renderTitle(),
            audiences.length > 0 ?
                <div>
                    <Button onClick={() => this.createEmailCampaign()} label="New campaign"/>
                </div> :
                <div style={{fontSize: 16}}>
                    You need to first create an audience before defining a campaign email.
                </div>,

            this.makeTable(emailCampaigns, columns, {style: {paddingTop: 16}}),
        ]
    }
}
