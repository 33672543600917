import {UI} from "../../stem-core/src/ui/UIBase.js";
import {isDeepEqual} from "../../blinkpay/UtilsLib.js";
import {PopupInput} from "./input/PopupInput.jsx";
import {LabelSetInput} from "../../core/ui/input/label/LabelSetInput.jsx";
import {BlinkInputField} from "../common/Input.jsx";
import {InputContainer} from "./input/Base.jsx";
import {ChevronDownIcon} from "../../core/ui/SVGElements.jsx";
import {GetLabelManager} from "../../client/state/misc/LabelManager.js";
import {dashboardSettings} from "../misc/DashboardSettings.js";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw.js";
import {MerchantPanelStore} from "../../client/state/merchant/MerchantPanelStore.js";
import {MerchantUserJourneyStore} from "../../client/state/merchant/MerchantUserJourneyStore.js";


@autoredraw(dashboardSettings, MerchantPanelStore, MerchantUserJourneyStore)
export class LabelFilter extends UI.Element {
    constructor(options) {
        super(options);
        this.load();
    }

    load() {
        const {labelFilterSettings} = dashboardSettings; // TODO This is not merchant specific

        this.allLabels = GetLabelManager(this.options.merchant).getLabels();
        this.selectedLabels = this.allLabels.filter(label => labelFilterSettings?.[label] ?? label !== "Archive");
        this.showUnlabeled = labelFilterSettings?.showUnlabeled ?? true;
    }

    async promptLabelFilterPopup() {
        await PopupInput.prompt(
            LabelSetInput,
            {
                allLabels: this.allLabels,
                initialValue: this.selectedLabels,
                unlabeledCheckboxValue: this.showUnlabeled,
                onChange: (event, input) => {
                    const selectedLabelsSet = new Set(input.getValue());
                    const showUnlabeled = input.getUnlabeledValue();
                    // Save to local storage
                    // Will trigger a redraw from the change listener on dashboardSettings
                    dashboardSettings.labelFilterSettings = {
                        ...Object.fromEntries(this.allLabels.map(label => [label, selectedLabelsSet.has(label)])),
                        showUnlabeled,
                    }
                    this.dispatchChange();
                }
            },
            {
                anchor: this.popupAnchor
            }
        );
    }

    enqueueRedraw() {
        // Mean an autoredraw was triggered, we'll reload the state from dashboardSettings
        // TODO This is a bit complex to maintain, simplify
        this.load();
        super.enqueueRedraw();
    }

    render() {
        const allFiltersSelected = this.showUnlabeled && isDeepEqual(this.selectedLabels, this.allLabels);

        return <BlinkInputField label="Filter by labels">
            <InputContainer ref="popupAnchor">
                <div onClick={() => this.promptLabelFilterPopup()} style={{display: "flex"}}>
                    <div>{allFiltersSelected ? "No filters" : <strong>Enabled</strong>}</div>
                    <ChevronDownIcon size={18} />
                </div>
            </InputContainer>
        </BlinkInputField>;
    }

    static filterEntries(entries, labelFilter, defaultOptions) {
        if (!labelFilter) {
            labelFilter = new this(defaultOptions); // Load the default values from defaultOptions
        }

        const {selectedLabels, showUnlabeled} = labelFilter;

        return entries.filter(entry => {
            const entryLabels = entry.dashboardOptions.labels;

            if (!entryLabels) {
                return showUnlabeled;
            }

            // return true if array intersection is non-empty
            return selectedLabels.some(label => entryLabels.includes(label));
        });
    }
}