import {UI} from "../../../stem-core/src/ui/UIBase";
import {apiCreateEmailTemplate, EmailTemplateStore, EmailTemplateType} from "../../../client/state/EmailTemplateStore";
import {EmailTemplateEditor} from "./EmailTemplateEditor";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {apiMerchantEditEmailCampaign, MerchantEmailCampaignStore} from "../../../client/state/merchant/MerchantEmailCampaignStore";
import {MerchantEmailSenderStore} from "../../../client/state/merchant/MerchantEmailSender";
import {MerchantEmailingSettingsStore} from "../../../client/state/merchant/MerchantEmailingSettingsStore";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {TopLevelTabArea} from "../../common/theme/TabAreaStyle";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {BlinkInputField} from "../../common/Input";
import {Select, TextInput} from "../../../stem-core/src/ui/input/Input";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {EmailCampaignSendingPanel} from "./EmailCampaignSendingPanel";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";

class CreateEmailCampaignTemplate extends ConfirmationModal {
    getDefaultOptions(options) {
        const {baseTemplate} = options;
        return {
            ...super.getDefaultOptions(),
            title: "New email template email",
            description: baseTemplate ? `The new template will be a copy of ${baseTemplate.name}` : "Create a new empty email template",
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve(this.getValue()),
        };
    }

    getValue() {
        const {baseTemplate, emailCampaign} = this.options;
        return {
            type: EmailTemplateType.CAMPAIGN,
            campaignId: emailCampaign.id,
            merchantId: emailCampaign.merchantId,
            name: this.nameInput.getValue().trim(),
            fromTemplateId: baseTemplate?.id,
        };
    }

    render() {
        return [
            <div>
                <BlinkInputField label="Name (used only to identify the template)">
                    <TextInput style={{minWidth: 360}} ref="nameInput"/>
                </BlinkInputField>
            </div>,
        ];
    }
}

class EmailCampaignTemplateEditor extends DashboardSelectorPage {
    getEmailTemplatesTable(allEmailTemplates) {
        const columns = [
            ["Name", emailTemplate => emailTemplate.name],
            ["Subject", emailTemplate => emailTemplate.subject],
        ];

        return this.makeTable(allEmailTemplates, columns);
    }

    async makeNewTemplate(baseTemplate) {
        const {emailCampaign} = this.options;
        const request = await CreateEmailCampaignTemplate.prompt({emailCampaign, baseTemplate});
        if (!request) {
            return;
        }
        const emailTemplate = await apiCreateEmailTemplate(request);
        if (emailTemplate) {
            this.goToEntry(emailTemplate);
        }
    }

    getActionButtons(singleEntryMode) {
        return <div style={{padding: 8}}>
            <Button onClick={() => this.makeNewTemplate()}>New email template</Button>
            {this.selectedEntry && <Button onClick={() => this.makeNewTemplate(this.selectedEntry)}>Make a copy</Button>}
            {(this.selectedEntry && !singleEntryMode) && <Button onClick={() => this.goToEntry(null)}>See all templates</Button>}
        </div>;
    }

    render() {
        const {emailCampaign} = this.options;
        const {merchant} = emailCampaign;
        const allEmailTemplates = EmailTemplateStore.filterBy({campaignId: emailCampaign.id});
        const singleEntryMode = allEmailTemplates.length === 1;

        if (singleEntryMode) {
            this.selectedEntry = allEmailTemplates[0];
        }

        return [
            <CollapsiblePanel title="Help" collapsed={true}>
                <div style={{paddingLeft: 12, paddingRight: 12}}>
                    <p>An email template contains all the information necessary to send our a message to your audience.</p>
                    <p>If you want to A/B test variations, you need to create multiple templates and define segments for which to send these</p>
                </div>
            </CollapsiblePanel>,
            this.getActionButtons(singleEntryMode),
            this.selectedEntry && <EmailTemplateEditor emailTemplate={this.selectedEntry}/>,
            !this.selectedEntry && this.getEmailTemplatesTable(allEmailTemplates),
        ];
    }
}

class EmailCampaignGeneralPanel extends UI.Element {
    async saveChanges() {
        const {emailCampaign} = this.options;
        const request = {
            emailCampaignId: emailCampaign.id,
            merchantId: emailCampaign.merchantId,
            name: this.nameInput.getValue().trim(),
            audienceId: this.audienceInput.getValue().id,
        };

        await apiMerchantEditEmailCampaign(request);
        // TODO toast?
    }

    render() {
        const {emailCampaign} = this.options;
        const {audience, merchant} = emailCampaign;
        const audienceOptions = MerchantAudienceStore.filterBy({merchantId: merchant.id});

        return [
            <div>
                <Button onClick={() => this.saveChanges()}>Save Changes</Button>
            </div>,
            <div>
                <BlinkInputField label="Audience">
                    <Select ref="audienceInput" options={audienceOptions} value={audience}/>
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Campaign name">
                    <TextInput style={{minWidth: 300}} ref="nameInput" value={emailCampaign.name} />
                </BlinkInputField>
            </div>,
        ];
    }
}


@autoredraw(MerchantEmailCampaignStore, MerchantEmailSenderStore, MerchantEmailingSettingsStore)
export class MerchantEmailCampaignInstancePage extends UI.Element {
    render() {
        const {emailCampaign} = this.options;

        // TODO refactor the TabArea class to push logic to keep active tab to the Switcher
        this.cachedTabs = this.cachedTabs || [
            <EmailCampaignTemplateEditor title="Email templates" emailCampaign={emailCampaign} />,
            <EmailCampaignSendingPanel title="Sending" emailCampaign={emailCampaign} />,
            <EmailCampaignGeneralPanel title="General" emailCampaign={emailCampaign} />,
        ];

        return [
            this.options.parent.renderTitle(`${emailCampaign.audience} - ${emailCampaign.name}`, null),
            <TopLevelTabArea>
                {this.cachedTabs}
            </TopLevelTabArea>
        ]
    }
}
