import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {BlinkInputField} from "./Input";

class InfoBlockGroupStyle extends StyleSheet {
    @styleRule
    infoBlockGroup = {
        display: "inline-block",
    };

    borderValue = () => "1px solid " + this.themeProps.CARD_BORDER_COLOR;

    @styleRule
    infoBlock = {
        display: "inline-block",
        padding: "0 12px",
    };

    @styleRule
    infoBlockValue = {
        fontWeight: "bold",
        margin: "auto",
        textAlign: "center",
        padding: 6,
    };

    @styleRule
    infoBlockLabel = {
        textAlign: "center",
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    };

    @styleRule
    blockGroup = {
        border: this.borderValue,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-around",
        padding: "6px 0",
    }

    @styleRule
    verticalSeparator = {
        width: 1,
        background: this.themeProps.CARD_BORDER_COLOR,
    };

    @styleRule
    hoverable = {
        cursor: "pointer",
        borderRadius: 4,
        ":hover": {
            background: "#F3F3F3",
        },
    };
}

@registerStyle(InfoBlockGroupStyle)
export class InfoBlockGroup extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.infoBlockGroup);
        if (this.options.onClick) {
            attr.addClass(this.styleSheet.hoverable);
        }
    }

    render() {
        const {styleSheet} = this;
        const {entries, label} = this.options;
        return <BlinkInputField label={label}>
            <div className={styleSheet.blockGroup}>
                {entries.map((entry, index) => entry && [
                    index ? <div className={styleSheet.verticalSeparator}/> : null,
                    <div className={styleSheet.infoBlock}>
                        <div className={styleSheet.infoBlockValue}>{entry[1]}</div>
                        <div className={styleSheet.infoBlockLabel}>{entry[0]}</div>
                    </div>,
                ])}
            </div>
        </BlinkInputField>;
    }
}
