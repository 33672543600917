import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {BlinkSDKInputField, JSONObjectBaseInput, SDKArrayInput} from "../../components/Primitives.jsx";
import {ArrayInput} from "../../../../common/input/ArrayInput";
import {NumberInput, TextArea, TextInput} from "../../../../../stem-core/src/ui/input/Input";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {DashboardBaseInput} from "../../../../../core/ui/input/BaseInput.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {SDKResourceLabelEditor} from "../../../../../client/state/misc/SDKResourceLabelEditor.jsx";


class PanelMetadataEntryInput extends JSONObjectBaseInput {
    static get defaultValue() {
        return {key: "", value: ""};
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.setStyle({
            width: 800,
        });
    }

    render() {
        const editorStyleSheet = PanelEditorTabStyle.getInstance();
        return [
            <BlinkSDKInputField ref="keyField" label="Key" InputClass={TextInput} style={{display: "block"}}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("key", "")}
                                onChange={() => this.setField("key", this.keyField.getValue())} />,
            <BlinkSDKInputField ref="valueField" label="Value" InputClass={TextArea} style={{display: "block"}}
                                inputOptions={{className: editorStyleSheet.textAreaInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("value", "")}
                                onChange={() => this.setField("value", this.valueField.getValue())} />,
        ];
    }
}


class PanelMetadataInput extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            ElementInputClass: PanelMetadataEntryInput,
            addButtonLabel: "Add new metadata entry",
        };
    }

    render() {
        const arrayInputValues = Object.entries(this.value || {}).map(([key, value]) => ({key, value}));
        return [
            <ArrayInput
                ElementInputClass={PanelMetadataEntryInput}
                initialValue={arrayInputValues}
                onChange={(metadataFieldsArrayValue) => {
                    const metadata = {};
                    for (const {key, value} of metadataFieldsArrayValue) {
                        metadata[key] = value;
                    }
                    this.setAndDispatchValue(metadata);
                }}
                addButtonLabel="Add new metadata entry"/>,
        ];
    }
}


class SDKFontInput extends JSONObjectBaseInput {
    render() {
        const editorStyleSheet = PanelEditorTabStyle.getInstance();
        return [
            <BlinkSDKInputField ref="familyField" label="Font Family"
                                InputClass={TextInput} style={{display: "block"}}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("family", "")}
                                onChange={() => this.setField("family", this.familyField.getValue())} />,
            <BlinkSDKInputField ref="srcField" label="Source URL (local, woff, woff2)"
                                InputClass={TextInput} style={{display: "block"}}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("src", "")}
                                onChange={() => this.setField("src", this.srcField.getValue())} />,
            <BlinkSDKInputField ref="styleField" label="Font Style"
                                optional InputClass={TextInput}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("style")} defaultValue="regular"
                                onChange={() => this.setField("style", this.styleField.getValue())} />,
            <BlinkSDKInputField ref="weightField"
                                label="Font Weight"
                                optional
                                InputClass={NumberInput}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("weight")}
                                defaultValue={400}
                                onChange={(...args) => this.setField("weight", this.weightField.getValue(), ...args)} />,
            <BlinkSDKInputField ref="unicodeRangeField"
                                label="Unicode range"
                                optional
                                InputClass={TextInput}
                                style={{display: "block"}}
                                inputOptions={{className: editorStyleSheet.textInput + editorStyleSheet.codeInput}}
                                initialValue={this.getField("unicodeRange")}
                                onChange={() => this.setField("unicodeRange", this.unicodeRangeField.getValue())} />,
        ];
    }
}


@registerStyle(PanelEditorTabStyle)
export class PanelDeveloperEditor extends UI.Element {
    render() {
        const {state} = this.options;
        return [
            <SDKResourceLabelEditor state={state} merchant={state.panel.merchant}/>,
            <BlinkSDKInputField
                label="Metadata"
                stateKey={["panelOptions", "metadata"]}
                state={state}
                InputClass={PanelMetadataInput}
            />,
            <BlinkSDKInputField
                label="Font resources"
                stateKey={["panelOptions", "fonts"]}
                state={state}
                style={{display: "block"}}
                InputClass={SDKArrayInput}
                inputOptions={{
                    ElementInputClass: SDKFontInput,
                    addButtonLabel: "Add font resource",
                }}/>,
        ];
    }
}
