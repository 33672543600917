import {PANEL_TYPE} from "../../../../blink-sdk/Constants.js";
import {
    MerchantPanel,
    MerchantPanelStore,
    panelSortCompareFn
} from "../../../../client/state/merchant/MerchantPanelStore.js";
import {MerchantUserJourneyStore} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {getEditingOrLiveSettingsVersion} from "../../../../client/state/merchant/MerchantSDKSettingsStore.js";

// TODO @cleanup use enums or remove
export const AVAILABLE_PANELS = {
    [PANEL_TYPE.custom]: "Custom",
    [PANEL_TYPE.donation]: "Donation",
    [PANEL_TYPE.subscribe]: "Subscription",
    [PANEL_TYPE.newsletter]: "Newsletter",
    [PANEL_TYPE.dashboard]: "Dashboard",
    [PANEL_TYPE.giftSubscription]: "Gift subscription",
    [PANEL_TYPE.auth]: "Authentication",
    [PANEL_TYPE.shop]: "Shop",
};

// TODO @cleanup drop this shit and have the logic in the store
export function getMerchantPanelsForVersion(merchant, sdkSettings = getEditingOrLiveSettingsVersion(merchant)) {
    const entries = MerchantPanelStore.filterBy({
        merchantId: merchant.id,
        settingsVersionId: sdkSettings.id,
    });
    for (const panelType of Object.keys(AVAILABLE_PANELS)) {
        const alias = panelType !== PANEL_TYPE.custom ? panelType : "base";
        if (!entries.find(panel => panel.alias === alias)) {
            entries.push(new MerchantPanel({
                id: `${sdkSettings.id}-${alias}`,
                merchantId: sdkSettings.merchantId,
                settingsVersionId: sdkSettings.id,
                name: panelType === PANEL_TYPE.custom ? "Base panel" : `${AVAILABLE_PANELS[panelType]} base panel`,
                alias,
                options: {
                    type: panelType,
                },
                dashboardOptions: {},
                isMocked: true,
                getStore: null, // Use the global one
            }, {}, MerchantPanelStore));
        }
    }
    return entries.sort(panelSortCompareFn);
}

export function getMerchantJourneysForVersion(merchant, sdkSettings = getEditingOrLiveSettingsVersion(merchant)) {
    return MerchantUserJourneyStore.filterBy({
        settingsVersionId: sdkSettings.id,
    })
}