import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {Level} from "../../../stem-core/src/ui/Constants.js";
import {Theme} from "../../../stem-core/src/ui/style/Theme.js";

export class SaveOrDiscardButtons extends UI.Element {
    render() {
        const {state} = this.options;
        const isEdited = state.isEdited();
        const {error} = state;

        let messageElement = error && <div style={{color: Theme.props.ERROR_COLOR, paddingLeft: 6, display: "inline-block"}}>
            {error}
        </div>;

        if (!messageElement && isEdited) {
            messageElement = <div style={{color: Theme.props.TEXT_SECONDARY_COLOR, paddingLeft: 6, display: "inline-block"}}>
                    Unsaved changes
                </div>
        }

        return [
            <Button level={Level.SECONDARY} disabled={!isEdited && !error} onClick={() => state.discardChanges()}>
                Discard changes
            </Button>,
            <Button disabled={!isEdited || error} onClick={() => state.saveChanges()}>
                Save changes
            </Button>,
            messageElement,
        ]
    }

    onMount() {
        this.attachChangeListener(this.options.state, () => this.redraw());
    }
}
