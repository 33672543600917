import {UI} from "../../../../../stem-core/src/ui/UIBase.js";
import {SimpleTable} from "../../../../ui/SimpleTable.jsx";
import {Button} from "../../../../../stem-core/src/ui/button/Button.jsx";

// It's a panel about the panel dog
export class MechantPanelHistoryPanel extends UI.Element {
    render() {
        const {state} = this.options;

        if (!state.haveLoadedHistory) {
            return null;
        }

        const pastVersions = state.panel.allHistoricVersions(20);

        if (pastVersions.length === 0) {
            return ["No historic versions available"];
        }

        const columns = [
            ["Historic alias", historicPanel => historicPanel.alias],
            ["Modified at", historicPanel => historicPanel.lastEditedAt],
            ["Actions", historicPanel => <div>
                <Button label={"Load version"} onClick={() => state.load(historicPanel)}/>
            </div>]
        ];

        return <SimpleTable entries={pastVersions} columns={columns}/>
    }

    // TODO Hmmmm
    onMount() {
        this.attachChangeListener(this.options.state, () => this.redraw());
    }
}
