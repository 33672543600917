import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {AddContextVariableModal, FormFieldVariableType} from "../../components/AddContextVariableModal";
import {Button} from "../../../../../stem-core/src/ui/button/Button";
import {BlinkSDKInputField} from "../../components/Primitives.jsx";
import {EditContextVariableModal} from "../../components/EditContextVariableModal";
import {TextArea, TextInput} from "../../../../../stem-core/src/ui/input/Input";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";


@registerStyle(PanelEditorTabStyle)
export class PanelContextEditor extends UI.Element {
    FormFieldDescriptors = {
        [FormFieldVariableType.TEXT_LINE.toString()]: [TextInput, () => ({className: this.styleSheet.textInput})],
        [FormFieldVariableType.TEXT.toString()]: [TextArea, () => ({className: this.styleSheet.textAreaInput})],
    };

    getFormFieldsDescription() {
        return this.options.state.dashboardOptions.formFields || [];
    }

    async addFormVariable() {
        const formField = await AddContextVariableModal.prompt();
        if (!formField) {
            return;
        }

        const {state} = this.options;
        const {dashboardOptions} = state;
        const existingFieldIndex = dashboardOptions.formFields.findIndex(f => f.key === formField.key);
        if (existingFieldIndex !== -1) {
            dashboardOptions.formFields.splice(existingFieldIndex, 1);
        }
        dashboardOptions.formFields.push(formField);
        state.dispatchChange();
        this.redraw();
    }

    async editContextVariableFormField(formField, index) {
        let value = await EditContextVariableModal.prompt({formField});
        if (!value) {
            return;
        }
        // We'll modify the state directly and dispatch a change
        const {state} = this.options;
        const {dashboardOptions, panelOptions} = state;

        if (value === "delete") {
            dashboardOptions.formFields.splice(index, 1);
            delete panelOptions.context?.[formField.key]; // Delete the value from the panel options
        } else {
            dashboardOptions.formFields[index] = value;
        }

        state.dispatchChange();
        this.redraw();
    }

    renderFormFields() {
        const {styleSheet} = this;
        const {state} = this.options;
        return [
            <BlinkSDKInputField
                label="Message"
                stateKey={["panelOptions", "text"]}
                state={state}
                className={styleSheet.inputField}
                InputClass={TextArea}
                inputOptions={{
                    placeholder: "Using default message",
                    className: styleSheet.textAreaInput,
                }}/>,
            <BlinkSDKInputField
                label="Button text"
                stateKey={["panelOptions", "buttonText"]}
                state={state}
                className={styleSheet.inputField}
                InputClass={TextInput}
                inputOptions={{
                    placeholder: "Using default message",
                    className: styleSheet.textInput,
                }}/>,
        ];
    }

    renderContextVariableFormFields() {
        const {styleSheet} = this;
        const {state} = this.options;

        return this.getFormFieldsDescription().map((formField, index) => {
            const {key, type, characterLimit} = formField;
            const inputDescription = this.FormFieldDescriptors[type] || this.FormFieldDescriptors[FormFieldVariableType.TEXT_LINE];
            const [InputClass, inputOptionsGenerator] = inputDescription;
            return <BlinkSDKInputField
                stateKey={["panelOptions", "context", key]}
                state={state}
                className={styleSheet.inputField}
                InputClass={InputClass}
                inputOptions={inputOptionsGenerator()}
                editLabel="Edit settings"
                editCallback={() => this.editContextVariableFormField(formField, index)}
                label={key}
                helpInfo={formField.description}
                maxLength={characterLimit}
            />;
        });
    }

    // Say if there are any extra variables available only in advanced mode
    renderExtraContextVariablesMessage() {
        const context = this.options.state.panelOptions?.context || {};
        const managedKeys = new Set(this.getFormFieldsDescription().map(({key}) => key));
        let numExtra = 0;
        for (const key of Object.keys(context)) {
            if (!managedKeys.has(key)) {
                numExtra += 1;
            }
        }
        if (numExtra === 0) {
            return null;
        }
        let textPrefix = numExtra === 1 ? "There is one variable" : `There are ${numExtra} variables`;
        return <div style={{padding: "2px 8px"}}><b>{textPrefix} available only in Advanced Mode.</b></div>;
    }

    render() {
        return [
            this.renderFormFields(),
            this.renderContextVariableFormFields(),
            this.renderExtraContextVariablesMessage(),
            <Button label="Add variable" onClick={() => this.addFormVariable()}/>
        ]
    }
}
