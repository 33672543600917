import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";

class DashboardSectionStyle extends StyleSheet {
    @styleRule
    container = {
        paddingBottom: 8,
    };

    @styleRule
    containerNotLast = {
        borderBottom: "1px solid " + this.themeProps.TEXT_SECONDARY_COLOR,
    };

    @styleRule
    title = {
        fontSize: 20,
        // fontWeight: "bold",
        marginTop: 20,
        marginBottom: 8
    }

    @styleRule
    description = {
        fontSize: 15,
        marginTop: 8,
        marginBottom: 8,
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    }
}

@registerStyle(DashboardSectionStyle)
export class DashboardSectionTitle extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.title);
    }

    render() {
        return this.options.title || super.render();
    }
}

@registerStyle(DashboardSectionStyle)
export class DashboardDescription extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.description);
    }
}


@registerStyle(DashboardSectionStyle)
export class DashboardSection extends UI.Element {
    extraNodeAttributes(attr) {
        if (!this.options.isLast) {
            attr.addClass(this.styleSheet.containerNotLast);
        }
    }

    getChildrenToRender() {
        const {title, description} = this.options;
        return [
            title && <DashboardSectionTitle> {title} </DashboardSectionTitle>,
            description && <DashboardDescription> {description} </DashboardDescription>,
            this.render(),
        ]
    }
}
