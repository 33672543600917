import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal";
import {UI} from "../../stem-core/src/ui/UIBase";
import {RecurringPaymentStoreObject} from "../../client/state/RecurringPaymentStoreObject";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {Size} from "../../stem-core/src/ui/Constants";
import {Button} from "../../stem-core/src/ui/button/Button";

export class MerchantCancelDonationModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Cancel recurring donation",
            description: "Stop auto-renewal for this donation.\n" +
                "It will still be active until the end of the paid period.",
            cancelLabel: "No, back",
            confirmLabel: "Yes, cancel future payments",
            confirmAction: () => this.cancelDonation(),
        };
    }

    render() {
        const {donation, merchantUser} = this.options;

        return [
            <div>Donor: {merchantUser.getName()} — {merchantUser.getEmail()}</div>,
            <div>Donation amount: {donation.formatPriceShortened()}</div>,
            <div>Donation created at {donation.createdAt}</div>,
            <div style={{margin: "1em 0"}}>Make sure you want to cancel the donation, as this action can only be undone by the donor.</div>,
        ]
    }

    async cancelDonation() {
        const {donation, merchantUser} = this.options;
        const request = {
            donationId: donation.id,
            merchantId: merchantUser.merchantId,
        }

        await this.makeRequest("/merchant/cancel_donation/", request);
    }
}

@autoredraw
export class DonationDetailsPanel extends UI.Element {
    getCancelDonationButton() {
        const {donation, merchantUser} = this.options;
        if (!donation.isRecurring() || donation.isFinished() || donation.isCanceled()) {
            return null;
        }

        const cancelDonation = () => {
            MerchantCancelDonationModal.show({donation, merchantUser});
        }

        return <Button size={Size.LARGE} onClick={cancelDonation}>
                Cancel recurring donation
            </Button>
    }

    getStatusLine() {
        const {donation} = this.options;

        if (!donation.isRecurring()) {
            return null;
        }

        let statusMessage = donation.status;
        // if (donation.isCanceled()) {
        //     statusMessage += "at " + donation.getCanceledDate()
        // }

        const nextPaymentDate = (donation.status === RecurringPaymentStoreObject.Status.ACTIVE) && donation.getNextBillingDate();

        return [
            <div>Status: {statusMessage}</div>,
            nextPaymentDate ? <div>Next payment date: {nextPaymentDate}</div> : null,
        ]
    }

    render() {
        const {donation} = this.options;

        // TODO: duplicate code?
        return [
            <div>{donation.isRecurring() ? "Recurring" : "One-time"} donation created at {donation.createdAt}</div>,
            <div>Amount: {donation.formatPriceShortened()}</div>,
            this.getStatusLine(),
            this.getCancelDonationButton(),
        ]
    }
}
