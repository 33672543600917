import {UI} from "../../../stem-core/src/ui/UIBase";
import {Level} from "../../../stem-core/src/ui/Constants";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {Select, NumberInput} from "../../../stem-core/src/ui/input/Input";
import {DateInput} from "../../ui/input/DateInput";
import {MultiselectInput} from "../../common/MultiselectInput";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {CloseIcon} from "../../../core/ui/SVGElements.jsx";
import {StemDate} from "../../../stem-core/src/time/Date";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {RadioButtonsInput} from "../../../core/ui/input/radio/RadioButtonsInput.jsx";
import {AudienceSubscriptionsFilter} from "./MerchantAudienceSubscriptionsFilter.jsx";
import {ProperSubscribedStatuses} from "../../../client/state/merchant/MerchantAudienceMemberStore.js";


@makeEnum
export class DonationTypeFilter extends BaseEnum {
    static ONE_TIME;
    static RECURRING;
    static ALL;

    isRecurring() {
        if (this === this.constructor.RECURRING) {
            return true;
        }
        if (this === this.constructor.ONE_TIME) {
            return false;
        }
        return null; // explicitly to be ignored
    }
}

class AudienceDonorsFilter extends UI.Element {
    getValue() {
        const donorType = this.donorTypeFilter.getValue();
        const isRecurringValue = donorType.isRecurring();
        let filters = {};
        if (isRecurringValue != null) {
            filters.isRecurring = isRecurringValue;
        }
        return filters;
    }

    render() {
        const {initialValue} = this.options;
        const {isRecurring} = initialValue;
        let defaultActiveValue = DonationTypeFilter.ALL;
        if (isRecurring != null) {
            defaultActiveValue = isRecurring ? DonationTypeFilter.RECURRING : DonationTypeFilter.ONE_TIME;
        }
        return [
            <div>
                Donors with the type
            </div>,
            <RadioButtonsInput
                initialValue={defaultActiveValue}
                values={[DonationTypeFilter.ONE_TIME, DonationTypeFilter.RECURRING, DonationTypeFilter.ALL]}
                ref="donorTypeFilter"
            />
        ]
    }
}

export class AudienceExplicitMembershipFilter extends UI.Element {
    getValue() {
        const selectedAudiences = this.audiencesInput.getValue();
        return {
            audienceIds: selectedAudiences.map(audience => audience.id),
        }
    }

    render() {
        const {audience, initialValue} = this.options;
        const allAudiences = MerchantAudienceStore.all().filter(potentialAudience => potentialAudience !== audience);
        const selectDefaultValue = (initialValue?.audienceIds || []).map(audienceId => MerchantAudienceStore.get(audienceId));

        return [
            <div>
                Is an explicit audience member of
            </div>,
            <MultiselectInput options={allAudiences} initialValue={selectDefaultValue} ref="audiencesInput" />
        ]
    }
}

class AudienceEngagementFilterStyle extends StyleSheet {
    @styleRule
    hidden = {
        display: "none",
    };

    @styleRule
    inactive = {
        color: this.themeProps.MERCHANT_3,
    };

    @styleRule
    audienceSelect = {
        paddingLeft: 16,
    };

    @styleRule
    inputLine = {
        height: 36,
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    inputLineMessage = {
        padding: "0 4px",
    };
}

@registerStyle(AudienceEngagementFilterStyle)
class AudienceEngagementFilter extends UI.Element {
    static positiveVerb = "Users that have";
    static negativeVerb = "Users that have NOT";

    getValue() {
        const data = {
            status: ProperSubscribedStatuses,
        };
        for (const key of ["receivedAtLeast", "receivedAtMost", "readAtLeast", "readAtMost", "startDate", "endDate"]) {
            if (this[key + "Checkbox"].getValue()) {
                data[key] = this[key + "Input"].getValue();
            }
        }
        if (this.audienceCheckbox.getValue()) {
            const selectedAudiences = this.audiencesInput.getValue();
            if (selectedAudiences && selectedAudiences.length > 0) {
                data.audienceIds = selectedAudiences.map(audience => audience.id);
            }
        }
        return data;
    }

    renderCheckboxedInputLine(key, message, initialValue, InputClass, inputAttributes) {
        const {styleSheet} = this;
        const checkboxKey = key + "Checkbox";
        const inputKey = key + "Input";
        const checkboxIsActive = this[checkboxKey] ? this[checkboxKey].getValue() : !!initialValue;
        return <div className={styleSheet.inputLine + (checkboxIsActive ? "" : styleSheet.inactive)}>
            <CheckboxInput ref={checkboxKey} initialValue={!!initialValue} onChange={() => this.redraw()}
                             label={message}/>
            <InputClass ref={inputKey} className={checkboxIsActive ? "" : styleSheet.hidden} {...inputAttributes}/>
        </div>;
    }

    renderNumberInputLine(key, message) {
        const initialValue = this.options.initialValue?.[key];
        return this.renderCheckboxedInputLine(key, message, initialValue, NumberInput, {initialValue: initialValue || 0});
    }

    renderDateInputLine(key, message) {
        const initialValue = StemDate.optionally(this.options.initialValue?.[key]);
        return this.renderCheckboxedInputLine(key, message, initialValue, DateInput, {date: initialValue || (new StemDate())});
    }

    render() {
        const {styleSheet} = this;
        const {initialValue} = this.options;

        const allAudiences = MerchantAudienceStore.all();
        const audienceCheckboxDefaultValue = (initialValue?.audienceIds?.length ?? 0) > 0;
        const audienceSelectDefaultValue = initialValue?.audienceIds?.map(audienceId => MerchantAudienceStore.get(audienceId)) || [];
        const audienceCheckboxIsActive = this.audienceCheckbox ? this.audienceCheckbox.getValue() : audienceCheckboxDefaultValue;

        return [
            <div>
                Email conditions
            </div>,
            this.renderNumberInputLine("receivedAtLeast", "received at least"),
            this.renderNumberInputLine("receivedAtMost", "received at most"),
            this.renderNumberInputLine("readAtLeast", "read at least"),
            this.renderNumberInputLine("readAtMost", "read at most"),
            this.renderDateInputLine("startDate", "sent after"),
            this.renderDateInputLine("endDate", "sent before"),
            <div className={audienceCheckboxIsActive ? "" : styleSheet.inactive}>
                <CheckboxInput
                    ref="audienceCheckbox"
                    initialValue={audienceCheckboxDefaultValue}
                    label={" sent in campaigns targeting audiences"}
                    onChange={() => this.redraw()}
                />
            </div>,
            <MultiselectInput
                className={styleSheet.audienceSelect + (audienceCheckboxIsActive ? "" : styleSheet.hidden)}
                options={allAudiences} initialValue={audienceSelectDefaultValue} ref="audiencesInput"/>,
        ];
    }
}

const filterTypeMap = new Map([
    ["users", null], // TODO: implement
    ["subscriptions", AudienceSubscriptionsFilter],
    ["donors", AudienceDonorsFilter],
    ["payments", null], // TODO: implement
    ["audience_members", AudienceExplicitMembershipFilter],
    ["audience_engagement", AudienceEngagementFilter],
    ["operator", null],  //  "or" / "and"
]);


class SubfilterExcludeInput extends Select {
    getDefaultOptions() {
        return {
            options: ["Users that are", "Users that are NOT"],
        }
    }

    setValue(value) {
        if (value.exclude) {
            this.setIndex(1);
        } else {
            this.setIndex(0);
        }
    }

    getValue() {
        const selectedIndex = this.getIndex();
        if (selectedIndex === 1) {
            return {exclude: true};
        }
        return undefined;
    }
}


class SubfilterWrapperStyle extends StyleSheet {
    @styleRule
    container = {
        position: "relative",
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        width: 350,
        background: this.themeProps.MERCHANT_CARD_BACKGROUND,
        padding: "18px 24px 24px",
        border: "1px solid " + this.themeProps.MERCHANT_CARD_BORDER_COLOR,
        borderRadius: this.themeProps.MERCHANT_FRAME_BORDER_RADIUS,
    }

    @styleRule
    filters = {
        maxWidth: 400,
        minWidth: 300,
    }

    @styleRule
    excludeInput = {
        maxWidth: 200,
        margin: "auto",
        paddingBottom: 4,
    }

    @styleRule
    deleteButton = {
        top: 12,
        right: 12,
        cursor: "pointer",
        position: "absolute",
    }
}


@registerStyle(SubfilterWrapperStyle)
class SubfilterWrapper extends UI.Element {
    getValue() {
        return {
            type: this.options.type,
            ...this.excludeInput.getValue(),
            ...this.filterInput.getValue(),
        };
    }

    render() {
        const {styleSheet} = this;
        const {index, audience, initialValue, parent, type} = this.options;
        const UIClass = filterTypeMap.get(type);
        if (!UIClass) {
            console.error("Can't find the UI class for filter type", type);
        }
        return [
            <div className={styleSheet.excludeInput}>
                <SubfilterExcludeInput initialValue={initialValue} ref={"excludeInput"} options={[
                    UIClass?.positiveVerb || "Users that are",
                    UIClass?.negativeVerb || "Users that are NOT",
                ]}/>
            </div>,
            <UIClass initialValue={initialValue} audience={audience} ref="filterInput"
                     className={styleSheet.filters}/>,
            <CloseIcon level={Level.SECONDARY} onClick={() => parent.deleteSubfilterAtIndex(index)}
                       className={styleSheet.deleteButton}/>
        ]
    }
}


function MakeFilterElement(value, audience, index, parent) {
    return <SubfilterWrapper key={Math.random()} type={value.type} initialValue={value} audience={audience}
                             index={index} parent={parent}/>
}


export class MerchantAudienceMembershipFilters extends UI.Element {
    type = null; // Can be "or"/"and"
    subfilters = [];

    getValue() {
        const subfilters = this.subfilters.map(op => op.getValue());
        if (subfilters.length === 0) {
            return null;
        }

        return {
            type: this.type,
            subfilters,
        };
    }

    setValue(value) {
        this.type = value?.type || "or";
        this.subfilters = (value?.subfilters || []).map((subfilter, index) => MakeFilterElement(subfilter, this.options.audience, index, this));
    }

    addSubfilter() {
        const {audience} = this.options;
        const {type} = this.subfilterTypeInput.getValue();
        const index = this.subfilters.length;
        this.subfilters.push(MakeFilterElement({type}, audience, index, this));
        this.redraw();
    }

    deleteSubfilterAtIndex(index) {
        this.subfilters.splice(index, 1);
        this.redraw();
    }

    renderTypeInput() {
        const setConjuctionType = (value) => {
            this.type = value;
            this.redraw();
        }

        return <RadioButtonsInput
            initialValue={this.type}
            values={["and", "or"]}
            onChange={setConjuctionType}
            style={{display: "inherit"}}
        />
    }

    render() {
        const {initialValue} = this.options;

        if (this.type == null) {
            this.setValue(initialValue);
        }

        const supportedFilterTypes = [
            {toString: () => "Donors", type: "donors"},
            {toString: () => "Subscribers", type: "subscriptions"},
            {toString: () => "Explicit audience membership", type: "audience_members"},
            {toString: () => "Email Engagement", type: "audience_engagement"},
        ]

        return <div>
            {this.subfilters.map(
                (obj, index) => [index > 0 && <div style={{padding: 12, textAlign: "center"}}>
                    {this.renderTypeInput()}
                </div>, obj]
            )}
            <div style={{textAlign: "center", paddingTop: 8}}>
                <Select options={supportedFilterTypes} ref="subfilterTypeInput" />
                <Button level={Level.SECONDARY} onClick={() => this.addSubfilter()}>Add filter</Button>
            </div>
        </div>;
    }
}
