import {App} from "./App";
import {Route, TerminalRoute} from "../stem-core/src/ui/Router";
import {DashboardPage} from "./DashboardPage";
import {WebsiteIndex} from "./WebsiteIndex";
import {DashboardLoginPage} from "./pages/DashboardLoginPage.jsx";
import {ChooseMerchantPage} from "./pages/choose-merchant/ChooseMerchantPage";
import {SwitchAccountPage} from "./pages/switch-account/SwitchAccountPage";
import {authService} from "../client/connection/services/AuthService.js";
import {apiClient} from "../client/connection/BlinkApiClient.js";
import {registerDashboardStyleSheets} from "./common/theme/DashboardTheme";
import {GlobalState} from "../stem-core/src/state/State";
import {catchUnhandledDashboardError} from "./ErrorHandler.js";
import {Toast} from "../blinkpay/ui/Toast.jsx";
import {ApiErrors} from "../client/connection/ApiErrors";
import {LoginRedirectPage} from "../core/external-auth/LoginRedirectPage.jsx";
import {iFrameConnection} from "../blinkpay/services/IFrameConnection.js";
import {IFrameMessages} from "../blink-sdk/messaging/IFrameMessages.js";
import {AuthHelper} from "../blinkpay/AuthHelper.js";
import {merchantService} from "./misc/MerchantService.js";
import {PageTitleManager} from "../stem-core/src/base/PageTitleManager.js";

window.onerror = catchUnhandledDashboardError;

// For unhandled promises
window.onunhandledrejection = (promiseRejectionEvent) => {
    const error = promiseRejectionEvent.reason;
    if (error.isAPIError) {
        let {message} = error;
        if (!message) {
            message = "API Error";
        }
        if (error.code === ApiErrors.VALIDATION_ERRORS) {
            if (error.fields && error.fields.length > 0) {
                // TODO @Mihai reimplement later better
                const field = error.fields[0]
                message = `${message} (${field.field}: ${field.message})`;
            }
        }
        Toast.showError(message);
        promiseRejectionEvent.preventDefault();
    }
}

window.UnimplementedError = {isAPIError: true, message: "Not properly implemented!"};

export const APP_NAME = "publisher_dashboard";

export class DashboardApp extends App {
    static init() {
        super.init();
        this.initializeWebsocketConnection();

        authService.setLoginAppName(APP_NAME);
        PageTitleManager.setPrefix("Blink Dashboard");

        // TODO @cleanup @auth use this everywhere in the same way
        iFrameConnection.addListener(IFrameMessages.USER_TOKEN_UPDATE, ({token}) => {
            const {t, e} = JSON.parse(token);
            authService.token.setToken(t, e);
            merchantService.updateMerchantId();
            AuthHelper.confirmedAuthenticationAction();
        });
    }

    // TODO copied over from the user dashboard (modified to get userId)
    static initializeWebsocketConnection() {
        const {token} = authService;
        const userId = token.getUserId();
        if (!userId) {
            return;
        }
        apiClient.initWebsocketConnection(token);
        // Make sure to only have one listener per user
        // TODO: this code is kinda ugly, we should just only have a single stream listener for events at any time (even after relogin)
        if (this.wsListenerSet) {
            return;
        }

        this.wsListenerSet = true;

        const streamName = "user-" + userId + "-events";
        // TODO: I don't like this code at all. A ws listener is deleted once the stream is deleted, although it doesn't seem so from the api.
        apiClient.addWebsocketListener(streamName, event => {
            GlobalState.applyEvent(event);
        });
    }

    static initializeTheme() {
        registerDashboardStyleSheets();
    }

    getRoutes() {
        const routeOptions = {replaceHistory: true};

        function checkNotAuthenticated() {
            if (!authService.isAuthenticated()) {
                return null;
            }
            return ["/", routeOptions];
        }

        function checkAuthenticated() {
            if (authService.isAuthenticated()) {
                return null;
            }
            return ["login", routeOptions];
        }

        return new Route([], WebsiteIndex, [
            new Route(["login"], DashboardLoginPage, [], {
                beforeEnter: checkNotAuthenticated,
            }),
            new TerminalRoute(["login-redirect"], LoginRedirectPage),
            new Route(["choose-merchant"], ChooseMerchantPage, [], {
                beforeEnter: checkAuthenticated,
            }),
            new Route(["switch-account"], SwitchAccountPage, [], {
                beforeEnter: checkAuthenticated,
            }),
            new TerminalRoute([], DashboardPage, {
                beforeEnter: checkAuthenticated,
            }),
        ]);
    }
}
