import {UI} from "../../../stem-core/src/ui/UIBase";
import {DashboardTitle} from "../../common/DashboardTitle";
import {apiCreateEmailTemplate, EmailTemplateStore, EmailTemplateType} from "../../../client/state/EmailTemplateStore";
import {BlinkInputField} from "../../common/Input";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {EmailTemplateEditor} from "./EmailTemplateEditor";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {MerchantEmailingSettingsStore} from "../../../client/state/merchant/MerchantEmailingSettingsStore";


export class MerchantEmailTemplatesPage extends UI.Element {
    async forkEmail(merchant, selectedEmailTemplateType) {
        const response = await apiCreateEmailTemplate({
            merchantId: merchant.id,
            type: selectedEmailTemplateType,
        });
        this.redraw();
    }

    // TODO: make static method or just plain function
    getEditableEmailTemplateTypes() {
        const {merchant} = this.options;

        let result = [
            EmailTemplateType.AUTH_LOGIN_CODE,
            EmailTemplateType.CHANGE_EMAIL_CONFIRM,
            EmailTemplateType.CHANGE_EMAIL_UNDO,
        ];
        if (merchant.hasDonationsEnabled()) {
            result.push(EmailTemplateType.DONATION_SUCCESS);
            const emailingSettings = MerchantEmailingSettingsStore.findBy({merchantId: merchant.id});
            if (emailingSettings && emailingSettings.sendDonationCancelEmail) {
                result.push(EmailTemplateType.DONATION_CANCEL);
            }
        }
        if (merchant.hasSubscriptionsEnabled()) {
            result.push(EmailTemplateType.SUBSCRIPTION_SUCCESS);
            // TODO - only show this if the merchant wants to gift subs
            result.push(EmailTemplateType.SUBSCRIPTION_GIFT_SUCCESS_BILLED_USER);
            result.push(EmailTemplateType.SUBSCRIPTION_GIFT_SUCCESS_BENEFICIARY);
        }
        return result;
    }

    getEditableEmailsOrTypes() {
        const {merchant} = this.options;
        const customEmails = EmailTemplateStore.filterBy({merchantId: merchant.id, type: EmailTemplateType.CUSTOM});

        return [
            ...this.getEditableEmailTemplateTypes(),
            ...customEmails,
        ]
    }

    render() {
        const {merchant} = this.options;
        const emailTemplatesOrTypes = this.getEditableEmailsOrTypes();

        const selectedOption = this.eventTypeSelect?.getValue() || emailTemplatesOrTypes[0];
        const emailTemplate = (selectedOption instanceof EmailTemplateType)
            ? EmailTemplateStore.findBy({merchantId: merchant.id, type: selectedOption})
            : selectedOption;

        return [
            <DashboardTitle
                title="Event-triggered emails"
                description="Edit email templates sent on user lifecycle events (registration, subscription, etc)"
            />,
            <BlinkInputField label="Event type">
                <Select
                    ref="eventTypeSelect"
                    value={selectedOption}
                    options={emailTemplatesOrTypes}
                    onChange={() => this.redraw()}
                />
            </BlinkInputField>,
            emailTemplate ?
                <EmailTemplateEditor emailTemplate={emailTemplate}/> :
                <div>
                    No template registered for this type, using the default.
                    <Button
                        label="Customize message"
                        onClick={() => this.forkEmail(merchant, selectedOption)}
                    />
                </div>,
        ]
    }
}
