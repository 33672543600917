import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {CodeEditor} from "../../../../stem-core/src/ui/CodeEditor.jsx";
import {deepGetAttr, deepSetAttr, toArray} from "../../../../blinkpay/UtilsLib.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../../../stem-core/src/decorators/Style.js";
import {StyleSheet} from "../../../../stem-core/src/ui/Style.js";


export class JSONStateInputStyle extends StyleSheet {
    @styleRule
    codeEditor = {
        fontFamily: this.themeProps.FONT_FAMILY_MONOSPACE,
        fontSize: 16,
        width: "100%",
    };
}

@registerStyle(JSONStateInputStyle)
export class JSONStateInput extends UI.Element {
    canOverwrite() {
        return false;
    }

    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            codeEditorHeight: 250,
        }
    }

    renderFormFields() {
        return [];
    }

    renderSimplifiedMode() {
        return <div ref="form">
            {this.renderFormFields()}
        </div>;
    }

    renderAdvancedMode() {
        const {state, stateKey} = this.options;
        const value = deepGetAttr(state, toArray(stateKey));
        const strValue = JSON.stringify(value, null, "\t");
        return <CodeEditor ref="jsonEditor"
                           className={this.styleSheet.codeEditor}
                           style={{height: this.options.codeEditorHeight}}
                           aceMode="json"
                           value={strValue}
                           onAceChange={() => this.handleJSONChange(this.jsonEditor.getValue())}/>;
    }

    render() {
        if (this.options.advancedMode ?? this.options.state.advancedMode) {
            return this.renderAdvancedMode();
        }
        return this.renderSimplifiedMode();
    }

    handleJSONChange(stringValue) {
        const {state, stateKey} = this.options;
        try {
            const value = JSON.parse(stringValue);
            deepSetAttr(state, toArray(stateKey), value);
            state.error = null;
        } catch {
            state.error = "JSON syntax error";
        }
        state.dispatchChange();
    }
}
