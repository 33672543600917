import {PropertyCache} from "../../../../stem-core/src/data-structures/PropertyCache.js";
import {StoreObject} from "../../../../stem-core/src/state/Store.js";
import {toArray} from "../../../../stem-core/src/base/Utils.js";

// TODO @branch Should not inherit StoreObject
export class BaseEditorState extends StoreObject {
    static instanceCache = new PropertyCache("editorState"); // TODO @branch not sure if it's safe to share with children?

    static instantiate(resource, rootElement) {
        const obj = this.instanceCache.get(resource, () => new this(resource)); // Getter must be here, since we're only used in inheritors
        obj.rootElement = rootElement;
        return obj;
    }

    dispatchChange() {
        this.dispatch("change", this);
    }

    setAdvancedMode(advancedMode) {
        this.advancedMode = advancedMode;
        this.dispatchChange();
        this.rootElement?.redraw();
    }

    discardChanges() {
        this.load();
        this.dispatchChange();
        this.rootElement?.redraw();
    }
}

// TODO Put in more common code here, and use entryOptions and dashboardOptions
export class SDKResourceEditorState extends BaseEditorState {
    getLabels() {
        return toArray(this.dashboardOptions?.labels);
    }

    editDashboardOptions(options) {
        Object.assign(this.dashboardOptions, options);
        for (const key of Array.from(Object.keys(this.dashboardOptions))) {
            if (this.dashboardOptions[key] === undefined) {
                delete this.dashboardOptions[key];
            }
        }
        this.dispatchChange();
    }
}