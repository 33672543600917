import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {TreeViewCheckbox} from "../../../../stem-core/src/ui/input/checkbox/TreeViewCheckbox.jsx";
import {Button} from "../../../../stem-core/src/ui/button/Button.jsx";
import {TextInput} from "../../../../stem-core/src/ui/input/Input.jsx";
import {BaseInputElement} from "../../../../stem-core/src/ui/input/BaseInputElement.js";


export class LabelSetInput extends BaseInputElement {
    allLabels = this.options.allLabels;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            allLabels: [],
            unlabeledCheckboxValue: null,
            editMode: null,
        };
    }

    addNewLabel(newLabel) {
        if (!newLabel || this.allLabels.includes(newLabel)) {
            return;
        }
        this.newLabelInput?.setValue("");
        this.allLabels.push(newLabel);
        this.setValue([...this.getValue(), newLabel]);
    }

    getUnlabeledValue() {
        if (!this.treeViewCheckbox) {
            return this.options.unlabeledCheckboxValue;
        }
        const entries = this.treeViewCheckbox.getValue({linearize: false});
        const unlabeledEntry = entries.find(entry => entry.label === "Unlabeled");
        return unlabeledEntry.checked;
    }

    render() {
        const checkedLabels = this.getValue();
        const allLabels = this.allLabels.filter((label) => label !== "Archive");
        const childrenEntries = allLabels.map((label) => ({
            label: label,
            value: label,
            checked: checkedLabels.includes(label)
        }));

        const entries = [];
        if (childrenEntries.length) {
            entries.push({
                label: "All labels",
                value: null,
                children: childrenEntries
            });
        }
        entries.push({
                label: "Archive",
                value: "Archive",
                checked: checkedLabels.includes("Archive")
            }
        );
        if (!this.options.editMode) {
            entries.push({
                label: "Unlabeled",
                value: null,
                checked: this.getUnlabeledValue()
            });
        }

        return [
            entries.length > 0 && <TreeViewCheckbox
                ref="treeViewCheckbox"
                entries={entries}
                onChange={(event, input) => this.setValue(input.getValue())}
            />,
            this.options.editMode && <div>
                <TextInput ref="newLabelInput" placeholder="New label"/>
                <Button
                    onClick={() => this.addNewLabel(this.newLabelInput.getValue())}
                    label="Add label"
                />
            </div>
        ];
    }
}