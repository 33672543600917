import {UI} from "../stem-core/src/ui/UIBase.js";
import {StemApp} from "../stem-core/src/app/StemApp.jsx";
import {ViewportMeta} from "../stem-core/src/ui/ViewportMeta.jsx";
import {Device} from "../stem-core/src/base/Device.js";
import {StemDate} from "../stem-core/src/time/Date.js";
import {ServerTime} from "../stem-core/src/time/Time.js";
import {apiClient} from "../client/connection/BlinkApiClient.js";
import {ApiErrors} from "../client/connection/ApiErrors.js";
import {authService} from "../client/connection/services/AuthService.js";
import {merchantService} from "./misc/MerchantService";
import {Router} from "../stem-core/src/ui/Router.jsx";
import {LanguageStore} from "../client/state/LanguageStore.js";
import {englishTranslationMap} from "../blinkpay/Messages.js";
import {setLanguageStore} from "../stem-core/src/ui/Translation.js";
import {WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED} from "../blinkpay/Constants.js";

LanguageStore.importState([
    {
        id: 1,
        translationMap: englishTranslationMap,
    },
]);

setLanguageStore(LanguageStore);


class BlinkViewportMeta extends ViewportMeta {
    getContent() {
        const {scale} = this.options;
        const windowWidth = (Device.isMobileDevice() && window.screen?.width) || window.innerWidth;

        // Squash everything if the window is too small
        const initialScale = (windowWidth < WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED) ? windowWidth / WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED : scale;

        return `width=device-width,initial-scale=${initialScale},maximum-scale=${scale},user-scalable=no`;
    }
}


// TODO: lots of duplicated code here, merge or remove
export class App extends StemApp {
    static init() {
        this.initializeTheme();
        this.initializeViewportMeta();
        this.addAjaxProcessors();
        this.fetch();
        return super.init();
    }

    static initializeViewportMeta() {
        BlinkViewportMeta.create(document.head);
    }

    static fetch() {
        authService.onAuthentication(() => {
            merchantService.fetchMerchantData();
        });
    }

    getContainer() {
        return this.getRouter();
    }

    static addAjaxProcessors() {
        const ajaxHandler = apiClient.getAjaxHandler();

        // TODO: normalize all these postprocessors.
        // Logout user if the token is invalid
        ajaxHandler.addPostprocessor(response => {
            const {error} = response;
            if (!error) {
                return response;
            }

            if (error && (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED)) {
                authService.clearCurrentInfo();
                Router.changeURL("/");
                return null;
            }

            return response;
        });

        ajaxHandler.addPreprocessor((options) => {
            if (options.noMerchantOverride) {
                return;
            }
            // Add merchantId to each request
            const merchantId = merchantService.getMerchantId();
            if (merchantId) {
                options.data.merchantId = merchantId;
            }
        });

        // Sync server time
        ajaxHandler.addPostprocessor((payload, xhrPromise) => {
            const responseHeaders = xhrPromise.getResponseHeaders();
            const responseDate = responseHeaders.get("date");
            if (responseDate) {
                // Estimate server time, with 500ms rounding and 100 ms latency
                const estimatedServerTime = new StemDate(responseDate).add(600);
                ServerTime.set(estimatedServerTime, true);
            }
        });

        // Raise any error, to be handled by the error processor
        ajaxHandler.addPostprocessor(payload => {
            if (payload.error) {
                throw payload.error;
            }
        });

        // Prettify any error, so it's in a standardized format
        ajaxHandler.addErrorPostprocessor(error => {
            let formattedError = {};
            if (typeof error === "string" || error instanceof String) {
                formattedError = {message: error};
            } else if (error instanceof Error) {
                formattedError = {
                    name: error.name,
                    message: error.message,
                };
            }
            return formattedError;
        });
    }
}
