import {UI} from "../../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal";
import {BlinkInputField} from "../../../common/Input";
import {Input, NumberInput, Select, TextArea} from "../../../../stem-core/src/ui/input/Input";
import {AddContextVariableModalStyle, FormFieldVariableType} from "./AddContextVariableModal";
import {Level} from "../../../../stem-core/src/ui/Constants";
import {Button} from "../../../../stem-core/src/ui/button/Button";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";


@registerStyle(AddContextVariableModalStyle)
export class EditContextVariableModal extends ConfirmationModal {
    confirmButtonDisabled = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Edit variable",
            confirmLabel: "Edit",
        };
    }

    getValue() {
        let value = {
            key: this.keyInput.getValue(),
            description: this.descriptionInput.getValue(),
            type: this.typeInput.getValue().toString(),
        };
        if (value.type === FormFieldVariableType.TEXT_LINE.toString() && this.characterLimitInput.getValue()) {
            value.characterLimit = parseInt(this.characterLimitInput.getValue(), 10);
        }
        return value;
    }

    async removeVariable() {
        const confirm = await ConfirmationModal.prompt({
            title: "Remove variable",
            description: "Removing a Panel's variables may have a significant impact on the UI/functionality of the panel. Make sure the variable is not used anymore before removing it.",
        });
        if (confirm) {
            this.resolve("delete");
        }
    }

    render() {
        const {styleSheet} = this;
        const onInputChange = () => {
            formField.key = this.keyInput.getValue();
            formField.description = this.descriptionInput.getValue();
            formField.type = this.typeInput.getValue();
            this.redraw();
        };
        const {formField} = this.options;

        return [
            <BlinkInputField label="Variable name" className={styleSheet.formField}>
                <Input ref="keyInput" onKeyUp={onInputChange} value={formField.key} disabled/>
            </BlinkInputField>,
            <BlinkInputField label="Type" className={styleSheet.formField}>
                <Select ref="typeInput" options={FormFieldVariableType.all()} onChange={onInputChange}
                        value={formField.type} disabled/>
            </BlinkInputField>,
            <BlinkInputField label="Description" className={styleSheet.formField}>
                <TextArea ref="descriptionInput" onKeyUp={onInputChange} style={{minHeight: 100}}
                          value={formField.description || ""}/>
            </BlinkInputField>,
            (this.typeInput?.getValue() === FormFieldVariableType.TEXT_LINE ||
             formField.type === FormFieldVariableType.TEXT_LINE.toString()) ?
                <BlinkInputField label="Character limit" className={styleSheet.formField}>
                    <NumberInput ref="characterLimitInput" value={formField.characterLimit} onKeyUp={onInputChange}/>
                </BlinkInputField> : null,
            <Button label="Remove variable" level={Level.SECONDARY} onClick={() => this.removeVariable()}/>
        ];
    }
}
