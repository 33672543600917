// Make a simple headerless table where each entry is an array with 2 elements
import {UI} from "../../stem-core/src/ui/UIBase";
import {SimpleTable} from "../ui/SimpleTable";

export function MakeSimpleSummaryTable(entries, nameCellStyle = {}, valueCellStyle = {textAlign: "right", fontWeight: 700}, tableStyle = {maxWidth: 712, padding: "12px 0px"}) {
    const columns = [
        {
            value: entry => entry[0],
            cellStyle: nameCellStyle,
        },
        {
            value: entry => entry[1],
            cellStyle: valueCellStyle,
        },
    ];

    return <SimpleTable
        entries={entries}
        columns={columns}
        noHeader
        style={tableStyle}
    />;
}
