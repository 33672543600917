import {UI} from "../../../../../stem-core/src/ui/UIBase.js";
import {JSONStateInput} from "../../components/JSONStateInput.jsx";

export class PanelPreviewSettingsEditor extends UI.Element {
    render() {
        const {state} = this.options;

        return [
            <h3>Extra global variables</h3>,
            <JSONStateInput
                ref="jsonEditor"
                advancedMode
                state={state}
                stateKey={["dashboardOptions", "variables"]}
            />,
            <h3>Extra options only for preview</h3>,
            <JSONStateInput
                advancedMode
                state={state}
                stateKey={["dashboardOptions", "extraOptions"]}
            />
        ]
    }
}