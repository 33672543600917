import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {DashboardTitle} from "../../common/DashboardTitle.js";
import {CSVFileFormat, CSVFileInput} from "../../common/input/CSVFileInput.jsx";
import {merchantService} from "../../misc/MerchantService.js";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {State} from "../../../stem-core/src/state/State.js";
import {apiStreamSubscriptions, Subscription} from "../../../client/state/SubscriptionStore.js";
import {MakeStore} from "../../../stem-core/src/state/Store.js";
import {normalizeCDSAccountNumber, SocialAccount} from "../../../client/state/SocialAccountStore.js";
import {TaskStatus} from "../../../client/state/misc/GenericEnums.js";
import {Theme} from "../../../stem-core/src/ui/style/Theme.js";
import {apiMerchantSyncExternalUser, MerchantUser} from "../../../client/state/merchant/MerchantUserStore.js";
import {BlinkInputField} from "../../common/Input.jsx";
import {DateInput} from "../../ui/input/DateInput.jsx";
import {StemDate} from "../../../stem-core/src/time/Date.js";
import {TimeUnit} from "../../../stem-core/src/time/Duration.js";
import {Select} from "../../../stem-core/src/ui/input/Input.jsx";
import {ArrayPaginator} from "../../../client/state/EndpointPaginator.js";
import {SimpleTable} from "../../ui/SimpleTable.jsx";
import {DashboardSectionTitle} from "../../common/DashboardSection.jsx";
import {BaseTaskProgressStatus} from "./BaseTaskProgressStatus.jsx";
import {TaskProgressStatusDescriptor} from "./TaskProgressStatusDescriptor.jsx";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {CSVBuilder} from "../../../stem-core/src/base/CSV.js";
import {NOOP_FUNCTION, slugify} from "../../../stem-core/src/base/Utils.js";
import {ExternalAppType} from "../../../client/state/ExternalAppConfigStore.js";


class BlinkSubscriberLoader extends UI.Element {
    defaultCreatedAtInitialValue = StemDate.now().subtract(TimeUnit.WEEK); // To not reset on init TODO better pattern?
    status = new BaseTaskProgressStatus();
    activeCDSAccountIds = new Map();
    allCDSAccounts = new Map();

    processResponse(response) {
        // Going to make a local state to not bloat the global one
        const state = new State();
        MakeStore("Subscription", Subscription, {state});
        MakeStore("SocialAccount", SocialAccount, {state});
        MakeStore("MerchantUser", MerchantUser, {state});

        state.importState(response.state || {});

        const activeSubs = state.getStore("Subscription").all().filter(sub => sub.isActive());
        const activeUserIds = new Set(activeSubs.map(sub => sub.userId));
        const socialAccounts = state.getStore("SocialAccount").all();
        const merchantUsers = state.getStore("MerchantUser").all();

        for (const socialAccount of socialAccounts) {
            if (socialAccount.getCDSProductId() != "NUB") {
                continue;
            }

            const accountNumber = normalizeCDSAccountNumber(socialAccount.getCDSAccountNumber());
            const {userId} = socialAccount;
            const merchantUser = merchantUsers.find(mu => mu.userId == userId);
            const {email, name} = merchantUser;

            const entry = {accountNumber, userId, email, name};
            this.allCDSAccounts.set(accountNumber, entry);
            if (activeUserIds.has(socialAccount.userId)) {
                this.activeCDSAccountIds.set(accountNumber, entry);
            }
        }

        this.status.incremProgress(response.state.Subscription.length, response.count);
    }

    async load() {
        if (this.status.isLoading) {
            return;
        }

        this.status = new BaseTaskProgressStatus();
        const {status} = this;

        this.attachChangeListener(status, () => this.dispatchChange());

        try {
            status.setProgress(0);
            this.redraw();
            const maxCreatedAt = this.maxCreatedAtInput.getValue();
            const filters = {
                createdAt: {end_date: maxCreatedAt.unix()},
                merchantId: merchantService.getMerchantId(),
            }
            for await (const response of apiStreamSubscriptions(filters)) {
                this.processResponse(response);
                if (this.status.shouldStop) {
                    break;
                }
            }
            status.setDone();
        } catch (error) {
            const errorMessage = error?.message || String(error);
            status.setErrorMessage(errorMessage);
        }
        status.setDone();
    }

    render() {
        const {status} = this;

        return [
            <div>
                <BlinkInputField label="Ignore if created after">
                    <DateInput ref="maxCreatedAtInput" initialValue={this.defaultCreatedAtInitialValue} />
                </BlinkInputField>
                <Button disabled={this.isLoading} label="Load subscribers from Blink" onClick={() => this.load()}/>
                {status.phase === TaskStatus.IN_PROGRESS && <Button label="Stop loading" onClick={() => status.setStop()} />}
            </div>,
            status.phase !== TaskStatus.PENDING && <TaskProgressStatusDescriptor status={status} />
        ]
    }
}

class ExternalUserSyncController extends UI.Element {
    errors = [];

    async processEntries() {
        const {entries} = this.options;

        this.status = new BaseTaskProgressStatus(entries.length);
        this.errors = [];

        for (const entry of entries) {
            const {accountNumber} = entry;

            try {
                await apiMerchantSyncExternalUser({
                    provider: ExternalAppType.CDS,
                    appId: "NUB",
                    externalUserId: accountNumber,
                });
            } catch (error) {
                const errorMessage = error?.message || String(error);
                if (this.stopOnErrorInput.getValue()) {
                    this.status.setStop(true);
                }
                this.errors.push({accountNumber, errorMessage});
            } finally {
                this.status.incremProgress();
            }

            if (this.status.shouldStop) {
                break;
            }
            this.redraw();
        }
        this.status.setDone();
        this.redraw();
    }

    render() {
        const {name} = this.options;
        return [
            <div style={{display: "flex"}}>
                <CheckboxInput ref="stopOnErrorInput" label="Stop on error" initialValue={true} style={{alignSelf: "center"}} />
                <Button disabled={this.isLoading} label={"Sync " + name} onClick={() => this.processEntries()}/>
                {this.status?.phase === TaskStatus.IN_PROGRESS && <Button label="Stop sync" onClick={() => this.status.setStop()} />}
            </div>,

            this.status && <TaskProgressStatusDescriptor status={this.status}/>,

            this.errors.length > 0 && [
                <div>{this.errors.length} error encountered:</div>,
                this.errors.map(error => <li>Account {error.accountNumber}: {error.errorMessage}</li>),
            ]

        ];
    }
}


class ExternalUserSyncSetManager extends UI.Element {
    renderWorkingSet() {
        this.exportCSV = NOOP_FUNCTION;

        const workingSet = this.workingSetInput?.getValue();
        if (!workingSet) {
            return null;
        }
        const {entries} = workingSet;
        const name = String(workingSet);
        const firstEntry = entries[0];
        if (!firstEntry) {
            return <div>No entries in the chosen set</div>
        }

        const paginator = new ArrayPaginator(entries, 5);
        const columns = Object.keys(firstEntry).map(key => [key, (obj) => obj[key]]);

        // A bit ugly, meh
        this.exportCSV = () => CSVBuilder.saveFile(columns, entries, `Sync-${slugify(name)}-${new StemDate().format("YYYY-MM-DD")}.csv`);

        return [
            <SimpleTable key={Math.random()} columns={columns} paginator={paginator} />,
            <ExternalUserSyncController entries={entries} name={name} />
        ]
    }

    render() {
        const {workingSets} = this.options;

        return [
            <div>
            <BlinkInputField label="User set to sync">
                <Select ref="workingSetInput" options={workingSets} onChange={() => this.redraw()}/>
            </BlinkInputField>
            <Button label="Export to csv..." onClick={() => this.exportCSV()}/>
            </div>,
            this.renderWorkingSet()
        ];
    }

    onMount() {
        this.redraw();
    }
}


export class CDSSyncSubscribersPage extends UI.Element {
    renderLoadingStats() {
        // TODO merge duplicate code
        const MakeEntry = (count, text, {depth=0, color=Theme.props.COLOR_PRIMARY}={}) => {
            return count !== 0 && <li style={{paddingLeft: depth * 16, paddingTop: 4}}>
                <div style={{display: "inline-block", color: color}}>{count}</div> {text}
            </li>;
        }

        const {blinkLoaderInput} = this;

        if (!blinkLoaderInput) {
            return null;
        }

        const {status, activeCDSAccountIds} = blinkLoaderInput;

        if (status.phase === TaskStatus.PENDING) {
            return null;
        }

        const csvInputEntries = this.csvInput.getEntriesAsObjects();
        const csvActiveAccounts = new Map(csvInputEntries.map(entry => [entry.accountNumber, entry]));

        // Return all entries from a where there isn't a key in b
        const mapDifference = (a, b) => {
            const result = [];
            for (const [key, value] of a.entries()) {
                if (!b.has(key)) {
                    result.push(value);
                }
            }
            return result;
        }

        const cdsOnly = mapDifference(csvActiveAccounts, activeCDSAccountIds);
        const blinkOnly = mapDifference(activeCDSAccountIds, csvActiveAccounts);

        const stats = activeCDSAccountIds && [
            MakeEntry(activeCDSAccountIds.size, "Active Blink subscribers"),
                MakeEntry(blinkOnly.length, "Not found in csv file", {depth: 1}),
                MakeEntry(cdsOnly.length, "Other subscribers in CDS file", {depth: 1}),
        ];

        const workingSets = [
            {toString: () => "CDS-only entries", entries: cdsOnly},
            {toString: () => "Blink-only entries", entries: blinkOnly},
        ]

        const actions = status.phase.isDone() && [
            <DashboardSectionTitle title="Run sync" />,
            <ExternalUserSyncSetManager workingSets={workingSets} />,
        ];

        return [
            stats,
            actions,
        ]
    }

    render() {
        return [
            <DashboardTitle title="Sync CDS Subscribers" description="Open the CDS account file and find account subsets to update" />,

            <DashboardSectionTitle title="Open CSV File" />,
            <CSVFileInput ref="csvInput" fileFormat={CSVFileFormat.CDS_SUBSCRIBER} onChange={() => this.redraw()} />,

            <DashboardSectionTitle title="Load existing Blink subscribers" />,
            <BlinkSubscriberLoader ref="blinkLoaderInput" onChange={() => this.redraw()} />,

            this.renderLoadingStats(),
        ];
    }
}
