import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";

import {ChooseMerchantCard} from "./MerchantSelectInput.jsx";
import {merchantService} from "../../misc/MerchantService";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";

export class ChooseMerchantPageStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "center"
    }
}

@registerStyle(ChooseMerchantPageStyle)
export class ChooseMerchantPage extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Choose your merchant",
        };
    }

    render() {
        if (!merchantService.isMerchantDataRequestFinished()) {
            return [
                <div className={this.styleSheet.mainPage}>
                    <LoadingSpinner />
                </div>,
            ];
        }

        return <ChooseMerchantCard />;
    }
}
