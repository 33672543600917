import {FormContainerPage} from "../../blinkpay/common/FormContainerPage.jsx";
import {SOCIAL_APPS} from "../../blink-sdk/Constants.js";
import {AuthFlowPanel} from "../../blinkpay/common/auth/AuthFlowPanel.jsx";


export const DashboardLoginPage = FormContainerPage(AuthFlowPanel, {
    showFooter: false,
    useSecureCode: true,
    createUserAutomatically: false,
    externalAuthProviders: [SOCIAL_APPS.Google],
});
