import {UI} from "../../../stem-core/src/ui/UIBase";
import {DonationOfferStore} from "../../../client/state/DonationOfferStore";
import {DashboardTitle} from "../../common/DashboardTitle";
import {DonationOfferEditor} from "./components/DonationOfferEditor.jsx";


export class MerchantDonationSettingsPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        const donationOffer = DonationOfferStore.findBy({merchant});

        return [
            <DashboardTitle
                title="Donation settings"
                description="Configure everything related to the donation flows"
            />,

            donationOffer ?
                <DonationOfferEditor donationOffer={donationOffer}/>
                :
                <div style={{fontSize: 18, marginTop: 24}}>
                    Donations are not enabled, you can active them by contacting us at <a>biz.support@blink.net</a>.
                </div>
        ]

    }
}
