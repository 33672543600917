import {UI, styleRule, registerStyle, StyleSheet} from "../../../stem-core/src/ui/UI";
import {SwitchAccountCard} from "./SwitchAccountCard";
import {Router} from "../../../stem-core/src/ui/Router";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {merchantService} from "../../misc/MerchantService";
import {MerchantStore} from "../../../client/state/MerchantStore.js";

export class SwitchAccountPageStyle extends StyleSheet {
    @styleRule
    pageElement = {
        maxWidth: 900,
        margin: "auto",
        paddingTop: 90,
    }
}

@registerStyle(SwitchAccountPageStyle)
export class SwitchAccountPage extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.pageElement);
    }

    render() {
        if (!merchantService.isMerchantDataRequestFinished()) {
            return <LoadingSpinner />
        }

        if (MerchantStore.all().length > 0) {
            Router.changeURL("", {replaceHistory: true});
            return [];
        }

        return <SwitchAccountCard />
    }

    onMount() {
        this.attachListener(merchantService, "merchantDataRequestFinished", () => this.redraw());
    }
}
