import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {DateCalendarMonthInputInterface} from "../base/DateCalendarMonthInputInterface";
import {DateCalendarDay} from "./DateCalendarDay";


export class DateCalendarMonthInput extends DateCalendarMonthInputInterface {
    renderDayElement(date) {
        const {monthDate, minDate, maxDate} = this.options;

        return <DateCalendarDay date={date} hoverDate={this.hoverDate} onHover={date => this.onDateHover(date)}
                                onSelect={date => this.onDateSelection(date)}
                                selectedDate={this.value} monthDate={monthDate}
                                minDate={minDate} maxDate={maxDate}/>
    }

    onDateSelection(date) {
        this.setAndDispatchValue(date);
    }
}
