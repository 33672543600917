import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {merchantService} from "../misc/MerchantService";
import {MerchantStore} from "../../client/state/MerchantStore.js";
import {ChooseMerchantModal} from "../pages/choose-merchant/MerchantSelectInput.jsx";


export class SidebarMerchantSelectorStyle extends StyleSheet {
    @styleRule
    nameContainer = {
        fontSize: 18,
        fontWeight: 700,
    };

    @styleRule
    change = {
        marginTop: 4,
        fontSize: 14,
        cursor: "pointer",
        color: "#0080FF",
    };
}

@registerStyle(SidebarMerchantSelectorStyle)
export class SidebarMerchantSelector extends UI.Element {
    render() {
        const {styleSheet} = this;
        const merchant = merchantService.getMerchant();
        const elements = [
            <div className={styleSheet.nameContainer}>{merchant.getName()}</div>,
        ];

        if (MerchantStore.all().length > 1) {
            elements.push(
                <div className={styleSheet.change} onClick={() => this.onChangeClick()}>
                    Change
                </div>
            );
        }

        return elements;
    }

    onChangeClick() {
        ChooseMerchantModal.show();
    }
}
