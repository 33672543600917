import {UI} from "../../stem-core/src/ui/UIBase.js";
import {apiMerchantRestartTask, MerchantRecurringTaskStore} from "../../client/state/merchant/MerchantRecurringTaskStore.js";
import {DashboardTimeDelta} from "../common/Base.js";
import {TaskStatus} from "../../client/state/misc/GenericEnums.js";
import {Duration, TimeUnit} from "../../stem-core/src/time/Duration.js";
import {StemDate} from "../../stem-core/src/time/Date.js";
import {ProgressBar} from "../../stem-core/src/ui/ProgressBar.jsx";
import {Level} from "../../stem-core/src/ui/Constants.js";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw.js";
import {Button} from "../../stem-core/src/ui/button/Button.jsx";
import {apiClient} from "../../client/connection/BlinkApiClient.js";


// Makes sense when status == IN_PROGRESS
function RenderTaskInProgress(merchantTask) {
    const {status, progress, startedAt, updatedAt} = merchantTask;

    const now = StemDate.now();
    const durationSinceStart = new Duration(now - startedAt);
    const durationSinceLastUpdated = new Duration(now - updatedAt);
    const isFailed = (status === TaskStatus.FAILED);

    return [
        <p>Task {(progress * 100).toFixed(1)}% done in {durationSinceStart} (started {startedAt})</p>,
        <ProgressBar
            level={isFailed ? Level.ERROR : Level.PRIMARY}
            value={progress}
            style={{maxWidth: 480}}
        />,
        <p>Last updated {durationSinceLastUpdated} ago</p>,
    ]
}

function RenderTaskStatusSpecific(merchantTask) {
    const {status} = merchantTask;
    if (status === TaskStatus.PENDING) {
        return <p>Waiting to be executed since <DashboardTimeDelta date={merchantTask.enqueuedAt}/></p>
    }

    if (status === TaskStatus.IN_PROGRESS || status === TaskStatus.FAILED) {
        return RenderTaskInProgress(merchantTask);
    }

    return <p>Last finished: {DashboardTimeDelta.dateAndDelta(merchantTask.finishedAt)}</p>;
}


@autoredraw
class MerchantRecurringTaskEditor extends UI.Element {
    render() {
        const {merchantCustomTask} = this.options;
        const {status, isActive} = merchantCustomTask;
        const durationSinceLastUpdate = new Duration(StemDate.now() - merchantCustomTask.updatedAt);
        const canRestart = isActive && ((status !== TaskStatus.IN_PROGRESS) || (durationSinceLastUpdate > 10 * TimeUnit.MINUTE));

        return [
            <h1>{merchantCustomTask.provider} - {merchantCustomTask.type} ({merchantCustomTask.isActive ? "Active" : "Disabled"})</h1>,
            merchantCustomTask.isActive && <p>
                Scheduled to start: {DashboardTimeDelta.dateAndDelta(merchantCustomTask.nextScheduled)}
            </p>,

            canRestart && <div>
                <Button onClick={() => apiMerchantRestartTask(merchantCustomTask)}>
                    Restart{(status === TaskStatus.IN_PROGRESS) && " (Forced)"}
                </Button>
            </div>,

            <p>Status: {status}</p>,
            RenderTaskStatusSpecific(merchantCustomTask),

            <pre>{JSON.stringify(merchantCustomTask.publicConfig, null, 3)}</pre>
        ]
    }
}


export class MerchantRecurringTasksPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        const merchantCustomTasks = MerchantRecurringTaskStore.filterBy({merchant});

        if (merchantCustomTasks.length === 0) {
            // TODO: don't show this page in that case
            return <h1>No custom tasks configured in Blink</h1>
        }

        return merchantCustomTasks.map(customTask => <MerchantRecurringTaskEditor merchantCustomTask={customTask} />);
    }
}
