import {MakeStore} from "../../../stem-core/src/state/Store";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";
import {BaseLogMessage} from "../misc/BaseLogMessage.js";
import {TaskStatus} from "../misc/GenericEnums.js";


class MerchantLogMessage extends BaseLogMessage {
    @field("MerchantUser") user; // Type is changed from base
    @field("MerchantUser") assignedTo;
    @field(TaskStatus) status;
}

export const MerchantLogMessageStore = MakeStore("MerchantLogMessage", MerchantLogMessage);

export async function apiMerchantEditLogMessage(request) {
    return apiClient.post("/merchant/edit_log_message", request);
}

export async function apiMerchantDeleteLogMessage(request) {
    return apiClient.post("/merchant/delete_log_message", request);
}
