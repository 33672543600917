import {UI} from "../../../../stem-core/src/ui/UIBase";
import {IFrame} from "../../../../stem-core/src/ui/UIPrimitives";
import {BLINK_PAY_URL} from "../../../../blink-sdk/Constants";
import {merchantService} from "../../../misc/MerchantService";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {LinearSizeConverter, RangeSlider} from "../../../../blinkpay/ui/RangeSlider";
import {Direction} from "../../../../stem-core/src/ui/Constants";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib.js";


class PanelEditorPreviewStyle extends StyleSheet {
    @styleRule
    slidersAreaContainer = {
        display: "flex",
        justifyContent: "space-around",
    }

    @styleRule
    slidersArea = {
        display: "flex",
    };

    @styleRule
    sliderBlock = {
        display: "inline-flex",
        alignItems: "center",
        minWidth: 320,
        paddingLeft: 12,
        paddingRight: 12,
    };

    @styleRule
    rangeSlider = {
        width: 300,
        margin: "12px 0",
    };
}

@registerStyle(PanelEditorPreviewStyle)
class PanelEditorPreviewIFrameContainer extends UI.Element {
    isPreviewIFrameReady = false;

    render() {
        const {height, width} = this.options;
        const sdkScriptURL = encodeURIComponent(`${BLINK_PAY_URL}/1.0/blink-sdk.js`);
        const previewIFrameSrc = `/panel-editor-preview.html?sdkScriptURL=${sdkScriptURL}`;

        return <IFrame
            ref="previewIFrame"
            height={height}
            width={width}
            style="border: 1px solid black; box-sizing: content-box"
            src={previewIFrameSrc}
        />;
    }

    redraw() {
        if (!this.previewIFrame) {
            super.redraw();
        } else {
            // TODO: Throttle this!
            this.updatePreviewIFrame();
        }
    }

    updatePreviewIFrame() {
        const {panelOptions, height, width} = this.options;

        this.previewIFrame.setStyle({height, width});

        // We explicitly want to test what would happen on purely a resize, so nothing more should happen if only the size changes
        if (isDeepEqual(panelOptions, this.cachedPanelOptions)) {
            return;
        }

        this.cachedPanelOptions = panelOptions;

        if (this.isPreviewIFrameReady && this.previewIFrame.node.contentWindow) {
            this.previewIFrame.node.contentWindow.postMessage(panelOptions, window.origin);
        }
    }

    handlePreviewIFrameMessage(data) {
        if (data === "ReadyForInitialOptions") {
            this.previewIFrame.node.contentWindow.postMessage({
                sdkInitOptions: {
                    clientId: merchantService.getMerchant().alias,
                    hideWallet: true,
                    journeys: null,
                },
                panelOptions: this.options.panelOptions,
            }, window.origin);
        }

        if (data === "ReadyForUpdates") {
            this.isPreviewIFrameReady = true;
        }

        this.updatePreviewIFrame();
    }

    onMount() {
        this.attachEventListener(window, "message", (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.source !== this.previewIFrame.node.contentWindow) {
                return;
            }
            this.handlePreviewIFrameMessage(event.data);
        });
    }
}

@registerStyle(PanelEditorPreviewStyle)
export class PanelEditorPreview extends UI.Element {
    updatePreview() {
        const options = this.getPreviewContainerOptions();
        this.previewIFrameContainer?.updateOptions(options);
    }

    getPreviewContainerOptions() {
        const {state} = this.options;
        const {height, width} = state.dashboardOptions;

        return {
            panelOptions: state.getPreviewPanelOptions(),
            height,
            width,
        };
    }

    render() {
        const {styleSheet} = this;
        const {state} = this.options;

        return [
            <div className={styleSheet.slidersAreaContainer}>
                <div className={styleSheet.slidersArea}>
                    <div className={styleSheet.sliderBlock}>
                        <div style={{paddingRight: 4}}>Height</div>
                        <RangeSlider
                            initialValue={state.dashboardOptions.height}
                            direction={Direction.UP}
                            className={styleSheet.rangeSlider}
                            converter={new LinearSizeConverter(200, 1080)}
                            onChange={(value) => state.editDashboardOptions({height: value})}/>
                    </div>
                    <div className={styleSheet.sliderBlock}>
                        <div style={{paddingRight: 4}}>Width</div>
                        <RangeSlider
                            initialValue={state.dashboardOptions.width}
                            direction={Direction.UP}
                            className={styleSheet.rangeSlider}
                            converter={new LinearSizeConverter(360, 1600)}
                            onChange={(value) => state.editDashboardOptions({width: value})}/>
                    </div>
                </div>
            </div>,
            <PanelEditorPreviewIFrameContainer ref="previewIFrameContainer" {...this.getPreviewContainerOptions()} />,
        ];
    }

    onMount() {
        this.attachChangeListener(this.options.state, () => this.updatePreview());
    }
}
