import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {CycleUnit} from "../../../client/state/RecurringPaymentStoreObject";
import {NumberInput, Select} from "../../../stem-core/src/ui/input/Input";
import {capitalize} from "../../../stem-core/src/base/Utils";


@makeEnum
class BillingCycleSelectOptions extends BaseEnum {
    static DAILY = {cycleUnit: CycleUnit.DAY};
    static WEEKLY = {cycleUnit: CycleUnit.WEEK};
    static MONTHLY = {cycleUnit: CycleUnit.MONTH};
    static YEARLY = {cycleUnit: CycleUnit.YEAR};
    static EVERY = {cycleUnit: null, name: "Custom, every"};

    static findMatching(cycleUnit, cycleUnitCount) {
        if (cycleUnitCount > 1) {
            return this.EVERY;
        }

        return this.all().find(obj => obj.cycleUnit === cycleUnit);
    }
}


export class BillingCycleInput extends UI.Element {
    primarySelectInput = null; // Refs
    cycleUnitCountInput = null;
    secondarySelectInput = null;
    extraInputs = null;

    setValue(value) {
        const {cycleUnit = CycleUnit.MONTHLY, cycleUnitCount = 1} = value;
        const mainCycle = BillingCycleSelectOptions.findMatching(cycleUnit, cycleUnitCount);
        this.primarySelectInput.setValue(mainCycle);
        if (mainCycle === BillingCycleSelectOptions.EVERY) {
            this.cycleUnitCountInput.setValue(cycleUnitCount);
            this.secondarySelectInput.setValue(cycleUnit);
        }
        this.recheckVisibility();
    }

    getValue() {
        const {cycleUnit} = this.primarySelectInput.getValue();
        if (cycleUnit) {
            return {cycleUnit, cycleUnitCount: 1};
        }
        return {
            cycleUnit: this.secondarySelectInput.getValue(),
            cycleUnitCount: this.cycleUnitCountInput.getValue(),
        }
    }

    recheckVisibility() {
        const selectBillingCycleType = this.primarySelectInput.getValue();
        if (selectBillingCycleType === BillingCycleSelectOptions.EVERY) {
            this.extraInputs.setStyle({display: "inline-block"});
        } else {
            this.extraInputs.setStyle({display: "none"});
        }
    }

    render() {
        return [
            <Select
                options={BillingCycleSelectOptions.all()}
                initialValue={BillingCycleSelectOptions.MONTHLY}
                onChange={() => this.recheckVisibility()}
                ref={"primarySelectInput"} />,
            <div style={{display: "inline-block"}} ref="extraInputs">
                <NumberInput
                    style={{margin: "0 8px"}}
                    min={1} max={30}
                    initialValue={2}
                    ref="cycleUnitCountInput"/>
                <Select
                    options={[CycleUnit.DAY, CycleUnit.WEEK, CycleUnit.MONTH, CycleUnit.YEAR]}
                    initialValue={CycleUnit.MONTH}
                    serializer={cycleUnit => capitalize(cycleUnit.timeUnit.getPluralName())}
                    ref="secondarySelectInput"
                    />
            </div>
        ]
    }

    // TODO @Mihai maybe inherit InputableElement, with mods?
    onMount() {
        const {initialValue} = this.options;
        initialValue && this.setValue(initialValue);
        this.recheckVisibility();
    }
}
