import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {Theme} from "../../../../../stem-core/src/ui/style/Theme";
import {styleRule, StyleSheet} from "../../../../../stem-core/src/ui/Style";


export class PanelEditorTabStyle extends StyleSheet {
    @styleRule
    inputField = {
        padding: 8,
        width: "100%",
    };

    @styleRule
    textInput = {
        width: "100%",
        maxWidth: 600,
    };

    @styleRule
    textAreaInput = {
        width: "100%",
        maxWidth: 600,
        resize: "vertical",
        minHeight: 120,
    };

    @styleRule
    codeInput = {
        fontFamily: Theme.props.FONT_FAMILY_MONOSPACE,
    };
}
