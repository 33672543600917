import {UI} from "../../../stem-core/src/ui/UIBase";
import {isNumber} from "../../../stem-core/src/base/Utils";
import {ProgressBar} from "../../../stem-core/src/ui/ProgressBar.jsx";
import {Level} from "../../../stem-core/src/ui/Constants";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {StoreObject} from "../../../stem-core/src/state/Store";
import {Theme} from "../../../stem-core/src/ui/style/Theme.js";
import {formatPercentFromRatio} from "../../../stem-core/src/base/Formatting.js";

// TODO: for autoredraw, we need to inherit StoreObject. Maybe better pattern
export class AudienceMemberImportStatus extends StoreObject {
    error = null;
    processing = true;
    entriesProcessed = 0;
    newMembersCount = 0;
    newUsersCount = 0;  // A subset of newMembersCount
    changedMembersCount = 0;
    unchangedMembersCount = 0;
    unchangeableMembersCount = 0; // A subset of unchangedMembersCount
    invalidMembersCount = 0;
    missingEmailCount = 0;

    constructor(numEntries) {
        super();
        this.numEntries = numEntries;
    }

    // TODO This seems to be like applyEventAndDispatch in StoreObject
    update(options={}) {
        Object.assign(this, options);
        this.dispatchChange(this);
    }

    addResponse(requestInfo, response) {
        this.entriesProcessed += requestInfo.size;
        for (const key of Object.keys(this)) {
            if (response[key] && isNumber(response[key])) {
                this[key] += response[key];
            }
        }
        this.update();
    }

    setError(error) {
        this.update({error});
    }

    setProcessing(processing) {
        this.update({processing});
    }

    isProcessing() {
        return this.processing;
    }

    isFinished() {
        return this.numEntries === this.entriesProcessed;
    }

    isStopped() {
        return !this.processing && !this.isFinished();
    }

    getStatusMessage() {
        if (this.isStopped()) {
            return this.error ? "Error importing" : "Stopped";
        }
        if (this.isFinished()) {
            return "Import finished";
        }
        return "Importing file";
    }

    getProgressMessage() {
        const {entriesProcessed, numEntries} = this;
        return (entriesProcessed < numEntries) ?
            `Processed ${entriesProcessed} / ${numEntries} entries (${formatPercentFromRatio(entriesProcessed, numEntries)})`
            : `Processed ${numEntries} entries`
    }

    ratioDone() {
        return this.numEntries > 0 ? this.entriesProcessed / this.numEntries : 0.0;
    }
}

@autoredraw
export class AudienceImportStatusElement extends UI.Element {
    render() {
        const {status} = this.options;

        const {
            newMembersCount,
            newUsersCount,
            changedMembersCount,
            unchangedMembersCount,
            unchangeableMembersCount,
            invalidMembersCount,
            missingEmailCount,
        } = status;

        const MakeEntry = (count, text, {depth=0, color=Theme.props.COLOR_PRIMARY}={}) => {
            // TODO: should stylize the bullet
            return (count > 0) && <li style={{paddingLeft: depth * 16, paddingTop: 4}}>
                <div style={{display: "inline-block", color: color}}>{count}</div> {text}
            </li>;
        }

        return [
            <div style={{width: 480}}>
                <div style={{fontWeight: "bold", display: "flex", alignItems: "center"}}>
                    <div>{status.getStatusMessage()}</div>
                    <div style={{flex: 1}}/>
                    <div>{status.getProgressMessage()}</div>
                </div>
                <ProgressBar level={Level.PRIMARY} value={status.ratioDone()}/>
            </div>,
            MakeEntry(newMembersCount, "newly added"),
                MakeEntry(newUsersCount, "new users", {depth: 1}),
            MakeEntry(changedMembersCount + unchangedMembersCount, "existing"),
                MakeEntry(changedMembersCount, "updated", {depth: 1}),
                MakeEntry(unchangedMembersCount - unchangeableMembersCount, "skipped (identical)", {depth: 1}),
                MakeEntry(unchangeableMembersCount, "unsubscribed by themselves, cannot update", {depth: 1}),
            MakeEntry(invalidMembersCount, "invalid", {color: Theme.props.ERROR}),
            MakeEntry(missingEmailCount, "missing email, skipped"),
        ];
    }
}
