// Class to wrap a table of entries where we can access one by clicking it
import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTitle} from "./DashboardTitle";
import {Router} from "../../stem-core/src/ui/Router";
import {SimpleTable, MakeLinkingRowClass} from "../ui/SimpleTable";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {openWindowWithOpener} from "../../blinkpay/UtilsLib.js";
import {getEditingOrLiveSettingsVersion} from "../../client/state/merchant/MerchantSDKSettingsStore.js";

export class DashboardSelectorStyle extends StyleSheet {
    @styleRule
    headerButtons = {
        paddingBottom: 12,
    }
}

@registerStyle(DashboardSelectorStyle)
export class DashboardSelectorPage extends UI.Element {
    // Breadcrumb style titles
    renderTitle(subtitle = null, description = this.options.description) {
        let title = this.options.title || "NoTitle";
        if (subtitle) {
            title = [
                <a onClick={(event) => {
                        if (event.metaKey || event.ctrlKey) {
                            return;
                        }
                        this.goToEntry(null);
                        event.preventDefault();
                    }}
                    href={this.getUrlForEntry()}
                    style={{cursor: "pointer"}}>{title}</a>,
                " / ",
                subtitle
            ];
        }
        return <DashboardTitle title={title} description={description} />
    }

    // Return an array of url parts
    getUrlForEntry(entry) {
        const {baseURL, useAliasInURL} = this.options;
        if (!baseURL) {
            return null;
        }
        const url = [this.options.baseURL];
        // Try to use the alias, but fall back to id just in case
        const entryKey = (useAliasInURL && entry?.alias) || entry?.id;
        if (entryKey) {
            url.push(String(entryKey));
        }
        return Router.formatURL(url);
    }

    goToEntry(entry) {
        this.selectedEntry = entry;
        const url = this.getUrlForEntry(entry);
        if (url) {
            // This will also call a redraw
            Router.changeURL(url);
        } else {
            this.redraw();
        }
    }

    updateEntryById(entryId) {
        const {merchant, store, useAliasInURL} = this.options;
        if (!store) {
            console.error("Store is required to set entry to id", entryId);
            return
        }
        if (useAliasInURL) {
            const editingSettingsVersion = getEditingOrLiveSettingsVersion(merchant);
            this.selectedEntry = store.findBy({alias: entryId, settingsVersion: editingSettingsVersion}) || store.get(entryId);
        } else {
            this.selectedEntry = store.get(entryId);
        }
    }

    setOptions(options) {
        super.setOptions(options);
        const {args} = options;
        if (args) {
            if (args.length > 0) {
                this.updateEntryById(args[0]);
            } else {
                this.goToEntry(null);
            }
        }
    }

    getEntryRowClass() {
        return MakeLinkingRowClass((entry, event) => {
            const specialKeyPressed = event.shiftKey || event.ctrlKey || event.metaKey;
            if (specialKeyPressed) {
                openWindowWithOpener(this.getUrlForEntry(entry));
            } else {
                this.goToEntry(entry);
            }
        });
    }

    makeTable(entries, columns, extraOptions={}) {
        return <SimpleTable columns={columns} entries={entries} rowClass={this.getEntryRowClass()} {...extraOptions} />
    }
}
