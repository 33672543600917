import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {ConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal.jsx";
import {Select} from "../../../../stem-core/src/ui/input/Input.jsx";


export class AddJourneyToEntrypointModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            confirmAction: () => this.resolve(this.select.get()),
        };
    }

    render() {
        return [
            <Select ref="select" options={this.options.availableJourneys}/>,
        ];
    }
}
