import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {DashboardSection} from "../../common/DashboardSection.jsx";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {BlinkInputField} from "../../common/Input.jsx";
import {DashboardMoneyInput} from "../../ui/input/DashboardMoneyInput.jsx";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {ShippingPricesInput} from "../../common/input/ShippingPriceEditor";
import {TextArea, TextInput} from "../../../stem-core/src/ui/input/Input.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {apiMerchantEditProduct} from "../../../client/state/ProductStore.js";
import {Toast} from "../../../blinkpay/ui/Toast.jsx";
import {EditableObjectStatus, VisibilityStatus} from "../../../client/state/misc/GenericEnums.js";
import {RadioButtonsInput} from "../../../core/ui/input/radio/RadioButtonsInput.jsx";


@autoredraw
export class MerchantProductEditor extends UI.Element {
    async saveChanges() {
        const {product} = this.options;
        const request = {
            productId: product.id,
            alias: this.aliasInput.getValue(),
            name: this.nameInput.getValue(),
            description: this.descriptionInput.getValue(),
            image: this.imageInput.getValue() || null,
            amount: this.basePriceInput.getValue(false),
            requiresAddress: this.requiresAddressInput.getValue(),
            status: this.statusInput.getValue(),
            visibility: this.visibilityInput.getValue(),
        };

        if (product.status ===  request.status) {
            // TODO @Mihai do this for everything, right now handling around Draft -> Draft being illegal
            delete request.status;
        }

        if (!request.alias) {
            request.alias = null;
        }

        if (this.shippingPricesInput) {
            request.shippingPrices = this.shippingPricesInput.getValue();
        }

        await apiMerchantEditProduct(request);
        Toast.showSuccess("Save successful");
    }

    render() {
        const {product} = this.options;
        // TODO The shipping price input should preserve state when hiding
        const shouldShowDeliveryPriceInput = this.requiresAddressInput ? this.requiresAddressInput.getValue() : product.requiresAddress;

        return [
            <div>
                <Button onClick={() => this.saveChanges()}>Save changes</Button>
            </div>,
            <DashboardSection title="General Settings">
                <div>
                    {/*TODO @cleanup merge how status and visibility work for products and subscription offers*/}
                    <BlinkInputField label="Status">
                        <RadioButtonsInput
                            ref="statusInput"
                            values={EditableObjectStatus.all()}
                            initialValue={product.status}
                        />
                    </BlinkInputField>
                    <BlinkInputField label="Visibility">
                        <RadioButtonsInput
                            values={VisibilityStatus.all()}
                            initialValue={product.visibility}
                            ref="visibilityInput"
                        />
                    </BlinkInputField>
                </div>
                <div>
                    <BlinkInputField label="Name">
                        <TextInput ref="nameInput" initialValue={product.name}/>
                    </BlinkInputField>
                    <BlinkInputField label="Alias (optional)">
                        <TextInput ref="aliasInput" initialValue={product.alias}/>
                    </BlinkInputField>
                </div>
                <div>
                    <BlinkInputField label="Description (optional)">
                        <TextArea ref="descriptionInput" initialValue={product.description} style={{height: 120}}/>
                    </BlinkInputField>
                </div>
                <div>
                    <BlinkInputField label="Image (full URL, optional)">
                        <TextInput ref="imageInput" initialValue={product.image} />
                    </BlinkInputField>
                    {/*TODO preview the image & allow upload*/}
                </div>
            </DashboardSection>,
            <DashboardSection isLast title="Pricing">
                <div>
                    <BlinkInputField label="Base price">
                        <DashboardMoneyInput
                            currency={product.currency}
                            initialValue={product.amount}
                            ref="basePriceInput"
                        />
                    </BlinkInputField>
                </div>
                <div>
                    <CheckboxInput label="Requires delivery address"
                        initialValue={product.requiresAddress}
                        onChange={() => this.redraw()}
                        ref="requiresAddressInput" />
                </div>
                {shouldShowDeliveryPriceInput && <div>
                    <ShippingPricesInput
                        currency={product.currency}
                        initialValue={product.getShippingPrices()}
                        ref="shippingPricesInput"
                    />
                </div>}
            </DashboardSection>,
        ]
    }
}
