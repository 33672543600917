import {StoreObject} from "../../../stem-core/src/state/Store.js";
import {TaskStatus} from "../../../client/state/misc/GenericEnums.js";
import {isNumber, toArray} from "../../../stem-core/src/base/Utils.js";


// TODO: for autoredraw, we need to inherit StoreObject. Maybe better pattern
export class BaseTaskProgressStatus extends StoreObject {
    errorMessage = null;
    phase = TaskStatus.PENDING;
    entriesDone = 0;
    shouldStop = false;

    constructor(entriesTotal = -1) {
        super();
        this.entriesTotal = entriesTotal;
    }

    isInProgress() {
        return this.phase === TaskStatus.IN_PROGRESS;
    }

    getProgressValue() {
        return (this.entriesTotal > 0) ? (this.entriesDone / this.entriesTotal) : 0.0;
    }

    getStatusMessage() {
        if (this.errorMessage) {
            return "Error: " + this.errorMessage;
        }
        if (this.phase === TaskStatus.FAILED) {
            return "Failed"
        }
        if (this.phase === TaskStatus.IN_PROGRESS) {
            return "In progress...";
        }
        if (this.phase === TaskStatus.SUCCEEDED) {
            return "Done"
        }
        return "";
    }

    getProgressMessage() {
        if (this.phase === TaskStatus.IN_PROGRESS) {
            if (this.entriesTotal === -1 && this.entriesDone === 0) {
                return "";
            }
            if (this.entriesTotal) {
                return `Processed ${this.entriesDone} / ${this.entriesTotal} entries`;
            }
        }
        // Also handle in progress with unknown number of total entries like done.
        if (this.phase === TaskStatus.SUCCEEDED || this.phase === TaskStatus.IN_PROGRESS) {
            return `Processed ${this.entriesDone} entries`;
        }
        return "";
    }

    setProgress(numDone, numTotal) {
        this.phase = TaskStatus.IN_PROGRESS;
        this.entriesDone = numDone;
        if (numTotal != null) {
            this.entriesTotal = numTotal;
        }
        this.dispatchChange();
    }

    incremProgress(numDone = 1, numTotal = null) {
        if (!isNumber(numDone)) {
            console.warn("Invalid task status increment argument");
        }
        this.setProgress(this.entriesDone + numDone, numTotal);
    }

    setDone() {
        this.phase = TaskStatus.SUCCEEDED;
        this.dispatchChange();
    }

    setStop(shouldStop = true) {
        this.shouldStop = shouldStop;
        this.dispatchChange();
    }

    setErrorMessage(errorMessage) {
        // TODO Have a standard way of stringifying errors
        this.errorMessage = errorMessage;
        this.phase = TaskStatus.FAILED;
        this.dispatchChange();
    }

    async processStream(stream, callback) {
        try {
            this.setProgress(0);
            for await (const response of stream) {
                const incremArgs = await callback(response);
                this.incremProgress(...toArray(incremArgs));
                if (this.shouldStop) {
                    break;
                }
            }
        } catch (error) {
            const errorMessage = error?.message || String(error);
            this.setErrorMessage(errorMessage);
        } finally {
            this.setDone();
        }
    }
}
