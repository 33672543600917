import {UI} from "../../../stem-core/src/ui/UIBase";
import {TopLevelTabArea} from "../../common/theme/TabAreaStyle";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {
    apiMerchantCreateBillingPlan,
    apiMerchantCreateSubscriptionOffer,
    SubscriptionOfferStore
} from "../../../client/state/SubscriptionOfferStore";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {BlinkInputField} from "../../common/Input";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {capitalize} from "../../../stem-core/src/base/Utils";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../common/theme/TableStyle";
import {MerchantSubscriptionOfferEditor} from "./MerchantSubscriptionOfferEditor";
import {BillingCycleInput} from "./BillingCycleInput";
import {DashboardMoneyInput} from "../../ui/input/DashboardMoneyInput";
import {apiMerchantEditSubscriptionCoverage, SubscriptionCoverageStore} from "../../../client/state/SubscriptionCoverageStore";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {SubscriptionBenefitsEditor} from "./SubscriptionBenefitsEditor";
import {Level} from "../../../stem-core/src/ui/Constants";
import {DashboardLabel} from "../../common/DashboardLabel";
import {DashboardSection} from "../../common/DashboardSection.jsx";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {EditableObjectStatus} from "../../../client/state/misc/GenericEnums";

export function MakeSubscriptionOfferStatusLabel(subscriptionOffer) {
    return subscriptionOffer.isAvailable() ?
        <DashboardLabel label="Active" /> :
        <DashboardLabel label="Inactive" level={Level.WARNING} />
}

// TODO reenable
class SubscriptionStatsPanel extends UI.Element {
    render() {
        const {subscriptionCoverage} = this.options;
        return [<h1>Stats go here</h1>];
    }
}

class SubscriptionCoverageGeneralPanel extends UI.Element {
    editing = true;

    makeEditable(editing) {
        this.editing = editing;
        this.redraw();
    }

    // TODO @cleanup use SaveOrDiscardButtons here as well
    async saveChanges() {
        const {subscriptionCoverage} = this.options;
        const request = {
            coverageId: subscriptionCoverage.id,
            name: this.subscriptionNameInput.getValue(),
            billableProRata: this.billableProRata.getValue(),
            includesCoverageId: this.includesCoverageInput?.getValue().id || null, // TODO @Mihai maybe we want to allowNulls by default, but not undefined.
            benefitIds: this.benefitsInput.getValue(),
            requiresAddress: this.requiresAddressInput.getValue(),
            addressRequiresPostalCode: this.addressRequiresPostalCodeInput.getValue(),
            addressRequiresPhoneNumber: this.addressRequiresPhoneNumberInput.getValue(),
        }
        const response = apiMerchantEditSubscriptionCoverage(request);
    }

    getButtonSection() {
        return <div>
            {!this.editing && <Button onClick={() => this.makeEditable(true)}>Enable editing</Button>}
            {/*{this.editing && <Button onClick={() => this.makeEditable(false)}>Cancel editing</Button>}*/}
            {this.editing && <Button onClick={() => this.saveChanges()}>Save Changes</Button>}
            </div>;
    }

    getSubscriptionCoverage() {
        const {subscriptionCoverage} = this.options;
        // TODO would need to do a DF of coverages here
        const includableCoverages = [
            "No other subscription",
            ...SubscriptionCoverageStore.filter(coverage => coverage != subscriptionCoverage && coverage.includesCoverage != subscriptionCoverage),
        ];

        let componentStyle = {};
        if (!this.editing) {
            componentStyle.pointerEvents = "none";
        }

        return <div style={componentStyle} ref="coverageSection">
            <DashboardSection title="General Settings">
                <BlinkInputField label="Name">
                    <TextInput ref="subscriptionNameInput" initialValue={subscriptionCoverage.name}/>
                </BlinkInputField>
                {/*TODO refund policy*/}
                <div>
                    <CheckboxInput label={"Billable Pro Rata - When changing their subscription, users would receive credit equal to unspent time."}
                        initialValue={subscriptionCoverage.billableProRata}
                        ref="billableProRata"/>
                </div>
            </DashboardSection>
            <DashboardSection title="Perks/benefits">
                {includableCoverages.length > 1 && <div>
                    Include benefits from subscription <Select initialValue={subscriptionCoverage.includesCoverage || includableCoverages[0]} options={includableCoverages} ref="includesCoverageInput"/>
                </div>}
                <SubscriptionBenefitsEditor initialValue={subscriptionCoverage.benefitIds} ref="benefitsInput" />
            </DashboardSection>
            <DashboardSection
                title="Address Rules"
                description="Configure if any subscription of this tier needs to have a user address collected, and what's required in the address."
            >
                <div>
                    <CheckboxInput label={"Address required - Controls if an address will be required during the subscription flow."}
                        initialValue={subscriptionCoverage.requiresAddress}
                        ref="requiresAddressInput" />
                </div>
                <div>
                    <CheckboxInput label={"Address requires postal/zip code."}
                        initialValue={subscriptionCoverage.addressRequiresPostalCode}
                        ref="addressRequiresPostalCodeInput"/>
                </div>
                <div>
                    <CheckboxInput label={"Address requires phone number."}
                        initialValue={subscriptionCoverage.addressRequiresPhoneNumber}
                        ref="addressRequiresPhoneNumberInput"
                    />
                </div>
            </DashboardSection>
        </div>
    }

    render() {
        return [
            this.getButtonSection(),
            this.getSubscriptionCoverage(),
        ]
    }
}

class CreateSubscriptionPlanDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            title: "New subscription plan for " + options.subscriptionCoverage,
            description: "Create a new payment plan for this subscription tier. ",
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve(this.getValue()),
        }
    }

    getValue() {
        const {subscriptionCoverage} = this.options;
        const price = this.priceInput.getValue();
        const {cycleUnit, cycleUnitCount} = this.billingCycleInput.getValue();
        return {
            // TODO add in the rest
            createBillingPlanRequest: {
                // currencyId: price.currency.id, // TODO allow this to be configurable
                amount: price.amount,
                cycleUnit,
                cycleUnitCount,
                shippingPrices: null,
                status: EditableObjectStatus.DRAFT,
            },
            createSubscriptionOfferRequest: {
                coverageId: subscriptionCoverage.id,
                title: this.nameInput.getValue(),
                alias: null,
                description: null,
            },
        };
    }

    render() {
        const {subscriptionCoverage} = this.options;
        const merchant = subscriptionCoverage.merchant;
        return [
            <div>
                <BlinkInputField label="Plan name (optional)">
                    <TextInput ref="nameInput" />
                </BlinkInputField>
            </div>,
            // TODO bundle these in a single component?
            <div>
                <BlinkInputField label="Price">
                    <DashboardMoneyInput
                        style={{width: 120}}
                        currency={merchant.getCurrency()} ref="priceInput"/>
                </BlinkInputField>
                <BlinkInputField label="Billing cycle">
                    <BillingCycleInput ref="billingCycleInput"/>
                </BlinkInputField>
            </div>
        ]
    }
}

class SubscriptionPlansPanel extends DashboardSelectorPage {
    async createSubscriptionPlan() {
        const {subscriptionCoverage} = this.options;
        const requests = await CreateSubscriptionPlanDialog.prompt({subscriptionCoverage});
        if (!requests) {
            return;
        }
        const {createBillingPlanRequest, createSubscriptionOfferRequest} = requests;
        const billingPlan = await apiMerchantCreateBillingPlan(createBillingPlanRequest);
        createSubscriptionOfferRequest.billingPlanId = billingPlan.id;
        const subscriptionPlan = await apiMerchantCreateSubscriptionOffer(createSubscriptionOfferRequest);
        subscriptionPlan && this.goToEntry(subscriptionPlan);
    }

    // TODO @Mihai how about a stem decorator @setContext({parentPage: this}), so that getContext().parentPage would be this, popping at the end?
    render() {
        const {selectedEntry} = this;

        if (selectedEntry) {
            return [
                <div style={{display: "flex"}}>
                    <Button onClick={() => this.goToEntry(null)} level={Level.SECONDARY}>{"<"} Back to all subscription plans</Button>
                </div>,
                <MerchantSubscriptionOfferEditor parentPage={this} subscriptionOffer={selectedEntry} />
            ]
        }

        const {subscriptionCoverage} = this.options;
        const subscriptionPlans = SubscriptionOfferStore.filterBy({coverageId: subscriptionCoverage.id});
        const columns = [
            ["Id", plan => plan.id, LEFT_COLUMN],
            ["Name", plan => plan.getName(), LEFT_COLUMN],
            ["Billing cycle", plan => {
                const {cycleUnit, cycleUnitCount} = plan.billingPlan;
                return (cycleUnitCount > 1) ? `Every ${cycleUnitCount} ${cycleUnit.timeUnit.getPluralName()}` : capitalize(cycleUnit.getFrequencyName());
            }],
            ["Base Price", plan => plan.billingPlan.getBasePrice(), MONEY_COLUMN],
            ["Shipping price", plan => {
                // TODO same as with to pricing
                const shippingPrices = plan.billingPlan.getShippingPrices();
                if (shippingPrices.length === 0) {
                    return "-";
                }
                const prices = shippingPrices.map(obj => obj.amount);
                const minPrice = Math.min(...prices);
                const maxPrice = Math.max(...prices);
                if (minPrice === maxPrice) {
                    return new Money(minPrice, plan.billingPlan.currency);
                }
                return `${new Money(minPrice, plan.billingPlan.currency)} to ${new Money(maxPrice, plan.billingPlan.currency)}`;
            }, CENTER_COLUMN],
            ["Active subscribers", plan => plan.currentSubscribersCount || 0],
            ["All time subscribers", plan => plan.allTimeSubscribersCount || 0],
            ["Status", plan => MakeSubscriptionOfferStatusLabel(plan), CENTER_COLUMN],
        ];
        return [
            <div>
                <Button onClick={() => this.createSubscriptionPlan()}>New plan</Button>
            </div>,
            <div>
                All the plans available for this subscription tier.
                Plan are editable only until they become active (by being made public, or by having subscribers).
                Multiple plans can exist with the same billing cycle.
            </div>,
            this.makeTable(subscriptionPlans, columns),
        ];
    }
}


// Control a subscription coverage and all the plans for that coverage
export class MerchantSubscriptionInstancePage extends UI.Element {
    render() {
        const {subscriptionCoverage} = this.options;
        // TODO have 2 panels for subscription plans, the active and archived ones?
        return [
            <TopLevelTabArea>
                {/*<SubscriptionStatsPanel subscriprionCoverage={subscriptionCoverage} title="Statistics" />*/}
                <SubscriptionCoverageGeneralPanel subscriptionCoverage={subscriptionCoverage} title="General" />
                <SubscriptionPlansPanel subscriptionCoverage={subscriptionCoverage} title="Plans" />
            </TopLevelTabArea>
        ]
    }
}
