import {UI} from "../../stem-core/src/ui/UIBase";
import {BlinkInput} from "../../blinkpay/ui/Input";
import {NumberInput} from "../../stem-core/src/ui/input/Input";
import {StemDate} from "../../stem-core/src/time/Date";
import {Duration, TimeUnit} from "../../stem-core/src/time/Duration";

export class BlinkNumberInput extends BlinkInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            initialValue: 0,
            InputClass: NumberInput,
            inputAttributes: {
                min: 0,
            }
        };
    }

    getValue() {
        return parseFloat(super.getValue()) || 0;
    }
}

export class DashboardTimeDelta extends UI.TextElement {
    timeout = null;

    constructor(date) {
        super();
        this.date = date;
    }

    getValue() {
        const {date} = this;
        const now = StemDate.now();
        const duration = new Duration(StemDate.create(date) - StemDate.now());
        const {parts, timeUnit} = duration.splitInParts(2, TimeUnit.SECOND);
        // TODO still very confusing if dates are immutable or not
        const nextRefresh = now.clone().addUnit(timeUnit).roundDown(timeUnit).add(100 * TimeUnit.MILLISECOND);
        const timeoutDuration = Math.max(1000, +nextRefresh - now);

        // TODO Have only one per second globally
        setTimeout(() => this.redraw(), timeoutDuration);
        let value = parts.map(part => part.timeUnit.formatCount(part.numUnits)).join(" ");
        return duration > 0 ? `in ${value}` : `${value} ago`;
    }

    static dateAndDelta(date, messageIfNull = "Never") {
        return date ? [
            date,
            " (",
            new this(date),
            ")"
        ] : messageIfNull;
    }
}
