import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {MerchantUserStore} from "./merchant/MerchantUserStore.js";

class ExternalAppRequestInstance extends StoreObject {
    @field("ExternalAppConfig") app;
    @field(Date) createdAt;
    @field("UserProfile") user;
    @field("Merchant") merchant;
    @field(String) url;
    @field(String) method;
    @field(String) requestPayload;
    @field(Number) responseCode;
    @field(Number) responseElapsedMs;
    @field(String) responsePayload;
    @field(Object) extra;
    @field(String) contextType;
    @field(String) contextUid;
    @field(String) contextMethod;

    getMerchantUser() {
        return MerchantUserStore.getFromObject(this);
    }

    getError() {
        try {
            const response = JSON.parse(this.requestPayload || "");
            if (response.error) {
                return response.error;
            }
        } catch (e) {
            if (this.responseCode !== 200) {
                return `Invalid response code ${this.responseCode}`;
            }
            return "Unparsable response";
        }
        return null;
    }
}

export const ExternalAppRequestInstanceStore = MakeStore("ExternalAppRequestInstance", ExternalAppRequestInstance);
