import {UI} from "../../stem-core/src/ui/UIBase.js";
import {EndpointPaginator} from "../../client/state/EndpointPaginator.js";
import {ExternalAppStore} from "../../client/state/ExternalAppConfigStore.js";
import {ExternalAppRequestInstanceStore} from "../../client/state/ExternalAppRequestInstanceStore.js";
import {DashboardTitle} from "../common/DashboardTitle.js";
import {SimpleTable} from "../ui/SimpleTable.jsx";
import {Button} from "../../stem-core/src/ui/button/Button.jsx";
import {DashboardModal} from "../common/modals/DashboardModal.jsx";
import {BlinkInputField} from "../common/Input.jsx";
import {Select} from "../../stem-core/src/ui/input/Input.jsx";
import {DashboardStaticText} from "../common/DashboardStaticText.jsx";
import {TextInput} from "../../stem-core/src/ui/input/Input.jsx";
import {MakeEmailEntry} from "../common/DashboardTablePage.jsx";


class OptionalBoolInput extends Select {
    static ANY = "Any";
    static TRUE = "Yes";
    static FALSE = "No";

    getDefaultOptions() {
        return {
            options: [
                this.constructor.ANY,
                this.constructor.TRUE,
                this.constructor.FALSE,
            ]
        }
    }

    getValue() {
        const value = super.getValue();
        if (value === this.constructor.TRUE) {
            return true;
        }
        if (value === this.constructor.FALSE) {
            return false;
        }
        return undefined;
    }
}


class ExternalAppRequestInstanceModal extends DashboardModal {
    render() {
        const {externalRequest} = this.options;
        const {requestPayload} = externalRequest;
        return [
            <div>
                Request id: {externalRequest.id}
            </div>,
            <div>
                URL: {externalRequest.url}
            </div>,
            <div>
                Duration: {externalRequest.responseElapsedMs}ms
            </div>,

            requestPayload ? [<h1>Request payload (masked):</h1>,
                <DashboardStaticText type="json" value={requestPayload}/>] : <h1>No request body</h1>,

            <h1>Response payload:</h1>,
            <DashboardStaticText type="json" value={externalRequest.responsePayload} />
        ]
    }
}

export class MerchantExternalAppRequestsPage extends UI.Element {
    paginator = new EndpointPaginator(ExternalAppRequestInstanceStore, "/external_providers/external_app_requests_list", {
        pageSize: 25,
        appId: ExternalAppStore.all()[0]?.id,
    });

    render() {
        const columns = [
            ["User", request => {
                const merchantUser = request.getMerchantUser();
                return merchantUser ? MakeEmailEntry(merchantUser) : "None";
            }],
            ["Created At", request => request.createdAt],
            ["App", request => request.app],
            ["Method", request => request.method],
            ["URL", request => request.url],
            ["Request Size", request => request.requestPayload?.length || "-"],
            ["Response Code", request => request.responseCode || "-"],
            ["Duration", request => request.responseElapsedMs ? (request.responseElapsedMs + "ms") : "-"],
            ["Response Size", request => request.responsePayload?.length || "-"],
            ["Success", request => !request.getError() ? "Yes" : "No"],
            ["", request => <Button onClick={() => ExternalAppRequestInstanceModal.show({externalRequest: request, fillScreen: true})}>See details</Button>]
        ];

        return [
            <DashboardTitle title={"External Requests"}/>,
            <div>
                <BlinkInputField label="Apps">
                    <Select options={ExternalAppStore.all()} onChange={(event, input) => this.paginator.updateFilter({appId: input.getValue().id})}/>
                </BlinkInputField>
                <BlinkInputField label="User (email/name)">
                    <TextInput
                        onChange={(event, input) => this.paginator.updateFilter({nameOrEmailSearch: input.getValue()})}
                    />
                </BlinkInputField>
                <BlinkInputField label="Method">
                    <Select
                        options={["Any", "GET", "POST", "PUT", "PATCH", "DELETE"]}
                        onChange={(event, input) => {
                            let method = input.getValue();
                            if (method == "Any") {
                                method = null;
                            }
                            this.paginator.updateFilter({method});
                        }}
                        />
                </BlinkInputField>
                <BlinkInputField label="Have exception">
                    <OptionalBoolInput
                        onChange={(event, input) => this.paginator.updateFilter({haveException: input.getValue()})}
                    />
                </BlinkInputField>
            </div>,
            <SimpleTable paginator={this.paginator} columns={columns}/>,
        ];
    }
}
