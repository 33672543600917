import {UI} from "../stem-core/src/ui/UIBase";
import {authService} from "../client/connection/services/AuthService.js";
import {Router} from "../stem-core/src/ui/Router.jsx";


// TODO @cleanup delete this
export class WebsiteIndex extends UI.Element {
    static redirect() {
        const redirectOptions = {replaceHistory: true};

        if (!authService.isAuthenticated()) {
            Router.changeURL("/login", redirectOptions);
            return;
        }

        Router.changeURL("/revenue", redirectOptions);
    }

    onMount() {
        this.addListener("urlEnter", () => this.constructor.redirect());
    }
}
