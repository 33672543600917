import {deepCopy} from "../../../../stem-core/src/base/Utils.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {apiEditMerchantUserJourney} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib.js";
import {SDKResourceEditorState} from "../components/BaseEditorState.js";

export class JourneyEditorState extends SDKResourceEditorState {
    constructor(journey) {
        super();
        this.journey = journey;
        this.advancedMode = true;
        this.load();
    }

    getInitialDashboardOptions(journey = this.journey) {
        return deepCopy({}, journey.dashboardOptions);
    }

    load(journey = this.journey) {
        this.journeyOptions = deepCopy({}, this.journey.options);
        this.dashboardOptions = this.getInitialDashboardOptions(journey);
    }

    isEdited() {
        return !isDeepEqual(this.dashboardOptions, this.getInitialDashboardOptions())
            || !isDeepEqual(this.journeyOptions, this.journey.options || {});
    }

    @wrapInSpinner
    async saveChanges() {
        this.journey = await apiEditMerchantUserJourney({
            sdkSettingsId: this.journey.settingsVersionId,
            userJourneyId: this.journey.id,
            options: this.journeyOptions,
            dashboardOptions: this.dashboardOptions
        });

        this.discardChanges();
    }
}
