import {JSONStateInput} from "../components/JSONStateInput.jsx";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";
import {
    MerchantUserJourneyStore
} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {MerchantSDKSettingsStore} from "../../../../client/state/merchant/MerchantSDKSettingsStore.js";
import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {SaveOrDiscardButtons} from "../../../common/input/SaveOrDiscardButtons.jsx";
import {JourneyEditorState} from "./JourneyEditorState.js";
import {SDKResourceLabelEditor} from "../../../../client/state/misc/SDKResourceLabelEditor.jsx";
import {ToggleInput} from "../../../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";

class JourneyEditorStyle extends StyleSheet {
    @styleRule
    editorArea = {
        display: "flex",
    };

    @styleRule
    optionsEditorArea = {
        flex: 1,
        padding: "16px 8px",
    };
}

@registerStyle(JourneyEditorStyle)
@autoredraw(MerchantUserJourneyStore, MerchantSDKSettingsStore)
export class JourneyEditor extends UI.Element {
    setOptions(options) {
        super.setOptions(options);
        this.state = JourneyEditorState.instantiate(this.options.userJourney, this);
    }

    render() {
        const {state, themeProps, styleSheet} = this;
        const {userJourney} = this.options;

        return [
            this.options.parent.renderTitle(userJourney.name),
            <div style={{marginBottom: 12, fontSize: 16}}>
                Journey alias: <span style={{fontFamily: themeProps.FONT_FAMILY_MONOSPACE}}>{userJourney.alias}</span>
            </div>,
            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{display: "flex", paddingRight: 6}}>
                    <ToggleInput
                        initialValue={state.advancedMode}
                        onChange={(active) => state.setAdvancedMode(active)}
                    />
                </span> Advanced
                <SaveOrDiscardButtons state={state}/>
            </div>,
            <SDKResourceLabelEditor state={state} merchant={state.journey.merchant}/>,
            <div className={styleSheet.editorArea}>
                <div className={styleSheet.optionsEditorArea}>
                    <JSONStateInput
                        state={state}
                        stateKey="journeyOptions"
                        codeEditorHeight={600}
                    />
                </div>
            </div>,
        ];
    }
}