import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {FileInput} from "../../../stem-core/src/ui/input/Input.jsx";
import {BlinkInputField} from "../Input.jsx";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {BaseInputElement} from "../../../stem-core/src/ui/input/BaseInputElement.js";
import {bytesToSize} from "../../../blinkpay/Utils.js";
import {SimpleTable} from "../../ui/SimpleTable.jsx";
import {CSVColumnMapper, CSVReader} from "../../../stem-core/src/base/CSV.js";
import {ArrayPaginator} from "../../../client/state/EndpointPaginator.js";
import {normalizeCDSAccountNumber} from "../../../client/state/SocialAccountStore.js";

const MAX_CSV_FILE_SIZE = 30 << 20;

@makeEnum
export class CSVFileFormat extends BaseEnum {
    static EMAIL_AUTODETECT; // Must have one of the following columns:
    static CDS_SUBSCRIBER; // Must have as the first column the account number
}

export class CSVFileInput extends BaseInputElement {
    error = null;

    setError(error) {
        this.error = error;
        this.redraw();
    }

    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            maxFileSize: MAX_CSV_FILE_SIZE,
            showPreviewTable: true,
            previewPageSize: 5,
            fileFormat: CSVFileFormat.EMAIL_AUTODETECT,
        }
    }

    loadCSVRows(csvRows) {
        const {fileFormat} = this.options;

        // TODO move this code out when adding a new format
        if (fileFormat === CSVFileFormat.EMAIL_AUTODETECT) {
            const [headerRow, ...entries] = csvRows;
            this.entries = entries;
            this.csvColumnMapper = new CSVColumnMapper(headerRow, [
                [["email", "email_address", "email address", "email:"], {required: true}],
                ["name"],
            ]);

        } else if (fileFormat === CSVFileFormat.CDS_SUBSCRIBER) {
            for (const row of csvRows) {
                for (let index = 0; index < row.length; index += 1) {
                    row[index] = row[index].trim();
                }
                row[0] = normalizeCDSAccountNumber(row[0]);
            }
            this.entries = csvRows;
            const headerRow = ["Account Number", "Name", "Address 1", "Address 2", "City", "Region", "Promo Code", "Email"]
            this.csvColumnMapper = new CSVColumnMapper(headerRow, [
                [headerRow[0], {key: "accountNumber"}],
                [headerRow[1], {key: "name"}],
                [headerRow.at(-1), {key: "email"}],
            ]);
        } else {
            throw "Unsupported file format";
        }

        if (this.entries.length === 0) {
            throw "No valid entries in file";
        }
    }

    async readFile() {
        const {maxFileSize} = this.options;

        this.error = null;
        this.paginator = null;
        this.csvColumnMapper = null;
        this.entries = null;

        const file = this.fileInput.getFile();
        if (!file) {
            this.setValue(null);
            return;
        }
        if (file.size > maxFileSize) {
            this.setError(`File size too large: ${bytesToSize(file.size)}. Max accepted is ${maxFileSize}.`);
            return;
        }

        try {
            const fileContent = await file.text();
            const csvReader = new CSVReader();
            const csvRows = csvReader.readInput(fileContent);
            this.loadCSVRows(csvRows);
        } catch (error) {
            this.setError(error);
            return;
        }

        this.paginator = new ArrayPaginator(this.entries, 5);
        this.setValue({entries: this.entries, columnMapper: this.csvColumnMapper});
    }

    getEntriesAsRows() {
        return this.getValue()?.entries || [];
    }

    getEntriesAsObjects() {
        const entries = this.getEntriesAsRows();
        const {columnMapper} = (this.getValue() || {});
        return entries.map(entry => columnMapper.toObject(entry));
    }

    renderPreviewTable() {
        if (this.error || !this.csvColumnMapper) {
            return null;
        }

        const columns = this.csvColumnMapper.columns.map(col => [
            col.originalName, entry => entry[col.index], {style: {width: 200, minWidth: 200}}
        ]);

        const numSkippedColumns = this.csvColumnMapper.numOriginalColumns() - this.csvColumnMapper.numColumns();

        // TODO have a checkbox that can toggle to show all the columns
        return [
            <div style={{width: "100%"}}>
                {(numSkippedColumns > 0) && <div style={{paddingTop: 12}}>Only showing relevant columns,
                    ignored {numSkippedColumns} column(s).</div>}
                <SimpleTable columns={columns} paginator={this.paginator}/>
            </div>
        ];
    }

    render() {
        return [
            <div>
                <BlinkInputField label="Open a csv or tsv file:">
                    <FileInput
                        ref={"fileInput"}
                        multipleFiles={false}
                        fileTypes=".csv,.tsv"
                        onChange={() => this.readFile()}
                    />
                </BlinkInputField>
            </div>,
            this.error && <div style={{maxWidth: 375, paddingTop: 12, color: "red"}}>
                Error: {this.error}
            </div>,
            this.renderPreviewTable(),
        ]
    }
}