import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../../connection/BlinkApiClient.js";

class MerchantCDSPromoKeys extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantCDSProduct") product;
    @field("SubscriptionOffer") subscriptionOffer;
    @field(Array) promoKeys;
    @field(Boolean) allowUnverified;
}

export const MerchantCDSPromoKeysStore = MakeStore("MerchantCDSPromoKeys", MerchantCDSPromoKeys);

export async function apiMerchantEditCDSPromoKeys(request) {
    return apiClient.post("/external_providers/edit_cds_promo_keys/", request);
}