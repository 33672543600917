import {UI} from "../../../stem-core/src/ui/UIBase";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {
    apiMerchantEditAudienceMembers,
    MerchantAudienceMemberStatus,
    MerchantAudienceMemberStore, ProperStatuses,
    ProperSubscribedStatuses,
    ProperUnsubscribedStatuses,
} from "../../../client/state/merchant/MerchantAudienceMemberStore";
import {DashboardTableColumnManager, DashboardTablePage, MakeEmailEntry} from "../../common/DashboardTablePage";
import {BlinkInputField} from "../../common/Input";
import {
    DateRangeInput,
    DefaultMerchantDateRangeIntervals,
    MakeMerchantAllTimeInterval
} from "../../ui/input/DateRangeInput";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {AggregateDataViewManager, Annotation, GroupByField} from "../../common/AggregateDataViewManager";
import {StemDate} from "../../../stem-core/src/time/Date";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {MultiselectInput} from "../../common/MultiselectInput";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";
import {AudienceMemberStatus} from "./MerchantAudienceInstancePage";
import {UserSearchInput} from "../../ui/input/UserSearchInput.jsx";
import {RadioButtonsInput} from "../../../core/ui/input/radio/RadioButtonsInput.jsx";
import {JourneysFilter} from "../../common/JourneysFilter.jsx";


@autoredraw(MerchantAudienceMemberStore)
export class MerchantNewsletterMembersPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            store: MerchantAudienceMemberStore,
            endpoint: "/merchant/get_audience_members",
            reportType: MerchantReportType.AUDIENCE_MEMBERS,
            paginatorOptions: {
                audienceIds: MerchantAudienceStore.all().map(audience => audience.id),
                fullAudience: true,
            },
            title: "Newsletter members",
            description: "See a list of all proper newsletter members. Proper members of a " +
                "newsletter are the ones that signed up themselves or were manually imported, but not " +
                "the ones added through membership filters.",
        }
    }

    getFilters() {
        const memberType = this.memberTypeFilter.getActive();
        let status = ProperStatuses;

        if (memberType === "Subscribed") {
            status = ProperSubscribedStatuses;
        }

        if (memberType === "Unsubscribed") {
            status = ProperUnsubscribedStatuses;
        }

        return {
            userFilter: this.userSearchInput.getValue(),
            audienceIds: this.audiencesFilter.getValue().map(audience => audience.id),
            status: status.map(enumEntry => enumEntry.value),
            metadata: this.columnManager.getMetadataFilters(),
            ...this.changedStatusAtInput.valueOf(),
            ...this.journeysFilter?.getValue(),
        };
    }

    getFilterSection() {
        const {merchant} = this.options;

        const {audiencesFilter} = this;
        let audiencesFilterTitle = "Filter by audience";
        if (audiencesFilter == null || audiencesFilter.allSelected()) {
            audiencesFilterTitle += " (All Selected)";
        } else {
            audiencesFilterTitle += " (Active filter)";
        }

        return [
            <UserSearchInput ref="userSearchInput" filterByAudience={false} onChange={() => this.applyFilters()} />,
            <BlinkInputField label="Member type">
                <RadioButtonsInput
                    initialValue="All"
                    values={["All", "Subscribed", "Unsubscribed"]}
                    onChange={() => this.applyFilters()}
                    ref="memberTypeFilter"
                />
            </BlinkInputField>,
            <BlinkInputField label="Changed status at">
                <DateRangeInput
                    ref="changedStatusAtInput"
                    style={{maxWidth: 350, display: "inline-block"}}
                    options={DefaultMerchantDateRangeIntervals(merchant)}
                    selectedInterval={MakeMerchantAllTimeInterval(merchant)}
                    onChange={() => this.applyFilters()} />
            </BlinkInputField>,
            <div>
                <CollapsiblePanel collapsed={true} title="Filter by active journeys">
                    <JourneysFilter ref="journeysFilter" merchant={merchant} onChange={() => this.applyFilters()} />
                </CollapsiblePanel>
            </div>,
            <div style={{marginTop: 16}}>
                <CollapsiblePanel collapsed={true} title={audiencesFilterTitle}>
                    <MultiselectInput ref="audiencesFilter"
                                      options={MerchantAudienceStore.all()}
                                      initialValue={MerchantAudienceStore.all()}
                                      onChange={() => this.applyFilters()}/>
                </CollapsiblePanel>
            </div>
        ];
    }

    async editAudienceMemberStatus(audienceMember, subscriptionStatus) {
        return apiMerchantEditAudienceMembers({
            audienceId: audienceMember.audienceId,
            status: subscriptionStatus,
            overwriteExistingMembers: true,
            members: [{email: MerchantUserStore.getFromObject(audienceMember).getEmail()}],
            includeState: true,
        });
    }

    async unsubscribeUser(audienceMember) {
        const confirm = await ConfirmationModal.prompt({
            title: "Unsubscribe user",
            description: "Mark this user to not receive any emails from this audience, even if they are included in the membership filters."
        });
        if (!confirm) {
            return;
        }
        await this.editAudienceMemberStatus(audienceMember, MerchantAudienceMemberStatus.MANUALLY_UNSUBSCRIBED);
    }

    columnManager = new DashboardTableColumnManager("newsletter-proper-members-table-columns", [
        ["Email", audienceMember => MakeEmailEntry(MerchantUserStore.getFromObject(audienceMember))],
        ["Audience", audienceMember => audienceMember.audience],
        ["Status", audienceMember => AudienceMemberStatus(audienceMember.status.value)],
        ["Subscribed at", audienceMember => audienceMember.subscribedAt],
        ["Unsubscribed at", audienceMember => audienceMember.unsubscribedAt],
        ["Actions", audienceMember => audienceMember.isSubscribed() && <Button onClick={() => this.unsubscribeUser(audienceMember)}>Unsubscribe</Button>],
    ], {tablePage: this});
    groupsManager = new AggregateDataViewManager([
        new Annotation("Last subscribe", "last_subscribe", "max", "subscribed_at", value => new StemDate(value)),
        new Annotation("Last unsubscribe", "last_unsubscribe", "max", "unsubscribed_at", value => new StemDate(value)),
    ], [
        new GroupByField("Audience", "audience_id", audienceId => MerchantAudienceStore.get(audienceId)),
        new GroupByField("Status", "status", value => AudienceMemberStatus(value)),
    ]);
}
