import {Dispatcher} from "../../../stem-core/src/base/Dispatcher.js";
import {merchantService} from "../../../dashboard/misc/MerchantService.js";
import {toArray} from "../../../stem-core/src/base/Utils";
import {MerchantPanelStore} from "../merchant/MerchantPanelStore.js";
import {MerchantUserJourneyStore} from "../merchant/MerchantUserJourneyStore.js";

export class LabelManager extends Dispatcher {
    constructor(merchant) {
        super();
        this.merchant = merchant;
    }

    getLabelsForSDKResource(sdkResource) {
        return toArray(sdkResource.dashboardOptions.labels).filter(x => x);
    }

    // Return an array of Strings
    getLabels() {
        const labelFrequency = new Map();

        const resources = [
            ...MerchantPanelStore.filterBy({merchantId: this.merchant.id}),
            ...MerchantUserJourneyStore.filterBy({merchantId: this.merchant.id}),
        ]
        // TODO - make case insensitive the label name
        for (const obj of resources) {
            const objLabels = this.getLabelsForSDKResource(obj);
            for (const label of objLabels) {
                const currentCount = labelFrequency.get(label) || 0;
                labelFrequency.set(label, currentCount + 1);
            }
        }
        const result = Array.from(labelFrequency.keys());
        // Sort by frequency
        result.sort((l1, l2) => labelFrequency.get(l1) - labelFrequency.get(l2));
        return result;
    }
}

export function GetLabelManager(merchant) {
    if (!merchant.labelManager) {
        merchant.labelManager = new LabelManager(merchant);
    }
    return merchant.labelManager
}

window.getLabelManager = () => GetLabelManager(merchantService.getMerchant());
