import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {DateCalendarInputInterface} from "../base/DateCalendarInputInterface";
import {DateCalendarMonthInput} from "./DateCalendarMonthInput";


export class DateCalendarInput extends DateCalendarInputInterface {
    setValue(value) {
        super.setValue(value);
        this.referenceTime = value;
        this.swipe(this.referenceTime);
    }

    renderCalendarMonthComponent(monthDate) {
        const {minDate, maxDate} = this.options;

        return <DateCalendarMonthInput initialValue={this.value} className={this.styleSheet.month}
                                       onChange={value => this.setAndDispatchValue(value)}
                                       {...{minDate, maxDate, monthDate}}/>;
    }
}
