import {UI} from "../../../stem-core/src/ui/UIBase";

import {InputContainer} from "./Base";
import {TimeInput} from "../../../stem-core/src/ui/input/Input";
import {StemDate} from "../../../stem-core/src/time/Date";
import {PopupInput} from "./PopupInput";
import {DateCalendarInput} from "./date-input/date-picker/DateCalendarInput";
import {BaseInputElement} from "../../../stem-core/src/ui/input/BaseInputElement.js";


// TODO - cleanup this implementation
export class DateInput extends BaseInputElement {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            dateFormatter: date => date.format("MMM DD, YYYY"), // TODO: Use something global
        }
    }

    formatDate() {
        const date = this.getValue();
        return this.options.dateFormatter(date);
    }

    async openDatePicker() {
        const value = await PopupInput.prompt(DateCalendarInput, {
            initialValue: this.getValue(),
        }, {anchor: this});

        if (value) {
            this.setValue(value);
        }
    }

    render() {
        return <InputContainer style={{fontWeight: 700, cursor: "pointer"}} onClick={() => this.openDatePicker()}>
            {this.formatDate()}
        </InputContainer>
    }
}

export class DateTimeInput extends UI.Element {
    extraNodeAttributes(attr) {
        attr.setStyle({
            display: "inline-flex",
        });
    }

    render() {
        const {initialValue} = this.options;
        return [
            <DateInput initialValue={initialValue} ref="dateInput" />,
            <TimeInput value={initialValue} ref="timeInput" />,
        ]
    }

    getValue() {
        let date = this.dateInput.getValue();
        date = this.timeInput.getValue(date);
        return date;
    }

    setValue(date) {
        date = new StemDate(date);
        this.dateInput.setValue(date);
        this.timeInput.setValue(date);
    }
}
