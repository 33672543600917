import {UI} from "../../stem-core/src/ui/UIBase";
import {
    apiMerchantCreateStaffMember,
    apiMerchantDeleteStaffMember,
    apiMerchantEditStaffMember,
    MerchantStaffStore
} from "../../client/state/merchant/MerchantStaffStore";
import {Button} from "../../stem-core/src/ui/button/Button";
import {SimpleTable} from "../ui/SimpleTable";
import {DashboardDescription, DashboardTitle} from "../common/DashboardTitle";
import {TopLevelTabArea} from "../common/theme/TabAreaStyle";
import {apiMerchantEditSettings} from "../../client/state/MerchantStore";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal";
import {Size} from "../../stem-core/src/ui/Constants";
import {EditableObjectStatus} from "../../client/state/misc/GenericEnums";
import {UserProfileStore} from "../../client/state/UserProfileStore";
import {CheckboxInput} from "../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {BlinkInputField} from "../common/Input";
import {EmailInput} from "../../stem-core/src/ui/input/Input";
import {MakeInfoElement} from "../common/InfoTooltip.jsx";
import {Toast} from "../../blinkpay/ui/Toast.jsx";

function GetOwnStaffMember(merchant) {
    const user = UserProfileStore.getUserProfile();
    return MerchantStaffStore.findBy({merchantId: merchant.id, userId: user.id});
}

function CanEditStaffRoles(merchant) {
    const staffRole = GetOwnStaffMember(merchant);
    return !staffRole || staffRole.isOwner;
}

class MerchantStaffEditModal extends ConfirmationModal {
    getDefaultOptions(options) {
        const {staffMember} = this.options;
        return {
            ...super.getDefaultOptions(),
            title: "Edit staff member",
            description: `Change the permissions for ${staffMember.getEmail()}`,
            confirmLabel: "Save",
            confirmAction: () => {
                const {staffMember} = this.options;
                this.resolve({
                    merchantId: staffMember.merchantId,
                    staffId: staffMember.id,
                    hasEditPermissions: this.hasEditPermissionsInput.getValue(),
                });
            },
        };
    }

    render() {
        const {staffMember} = this.options;

        return <div>
            <CheckboxInput ref="hasEditPermissionsInput"
                             label={"Has edit permissions"}
                             initialValue={staffMember.hasEditPermissions}/>
        </div>
    }
}


class MerchantStaffCreateModal extends ConfirmationModal {
    getDefaultOptions(options) {
        const {merchant} = this.options;
        return {
            ...super.getDefaultOptions(),
            title: "Add staff member",
            description: "Grant permission to a new staff member to access the dashboard and use the Blink API.",
            confirmLabel: "Add staff",
            confirmAction: () => this.resolve({
                merchantId: merchant.id,
                emailAddress: this.emailInput.getValue(),
                createIfRequired: true,
                hasEditPermissions: this.hasEditPermissionsInput.getValue(),
            }),
        };
    }

    render() {
        const {staffMember} = this.options;

        // TODO @cleanup replace the email input with a user select, that can also add a new user.

        return <div>
            <div>
                <BlinkInputField label="Email">
                    <EmailInput ref="emailInput" />
                </BlinkInputField>
            </div>
            <CheckboxInput ref="hasEditPermissionsInput"
                             label={"Has edit permissions"}
                             initialValue={true}/>
        </div>
    }
}

@autoredraw(MerchantStaffStore)
class MerchantAdminUsersControl extends UI.Element {
    async editStaff(staffMember) {
        const request = await MerchantStaffEditModal.prompt({staffMember});
        if (!request) {
            return;
        }
        await apiMerchantEditStaffMember(request);
    }

    async deleteStaff(staffMember) {
        const confirm = await ConfirmationModal.prompt({
            title: "Remove staff member?",
            description: `${staffMember.getMerchantUser().email} will no longer have access to the dashboard. Are you sure you want to remove their access?`,
            confirmLabel: "Remove access"
        });

        if (confirm) {
            await apiMerchantDeleteStaffMember({
                staffId: staffMember.id,
            })
        }
    }

    async addStaffMember() {
        const {merchant} = this.options;
        const request = await MerchantStaffCreateModal.prompt({merchant});
        if (!request) {
            return;
        }
        await apiMerchantCreateStaffMember(request);
    }

    render() {
        const {merchant} = this.options;
        const users = MerchantStaffStore.filter(u => u.merchantId === merchant.id);

        users.sort((a, b) => {
            if (a.isOwner) {
                return -1;
            }
            if (b.isOwner) {
                return 1;
            }
            if (a.hasEditPermissions) {
                return -1
            }
            if (b.hasEditPermissions) {
                return 1;
            }
            return (+a.createdAt) - (+b.createdAt);
        });

        const columns = [
            ["User", user => user.getMerchantUser()?.email || user.userId],
            ["Permissions", user => {
                if (user.isOwner) {
                    return "Owner";
                }
                return user.hasEditPermissions ? "View & Edit": "View";
            }],
            ["Added at", user => user.createdAt],
            CanEditStaffRoles(merchant) && ["", user => (!user.isOwner && <div>
                <Button onClick={() => this.editStaff(user)}>Edit Role</Button>
                <Button onClick={() => this.deleteStaff(user)}>Remove</Button>
            </div>)]
        ].filter(x => x);

        return [
            <p>
                All admin users that have access to this dashboard:
            </p>,
            CanEditStaffRoles(merchant) && <div>
                <Button onClick={() => this.addStaffMember()}>Add admin user</Button>
            </div>,
            <SimpleTable columns={columns} entries={users} />,
        ]
    }
}

class MerchantDeveloperSettings extends UI.Element {
    render() {
        return [
            <div>
                Control OAuth settings and API integrations from this section.
                Your account does not have live-API interaction permissions, please contact your Blink account owner.
            </div>
        ]
    }
}

@autoredraw
class MerchantMasterSwitch extends UI.Element {
    async setSDKStatus(sdkStatus) {
        await apiMerchantEditSettings({sdkStatus});
    }

    async disableSDK() {
        const confirmation = await ConfirmationModal.prompt({
            title: "Disable Blink?",
            description: "Do you want to disable all Blink SDK functionality? All settings can still be updated in the meantime."
        });
        if (confirmation) {
            await this.setSDKStatus(EditableObjectStatus.INACTIVE);
        }
    }

    async enableSDK() {
        const confirmation = await ConfirmationModal.prompt({
            title: "Re-Enable Blink?",
            description: "This will turn back on all the regular Blink functionality."
        });
        if (confirmation) {
            await this.setSDKStatus(EditableObjectStatus.ACTIVE);
        }
    }

    renderSDKEnabled() {
        return [
            <DashboardDescription>
                The Blink SDK is currently enabled. If you want to disable all functionality (user journeys, paywalls, pop-ups, etc.),
                you can turn it off temporarily, while still being able to edit all other settings.
            </DashboardDescription>,
            <div style={{margin: "auto"}}>
                <Button size={Size.LARGE} onClick={() => this.disableSDK()}>Disable Blink web functionality</Button>
            </div>
        ];
    }

    renderSDKDisabled() {
        return [
            <DashboardDescription>
                The Blink SDK (which controls all user journeys, paywalls, pop-ups, etc.) is currently disabled!
            </DashboardDescription>,
            <div style={{margin: "auto"}}>
                <Button size={Size.LARGE} onClick={() => this.enableSDK()}>Enable Blink</Button>
            </div>
        ]
    }

    render() {
        const {merchant} = this.options;
        if (merchant.sdkStatus == EditableObjectStatus.ACTIVE) {
            return this.renderSDKEnabled();
        }
        return this.renderSDKDisabled();
    }
}


@autoredraw
class MerchantSettingsPanel extends UI.Element {
    async saveSettings() {
        await apiMerchantEditSettings({
            allowUnverifiedEmailUsers: this.allowUnverifiedEmailUsers.getValue(),
        });
        Toast.showSuccess("Changes successfully saved");
    }

    getUnverifiedEmailsHelp() {
        return [
            <p>
                Control enforcing email verification on new users. By default, all users need to verify their email (either by entering a verification code or via clicking an email link).
                Conversion can be increased by not requiring emails to be validated for new users, but some of the emails used might be fake.
            </p>,
            <p>
                Subsequent authentication with the same credentials will trigger a validation.
            </p>,
            <p>When allowing unverified users, this settings can still be overridden in individual journeys/panels.</p>,
            <p>After enabling should this settings be disabled later, all previously unverified users will be logged out.</p>
        ]
    }

    render() {
        const {merchant} = this.options;
        return [
            <div>
                <Button onClick={() => this.saveSettings()}>Save changes</Button>
            </div>,
            <div style={{margin: 12, display: "inline-flex"}}>
                <CheckboxInput
                    style={{paddingRight: 8}}
                    ref="allowUnverifiedEmailUsers"
                    initialValue={merchant.allowUnverifiedEmailUsers}
                    label="Allow users with unverified emails"/>
                {MakeInfoElement(() => this.getUnverifiedEmailsHelp())}
            </div>,
            <MerchantMasterSwitch merchant={merchant} title={"Master switch"} />
        ]
    }
}


export class MerchantAdministrationPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        return [
            <DashboardTitle
                title="Administration"
            />,
            <TopLevelTabArea>
                <MerchantAdminUsersControl merchant={merchant} title="Admin users" />
                <MerchantSettingsPanel merchant={merchant} title="Settings"/>
                {/*<MerchantDeveloperSettings title="Developer settings" />*/}
                {/*<MerchantPaymentGateways title="Payment Gateways" />*/}
            </TopLevelTabArea>
        ]
    }
}
