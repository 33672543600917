import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {BlinkSDKInputField} from "../../components/Primitives.jsx";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {HTMLEditor} from "../../../../common/HTMLEditor.jsx";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {Button} from "../../../../../stem-core/src/ui/button/Button.jsx";
import {MakeInfoElement} from "../../../../common/InfoTooltip.jsx";


@registerStyle(PanelEditorTabStyle)
export class PanelContentEditor extends UI.Element {
    resetContent(doFork) {
        const {state} = this.options;

        delete state.panelOptions.content;
        if (doFork) {
            const inheritedOptions = state.composePanelOptions();
            state.panelOptions.content = inheritedOptions.content;
        }
        state.dispatchChange();
        this.redraw();
    }

    render() {
        const {state} = this.options;
        const parentPanel = state.getParentPanel();

        if (parentPanel && state.panelOptions.content == null) {
            return [
                <h3>Using the inherited content layout.</h3>,
                <Button onClick={() => this.resetContent(true)}>Fork content</Button>
            ]
        }

        return [
            parentPanel && <div>
                <Button onClick={() => this.resetContent(false)}>Reset content to parent</Button>
                {MakeInfoElement(`Clear the custom content, and use the content of the parent panel (${parentPanel.name || parentPanel.alias})`)}
            </div>,
            <BlinkSDKInputField
                label="HTML Content"
                stateKey={["panelOptions", "content"]}
                state={state}
                InputClass={HTMLEditor}
                style={{width: "100%", maxWidth: "100%"}}
                inputOptions={{
                    className: this.styleSheet.textAreaInput + this.styleSheet.codeInput,
                    aceMode: "html",
                    resize: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    style: {maxHeight: 600, minHeight: 600, maxWidth: "100%"},
                }}/>
        ];
    }
}
