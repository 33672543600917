import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {apiMerchantUpdateEmailSender} from "../../../client/state/merchant/MerchantEmailSender";
import {BlinkInput} from "../../../blinkpay/ui/Input";

export class MerchantEditEmailSenderModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Edit email sender",
            confirmLabel: "Confirm",
            emailSender: null,
            confirmAction: () => this.editEmailSender(),
        };
    }

    @wrapInSpinner
    async editEmailSender() {
        const request = {
            emailSenderId: this.options.emailSender.id,
            name: this.nameInput.getValue().trim(),
        }
        await apiMerchantUpdateEmailSender(request);
        this.hide();
    }

    render() {
        const {emailSender} = this.options;

        return <div>
            <BlinkInput initialValue={emailSender.emailAddress}
                        label={"Email"}
                        inputAttributes={{placeholder: "Email"}}
                        validators={[{condition: () => !this.inputRefs.emailInput.isEmpty()}]}
            />
            <BlinkInput ref={"nameInput"}
                        initialValue={emailSender.name}
                        label={"Name (Optional)"}
                        inputAttributes={{placeholder: "Name"}}
            />
        </div>
    }
}
