import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {Level} from "../../../stem-core/src/ui/Constants";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {
    apiMerchantArchiveSubscriptionDiscount,
    apiMerchantEditSubscriptionDiscount, SubscriptionDiscountScope
} from "../../../client/state/SubscriptionDiscountStore";
import {DashboardLabel} from "../../common/DashboardLabel";
import {BlinkInputField} from "../../common/Input";
import {Select, TextInput} from "../../../stem-core/src/ui/input/Input";
import {SubscriptionCoverageStore} from "../../../client/state/SubscriptionCoverageStore";
import {SubscriptionOfferStore} from "../../../client/state/SubscriptionOfferStore";
import {SubscriptionCoverageMultiselectInput, SubscriptionOffersMultiselectInput} from "../../common/input/SubscriptionCoverageMultiselectInput";
import {Switcher} from "../../../stem-core/src/ui/Switcher";

export class SubscriptionDiscountScopeInput extends UI.Element {
    getValue() {
        let value = {
            scope: this.scopeInput.getValue(),
        };
        if (value.scope === SubscriptionDiscountScope.TARGETED_COVERAGES) {
            value.targetCoverageIds = this.subscriptionCoveragesInput.getValue().map(coverage => coverage.id);
        }
        if (value.scope === SubscriptionDiscountScope.TARGETED_OFFERS) {
            value.targetOfferIds = this.subscriptionOffersInput.getValue().map(offer => offer.id);
        }
        return value;
    }

    setValue(value) {
        const {scope} = value;
        this.scopeInput.setValue(scope);
        this.refreshVisibility(); // Make sure the appropriate option is initialized
        if (scope === SubscriptionDiscountScope.TARGETED_COVERAGES) {
            const coverages = value.targetCoverageIds.map(id => SubscriptionCoverageStore.get(id));
            this.subscriptionCoveragesInput.setValue(coverages);
        }
        if (scope === SubscriptionDiscountScope.TARGETED_OFFERS) {
            const offers = value.targetOfferIds.map(id => SubscriptionOfferStore.get(id));
            this.subscriptionOffersInput.setValue(offers);
        }
    }

    getTypeSpecificInput() {
        if (!this.coverageOptions) {
            // Initialize all inputs
            const {merchant} = this.options;
            const inputStyle = {margin: 6};
            this.coverageOptions = <SubscriptionCoverageMultiselectInput merchant={merchant} style={inputStyle} ref="subscriptionCoveragesInput" />
            this.planOptions = <SubscriptionOffersMultiselectInput merchant={merchant} style={inputStyle} ref="subscriptionOffersInput" />
        }

        const scope = this.scopeInput?.getValue() || SubscriptionDiscountScope.ALL_OFFERS;
        if (scope === SubscriptionDiscountScope.TARGETED_COVERAGES) {
            return this.coverageOptions;
        }
        if (scope === SubscriptionDiscountScope.TARGETED_OFFERS) {
            return this.planOptions;
        }
        return null;
    }

    refreshVisibility() {
        this.inputSwitcher.setActive(this.getTypeSpecificInput());
    }

    render() {
        return [
            <div>
                <Select
                    ref="scopeInput"
                    onChange={() => this.refreshVisibility()}
                    options={SubscriptionDiscountScope.all()}
                />
            </div>,
            <Switcher ref="inputSwitcher">
                {this.getTypeSpecificInput()}
            </Switcher>
        ]
    }

    onMount() {
        const {initialValue} = this.options;
        if (initialValue) {
            this.setValue(initialValue);
        }
    }
}


function MakeSubscriptionDiscountStatusLabel(subscriptionDiscount) {
    if (subscriptionDiscount.isActive()) {
        return <DashboardLabel>Active</DashboardLabel>
    }
    return <DashboardLabel level={Level.WARNING}>Archived</DashboardLabel>
}


@autoredraw
export class MerchantSubscriptionDiscountInstancePage extends UI.Element {
    async archiveDiscount() {
        const {subscriptionDiscount} = this.options;
        const confirm = await ConfirmationModal.prompt({
            title: "Archive discount?",
            description: "Archiving a discount will allow existing users to benefit from their discount, but no new ones can use it.",
            confirmationLabel: "Archive",
        })
        if (!confirm) {
            return;
        }

        await apiMerchantArchiveSubscriptionDiscount({
            merchantId: subscriptionDiscount.merchantId,
            discountId: subscriptionDiscount.id,
        });
    }

    async saveChanges() {
        const {subscriptionDiscount} = this.options;
        const request = {
            subscriptionDiscountId: subscriptionDiscount.id,
            name: this.nameInput.getValue(),
            code: this.codeInput.getValue(),
            ...this.scopeInput.getValue(),
        }

        await apiMerchantEditSubscriptionDiscount(request);
    }

    render() {
        const {subscriptionDiscount} = this.options;
        return [
            <div style={{display: "flex", alignItems: "flex-end"}}>
                <div>Current status: {MakeSubscriptionDiscountStatusLabel(subscriptionDiscount)}</div>
                <div style={{flex: 1}}/>
                <div>
                    {subscriptionDiscount.isActive() && <Button onClick={() => this.saveChanges()}>Save Changes</Button>}
                    {subscriptionDiscount.isActive() && <Button onClick={() => this.archiveDiscount()}>Archive</Button>}
                </div>
            </div>,
            <div style={{paddingTop: 12}}>
                <BlinkInputField label="Name">
                    <TextInput initialValue={subscriptionDiscount.name} ref="nameInput" />
                </BlinkInputField>
                <BlinkInputField label="Promo code">
                    <TextInput initialValue={subscriptionDiscount.code} ref="codeInput" />
                </BlinkInputField>
            </div>,
            <div>
                Discount value: <strong>{subscriptionDiscount.getDiscountValue()}</strong>
            </div>,
            <p>
                This discount applies to {subscriptionDiscount.numCyclesDiscounted > 0 ? `the first ${subscriptionDiscount.numCyclesDiscounted} billing cycled.` : "all billing cycles."}
            </p>,
            <div>
                <BlinkInputField label="Scope">
                    <SubscriptionDiscountScopeInput
                        merchant={subscriptionDiscount.merchant}
                        initialValue={subscriptionDiscount}
                        ref="scopeInput" />
                </BlinkInputField>
            </div>,
            // TODO expiration
        ];
    }
}
