import {UI} from "../../../stem-core/src/ui/UIBase";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {
    apiMerchantCreateEmailCampaignSegment,
    apiMerchantEditEmailCampaignSegment, apiMerchantStopEmailCampaignSegment,
    MerchantEmailCampaignSegmentStore
} from "../../../client/state/merchant/MerchantEmailCampaignSegmentStore";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {InfoBlockGroup} from "../../common/InfoBlock";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {BlinkInputField} from "../../common/Input";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {EmailTemplateStore} from "../../../client/state/EmailTemplateStore";
import {SimpleTable} from "../../ui/SimpleTable";
import {DateTimeInput} from "../../ui/input/DateInput";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {DashboardTitle} from "../../common/DashboardTitle";
import {StemDate} from "../../../stem-core/src/time/Date";
import {NumberInput} from "../../../stem-core/src/ui/input/Input";
import {MerchantEmailCampaignStatus} from "../../../client/state/merchant/MerchantEmailCampaignStore";
import {TimeUnit} from "../../../stem-core/src/time/Duration";
import {Toast} from "../../../blinkpay/ui/Toast";
import {formatDateWithHour} from "../../common/Utils";
import {DashboardTimeDelta} from "../../common/Base";
import {MerchantAudienceSize} from "./MerchantAudienceInstancePage";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";


function formatCampaignSegmentStatus(campaignSegment) {
    if (campaignSegment.status === MerchantEmailCampaignStatus.VALIDATION_FAILED) {
        return "Validation error, check your template.";
    }
    if (campaignSegment.status === MerchantEmailCampaignStatus.SCHEDULED) {
        return ["Scheduled at ", formatDateWithHour(campaignSegment.scheduledSendDate), " (",
            new DashboardTimeDelta(campaignSegment.scheduledSendDate), ")"];
    }
    if (campaignSegment.status === MerchantEmailCampaignStatus.STARTED) {
        return "Sending...";
    }
    return campaignSegment.status.toString();
}


export class CreateEmailCampaignSegmentDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        const {baseSegment} = options;
        return {
            ...super.getDefaultOptions(),
            title: "New email campaign segment",
            description: baseSegment ? `The new template will be a copy of ${baseSegment.name}` : "Create a new segment, that by default will cover the entire audience",
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve(this.getValue()),
        };
    }

    getValue() {
        const {emailCampaign, emailTemplates} = this.options;
        const selectedEmailTemplate = this.emailTemplateInput?.getValue() || emailTemplates[0];

        return {
            name: this.nameInput.getValue().trim(),
            templateId: selectedEmailTemplate.id,
            campaignId: emailCampaign.id,
            merchantId: emailCampaign.merchantId,
            scheduledSendDate: emailCampaign.scheduledSendDate,
        };
    }

    render() {
        const {emailTemplates, baseSegment} = this.options;
        const defaultEmailTemplate = baseSegment?.template || emailTemplates[0];

        return [
            <div>
                <BlinkInputField label="Name (used only to identify the segment)">
                    <TextInput style={{minWidth: 360}} ref="nameInput"/>
                </BlinkInputField>
            </div>,
            (emailTemplates.length > 1) && <div>
                <BlinkInputField label="Email template">
                    <Select options={emailTemplates} value={defaultEmailTemplate} ref="emailTemplateInput" />
                </BlinkInputField>
            </div>
        ];
    }
}

export class EmailSendingObjectStatistics extends UI.Element {
    render() {
        const {emailSendingObject, audience} = this.options; // Is an EmailSendingStoreObject
        const {numSentEmails, numReadEmails, numBouncedEmails, numFailedEmails, totalNumReads} = emailSendingObject;

        return [
            <InfoBlockGroup label="Overview" entries={[
                audience && ["audience size", <MerchantAudienceSize audience={audience}/>],
                ["total emails sent", numSentEmails],
                ["sent successfully", emailSendingObject.numSentEmails],
                ["failed delivery", numFailedEmails],
            ]}/>,

            <InfoBlockGroup label="Open statistics" entries={[
                ["opened", emailSendingObject.formatOpenPercent()], // TODO: asterisk - estimate percent, out of delivered
                ["average number of reads", numReadEmails > 0 ? (totalNumReads / numReadEmails).toFixed(2) : 0],
            ]}/>,
        ];
    }
}

class SendEmailCampaignSegmentDialog extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Send campaign emails",
            confirmAction: () => this.resolve(this.getValue()),
        }
    }

    getConfirmLabel() {
        if (this.scheduleSendingInput?.getValue()) {
            return "Schedule sending";
        }

        return "Send now";
    }

    getValue() {
        let request = {
            isScheduled: true,
            scheduledSendDate: this.scheduleSendingInput.getValue() ? this.scheduledSendDateInput.getValue() : "now",
        };
        if (this.limitSendingInput.getValue()) {
            request.maxEmailsToSend = parseInt(this.maxEmailToSendInput.getValue());
            // TODO support also percentage at some point
        }

        return request;
    }

    render() {
        return [
            <div>
                <CheckboxInput ref="scheduleSendingInput" onChange={() => this.redraw()} label={"Schedule sending"}/>
            </div>,
            // TODO @email use the default time from the campaign
            this.scheduleSendingInput?.getValue() && <div>
                <DateTimeInput initialValue={StemDate.now().addUnit(TimeUnit.DAY).round(TimeUnit.HOUR)} ref="scheduledSendDateInput"/>
            </div>,

            <div>
                <CheckboxInput ref="limitSendingInput" onChange={() => this.redraw()} label={"Limit sending"}/>
            </div>,
            this.limitSendingInput?.getValue() && <div>
                <BlinkInputField label="Maximum number of emails to send in this batch">
                    <NumberInput value={10} ref="maxEmailToSendInput" />
                </BlinkInputField>
            </div>,
        ]
    }
}


@autoredraw
class CampaignSegmentInstanceElement extends UI.Element {
    getSegmentRequest() {
        const {campaignSegment, emailCampaign} = this.options;
        const allEmailTemplates = emailCampaign.getAllEmailTemplates();
        const selectedTemplate = this.emailTemplateInput?.getValue() || allEmailTemplates[0];

        let request = {
            merchantId: emailCampaign.merchantId,
            campaignId: emailCampaign.id,
            templateId: selectedTemplate.id,
            name: this.nameInput.getValue(),
            ...this.getExtraFiltersValue(),
        }

        if (campaignSegment) {
            Object.assign(request, {
                emailCampaignSegmentId: campaignSegment.id,
                isScheduled: this.isScheduledInput.getValue(),
                scheduledSendDate: this.scheduledSendDateInput.getValue(),
                maxEmailsToSend: this.enableMaxEmailsToSendInput.getValue() ? this.maxEmailsToSendInput.getValue() : null,
            });
        }
        return request;
    }

    getExtraFiltersValue() {
        // TODO @email add extraFilters here
        return null;
    }

    async saveChanges() {
        const request = this.getSegmentRequest();
        await apiMerchantEditEmailCampaignSegment(request);
    }

    async sendNow() {
        const sendRequest = await ConfirmationModal.prompt({
            title: "Send email batch",
            description: "Are you sure you want to send the emails now?",
            confirmLabel: "Send",
        });
        if (!sendRequest) {
            return;
        }

        const request = {
            ...this.getSegmentRequest(),
            isScheduled: true,
            scheduledSendDate: "now",
        };
        await apiMerchantEditEmailCampaignSegment(request);
    }

    async sendOrSchedule() {
        const sendRequest = await SendEmailCampaignSegmentDialog.prompt();
        if (!sendRequest) {
            return;
        }
        const request = {
            ...sendRequest,
            ...this.getSegmentRequest(),
        };
        try {
            await apiMerchantCreateEmailCampaignSegment(request);
        } catch (error) {
            // TODO @cleanup don't do this manually, by default handle errors
            Toast.showError(error);
        }
    }

    async cancelSending() {
        const confirm = await ConfirmationModal.prompt({
            title: "Cancel sending emails?",
            description: "This will cancel sending emails for this segment. Other segments are not canceled.",
            confirmLabel: "Cancel sending",
        });

        if (!confirm) {
            return;
        }

        const {campaignSegment} = this.options;
        const response = apiMerchantEditEmailCampaignSegment({
            merchantId: campaignSegment.merchantId,
            emailCampaignSegmentId: campaignSegment.id,
            isScheduled: false,
        });
    }

    async stopSending() {
        const {campaignSegment} = this.options;
        const request = {
            merchantId: campaignSegment.merchantId,
            emailCampaignSegmentId: campaignSegment.id,
        };

        const response = await apiMerchantStopEmailCampaignSegment(request);
    }

    getCampaignSegmentStatus() {
        const {campaignSegment} = this.options;
        if (!campaignSegment) {
            return;
        }
        let buttons = [];

        if (campaignSegment.canBeEdited()) {
            buttons.push(<Button onClick={() => this.saveChanges()}>Save Changes</Button>);
            buttons.push(<Button onClick={() => this.sendNow()}>Send now</Button>);
        }

        if (campaignSegment.status === MerchantEmailCampaignStatus.SCHEDULED) {
            buttons.push(<Button onClick={() => this.cancelSending()}>Cancel sending</Button>);
        }

        if (campaignSegment.status === MerchantEmailCampaignStatus.STARTED) {
            buttons.push(<Button onClick={() => this.stopSending()}>Stop sending</Button>);
        }

        return [
            <DashboardTitle title={"Editing segment " + campaignSegment.name}/>,
            <div>
                {["Email batch status: ", formatCampaignSegmentStatus(campaignSegment)]}
            </div>,
            <div>{buttons}</div>,
        ];
    }

    render() {
        const {campaignSegment, emailCampaign} = this.options;

        const availableEmailTemplates = emailCampaign.getAllEmailTemplates();
        const defaultEmailTemplate = campaignSegment?.template || availableEmailTemplates[0];

        const defaultExtraFiltersEnabled = campaignSegment?.extraFilters != null;
        const extraFiltersEnabled = this.enableExtraFiltersInput?.getValue() || defaultExtraFiltersEnabled;

        // TODO @email what should the default be?
        const defaultScheduledSendDate = campaignSegment?.scheduledSendDate || new StemDate().addUnit(TimeUnit.DAY).roundUp(TimeUnit.HOUR);

        return [
            // Either a segment specific header, or a button to send now
            campaignSegment ? this.getCampaignSegmentStatus() : <div style={{padding: 8}}>
                <Button onClick={() => this.sendOrSchedule()}>Send now / Schedule send</Button>
            </div>,

            // Generic options (name & email template)
            <div>
                <BlinkInputField label="Sending batch name (optional)">
                    <TextInput style={{minWidth: 360}} value={campaignSegment?.name || "Default"} ref="nameInput"/>
                </BlinkInputField>
                {availableEmailTemplates.length > 1 && <BlinkInputField label="Email template">
                    <Select options={availableEmailTemplates} initialValue={defaultEmailTemplate} ref="emailTemplateInput" />
                </BlinkInputField>}
            </div>,

            // The schedules and limit
            campaignSegment && [<div>
                <CheckboxInput initialValue={campaignSegment.isScheduled} ref="isScheduledInput" style={{display: "inline-flex"}}
                                 label={"Schedule to send at "}/>
                <DateTimeInput initialValue={defaultScheduledSendDate} ref="scheduledSendDateInput" />
            </div>,
                <div>
                    <CheckboxInput initialValue={campaignSegment.maxEmailsToSend != null} style={{display: "inline-flex"}}
                                     ref="enableMaxEmailsToSendInput" label={"Limit the number of email to "}/>
                    <NumberInput
                        value={campaignSegment.maxEmailsToSend || 0}
                        disabled={this.limitEmailsToSend?.getValue() ?? (campaignSegment.maxEmailsToSend != null)}
                        ref={"maxEmailsToSendInput"} />
                </div>
            ],

            // TODO implement this
            // <div>
            //     <div>
            //         <CheckboxInput value={defaultExtraFiltersEnabled} onChange={() => this.redraw()} ref="enableExtraFiltersInput" /> Extra audience filters
            //     </div>
            //     {extraFiltersEnabled && <div>
            //         Placeholder for extra audience filters.
            //     </div>}
            // </div>
        ];
    }
}


@autoredraw(MerchantEmailCampaignSegmentStore)
export class EmailCampaignSendingPanel extends DashboardSelectorPage {
    getAllCampaignSegments() {
        const {emailCampaign} = this.options;
        return MerchantEmailCampaignSegmentStore.filterBy({campaignId: emailCampaign.id});
    }

    async createCampaignSegment(baseSegment) {
        const {emailCampaign} = this.options;
        const emailTemplates = EmailTemplateStore.filterBy({campaignId: emailCampaign.id});
        const request = await CreateEmailCampaignSegmentDialog.prompt({emailCampaign, emailTemplates, baseSegment});
        if (!request) {
            return;
        }
        const newSegment = await apiMerchantCreateEmailCampaignSegment(request);
        if (newSegment) {
            this.goToEntry(newSegment);
        }
    }

    getCampaignSegmentTable() {
        const {emailCampaign} = this.options;
        const allEmailTemplates = emailCampaign.getAllEmailTemplates();
        const templateColumns = allEmailTemplates.length > 1 ? [
            ["Template", campaignSegment => campaignSegment.template],
        ] : [];

        const columns = [
            ["Name", campaignSegment => campaignSegment.name],
            ...templateColumns,
            ["Status", campaignSegment => formatCampaignSegmentStatus(campaignSegment)],
            ["Send cap", campaignSegment => campaignSegment.maxEmailsToSend || "-"],
            ["Sent", campaignSegment => campaignSegment.numSentEmails],
            ["Failed/Bounced", campaignSegments => campaignSegments.getAllFailed()],
            ["Open %", campaignSegment => campaignSegment.formatOpenPercent()],
        ];

        return this.makeTable(this.getAllCampaignSegments(), columns);
    }

    render() {
        const {emailCampaign} = this.options;
        const audience = emailCampaign.audience;
        const campaignSegments = this.getAllCampaignSegments();
        return [
            <CollapsiblePanel title="Campaign statistics" collapsed={false}>
                <EmailSendingObjectStatistics emailSendingObject={emailCampaign} audience={audience} />
            </CollapsiblePanel>,
            <div style={{padding: 8}}>
                <Button onClick={() => this.createCampaignSegment()}>{"New campaign segment"}</Button>
                {this.selectedEntry && <Button onClick={() => this.goToEntry(null)}>See all segment</Button>}
            </div>,
            (this.selectedEntry || campaignSegments.length === 0) ? <CampaignSegmentInstanceElement
                key={"segment" + this.selectedEntry?.id}
                emailCampaign={emailCampaign}
                campaignSegment={this.selectedEntry}
            /> : this.getCampaignSegmentTable(),
        ];
    }
}
