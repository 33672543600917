import {BaseDateCalendarDay} from "../base/BaseDateCalendarDay";


export class DateCalendarDay extends BaseDateCalendarDay {
    applyDateStyle(attr) {
        const {selectedDate, hoverDate} = this.options;
        const {styleSheet} = this;

        // The circle.
        if (hoverDate && this.isEqualTo(hoverDate)) {
            attr.addClass(styleSheet.hoverCircle);
        } else if (this.isEqualTo(selectedDate)) {
            attr.addClass(styleSheet.greenCircle);
            attr.addClass(styleSheet.lightFont);
        }
    }
}
