import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";

export class DashboardCardStyle extends StyleSheet {
    @styleRule
    card = {
        margin: "0 auto",
    };
}

@registerStyle(DashboardCardStyle)
export class DashboardCard extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.card);
    }
}
