import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style.js";
import {UserProfileStore} from "../../../client/state/UserProfileStore.js";
import {DashboardCard, DashboardCardStyle} from "../../ui/DashboardCard.jsx";
import {Messages} from "../../../blinkpay/Messages.js";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {authService} from "../../../client/connection/services/AuthService.js";
import {userService} from "../../../client/connection/services/UserService.js";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {MessageElement} from "../../../blinkpay/widget/ui/MessageElement.jsx";
import {DashboardTitle, DashboardDescription} from "../../common/DashboardTitle.js";

export class SwitchAccountCardStyle extends DashboardCardStyle {
    @styleRuleInherit
    card = {
        textAlign: "center",
    };

    @styleRuleInherit
    contentContainer = {
        padding: "60px 12px",
    };
}

@registerStyle(SwitchAccountCardStyle)
@autoredraw(UserProfileStore)
export class SwitchAccountCard extends DashboardCard {
    render() {
        return [
            <DashboardTitle >{Messages.unauthorizedUserAccountTitle}</DashboardTitle>,

            <DashboardDescription>
                <MessageElement
                    message={Messages.unauthorizedUserAccountDescriptionLine1(userService.getUserEmail())}/>
                <div>{Messages.unauthorizedUserAccountDescriptionLine2}</div>
            </DashboardDescription>,

            <Button
                label={Messages.switchAccount}
                onClick={() => this.handleSwitchAccountClick()}
            />
        ];
    }

    @wrapInSpinner
    async handleSwitchAccountClick() {
        await authService.logout();
        window.location.href = "/";
    }

    onMount() {
        super.onMount();
        userService.ensureUserDataRequested();
    }
}
