import {UI} from "../../../stem-core/src/ui/UIBase";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {DashboardBaseInput} from "../../../core/ui/input/BaseInput.js";
import {CloseIcon} from "../../../core/ui/SVGElements.jsx";
import {Level} from "../../../stem-core/src/ui/Constants";


class ArrayInputStyle extends StyleSheet {
    @styleRule
    elementInputContainer = {
        position: "relative",
        display: "flex",
        paddingRight: 16,
        marginBottom: 8,
    };

    @styleRule
    elementInputContainerBox = {
        background: this.themeProps.MERCHANT_CARD_BACKGROUND,
        padding: "18px 24px 18px 24px",
        border: "1px solid " + this.themeProps.MERCHANT_CARD_BORDER_COLOR,
        borderRadius: this.themeProps.MERCHANT_FRAME_BORDER_RADIUS,
    };

    @styleRule
    deleteButtonContainer = {
        width: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    @styleRule
    deleteButton = {
        cursor: "pointer",
    };

    @styleRule
    deleteButtonBox = {
        position: "absolute",
        top: 12,
        right: 12,
    };
}


@registerStyle(ArrayInputStyle)
export class ArrayInput extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            ElementInputClass: TextInput,
            elementInputOptions: {},
            box: true,
            enableDelete: true,
            enableAdd: true,
            addButtonLabel: "Add",
            initialValue: [],
        };
    }

    renderElementInput(index, elementValue) {
        const {styleSheet} = this;
        const {ElementInputClass, enableDelete, box} = this.options;
        const update = () => {
            this.value[index] = this[`elementInput-${index}`].getValue();
            this.setAndDispatchValue(this.getValue());
        }
        return <div className={styleSheet.elementInputContainer + (box ? styleSheet.elementInputContainerBox : "")}>
            <div style={{flex: 1}}>
                <ElementInputClass
                    ref={this.refLink(`elementInput-${index}`)}
                    initialValue={elementValue}
                    onChange={update}
                    {...this.options.elementInputOptions}
                />
            </div>
            {enableDelete && <div className={styleSheet.deleteButtonContainer}>
                <CloseIcon level={Level.SECONDARY}
                           className={styleSheet.deleteButton + (box ? styleSheet.deleteButtonBox : "")}
                           onClick={() => {
                                 this.value.splice(index, 1);
                                 this.setAndDispatchValue(this.value);
                             }}/>
            </div>}
        </div>;
    }

    addElement() {
        this.value.push(this.options.ElementInputClass.defaultValue);
        this.redraw();
        this.setAndDispatchValue(this.value);
    }

    renderAddButton() {
        return <Button onClick={() => this.addElement()} style={{marginLeft: 0}}>
            {this.options.addButtonLabel}
        </Button>;
    }

    render() {
        return [
            this.value.map((elementValue, index) => this.renderElementInput(index, elementValue)),
            this.options.enableAdd && this.renderAddButton(),
        ];
    }
}
