import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {GetLabelManager} from "./LabelManager.js";
import {PopupInput} from "../../../dashboard/ui/input/PopupInput.jsx";
import {LabelSetInput} from "../../../core/ui/input/label/LabelSetInput.jsx";
import {BlinkInputField} from "../../../dashboard/common/Input.jsx";
import {InputContainer} from "../../../dashboard/ui/input/Base.jsx";
import {EditIcon} from "../../../core/ui/SVGElements.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {DashboardLabel} from "../../../dashboard/common/DashboardLabel.jsx";

@autoredraw
export class DashboardLabels extends UI.Element {
    render() {
        return this.options.state.getLabels().map(label => <DashboardLabel label={label} autocolor/>);
    }
}

export class SDKResourceLabelEditor extends UI.Element {
    async promptLabelFilterPopup() {
        const {state, merchant} = this.options;
        const allLabels = GetLabelManager(merchant).getLabels();

        await PopupInput.prompt(
            LabelSetInput,
            {
                allLabels: allLabels,
                initialValue: state.getLabels(),
                editMode: true,
                onChange: (event, input) => {
                    this.options.state.editDashboardOptions({
                        labels: input.getValue(),
                    });
                }
            },
            {
                anchor: this.editLabelsIcon
            }
        );
    }

    render() {
        return <BlinkInputField label="Labels">
            <InputContainer>
                <EditIcon
                    ref="editLabelsIcon"
                    size={24}
                    onClick={() => this.promptLabelFilterPopup()}
                    style={{
                        cursor: "pointer",
                        marginRight: 6
                    }}
                />
                <DashboardLabels
                    ref="dashboardLabels"
                    state={this.options.state}
                    style={{display: "inline-block"}}
                />
            </InputContainer>
        </BlinkInputField>;
    }
}