import {MerchantUserJourneyStore} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {isDeepEqual, isString, unwrapArray} from "../../../../blinkpay/UtilsLib.js";
import {Dispatchable} from "../../../../stem-core/src/base/Dispatcher.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {apiEditMerchantSDKSettings} from "../../../../client/state/merchant/MerchantSDKSettingsStore.js";


// A journey that can be selected for an entrypoint. One per entrypoint, or multiple on A/B tests.
class JourneyEntrypointBranch {
    constructor(options, settingsVersion) {
        this.settingsVersion = settingsVersion;
        Object.assign(this, options);
    }

    // Ensure the journey exists in this settingsVersion
    static optionally(rawValue, settingsVersion) {
        if (!rawValue) {
            return null;
        }
        if (isString(rawValue)) {
            rawValue = {alias: rawValue};
        }
        const journey = MerchantUserJourneyStore.getByIdOrAlias(rawValue.alias, settingsVersion);
        return journey && new this({...rawValue, journey}, settingsVersion);
    }

    serialize() {
        let value = {
            alias: this.journey.alias || this.journey.id,
        }
        if (this.weight && this.weight !== 1) {
            value.weight = this.weight;
        }
        if (this.priority && this.priority !== 1) {
            value.priority = this.priority;
        }

        // Keeping this compact to minimize the SDK settings size
        if (Object.keys(value).length === 1) {
            return value.alias;
        }
        return value;
    }

    getJourney() {
        return this.journey;
    }
}


class UserJourneyEntrypoint {
    constructor(rawJourneyEntrypoint, settingsVersion) {
        this.settingsVersion = settingsVersion;

        rawJourneyEntrypoint = unwrapArray(rawJourneyEntrypoint);
        this.journeyBranches = rawJourneyEntrypoint.map(value => JourneyEntrypointBranch.optionally(value, this.settingsVersion));
        this.journeyBranches = this.journeyBranches.filter(Boolean); // drop nulls
    }

    addJourney(journey) {
        const branch = JourneyEntrypointBranch.optionally(journey, this.settingsVersion);
        this.journeyBranches.push(branch);
    }

    serialize() {
        const value = this.journeyBranches.map(branch => branch.serialize());
        return (value.length === 1) ? value[0] : value;
    }

    getJourneys() {
        return this.journeyBranches.map(branch => branch.getJourney());
    }

    numBranches() {
        return this.journeyBranches.length;
    }

    getNormalizedWeights() {
        const weights = this.journeyBranches.map(branch => branch.weight || 1);
        const sum = weights.reduce((s, weight) => s + weight, 0);
        return weights.map(w => w / sum);
    }

    // Journeys that can be added to this entrypoint
    getAvailableJourneys() {
        const journeys = this.getJourneys();
        return MerchantUserJourneyStore
            .filterBy({settingsVersion: this.settingsVersion})
            .filter(journey => journeys.indexOf(journey) === -1);
    }
}

// Keep an ordered list of JourneyEntrypoints
export class JourneyEntrypointState extends Dispatchable {
    constructor(settingsVersion) {
        super();
        this.settingsVersion = settingsVersion;
        this.load();
    }

    static isJourneyReferenced(settingsVersion, userJourney) {
        const state = new JourneyEntrypointState(settingsVersion);

        for (const entrypoint of state.entries) {
            if (entrypoint.getJourneys().indexOf(userJourney) === -1) {
                return true;
            }
        }

        return false;
    }

    load() {
        const rawEntrypoints = this.settingsVersion.options?.journeys || [];
        this.entries = rawEntrypoints.map(entry => new UserJourneyEntrypoint(entry, this.settingsVersion));
        this.dispatchChange();
    }

    serialize() {
        return this.entries.map(entry => entry.serialize());
    }

    isEqual(state) {
        return isDeepEqual(this.serialize(), state.serialize());
    }

    // Editing operations
    dispatchChange() {
        this.dispatch("change");
    }

    removeEntry(index) {
        this.entries.splice(index, 1);
        this.dispatchChange();
    }

    // Append a single journey
    appendEntrypoint(userJourney) {
        this.entries.push(new UserJourneyEntrypoint([userJourney], this.settingsVersion));
        this.dispatchChange();
    }

    addJourneyToEntrypoint(index, userJourney) {
        this.entries[index].addJourney(userJourney);
        this.dispatchChange();
    }

    // A bit different than the add one
    removeJourneyFromEntrypoint(journeyEntrypoint, journeyBranchIndex) {
        journeyEntrypoint.journeyBranches.splice(journeyBranchIndex, 1);
        this.dispatchChange();
    }

    swapEntries(indexA, indexB) {
        const temp = this.entries[indexA];
        this.entries[indexA] = this.entries[indexB];
        this.entries[indexB] = temp;
        this.dispatchChange();
    }

    moveUpEntry(index) {
        if (index > 0) {
            this.swapEntries(index - 1, index);
        }
    }

    moveDownEntry(index) {
        if (index + 1 < this.entries.length) {
            this.swapEntries(index, index + 1);
        }
    }

    // Same for all states
    isEdited() {
        const originalState = new JourneyEntrypointState(this.settingsVersion);
        return !this.isEqual(originalState);
    }

    discardChanges() {
        this.load();
    }

    @wrapInSpinner
    async saveChanges() {
        const newValue = this.serialize();
        const sdkSettings = this.settingsVersion;

        this.settingsVersion = await apiEditMerchantSDKSettings({
            sdkSettingsId: sdkSettings.id,
            options: {
                journeys: newValue,
            },
        });

        this.dispatchChange(); // No real change, only isEdited might be different now
    }
}
