import {UI} from "../../stem-core/src/ui/UIBase";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {loadBefore} from "../../blinkpay/ui/decorators/LoadBefore";
import {apiClient} from "../../client/connection/BlinkApiClient.js";
import {merchantService} from "../misc/MerchantService";
import {Money} from "../../stem-core/src/localization/Money.js";
import {StemDate} from "../../stem-core/src/time/Date";
import {formatDateRange} from "../common/Utils";
import {MakeSimpleSummaryTable} from "../common/SimpleSummaryTable";
import {SimpleTable} from "../ui/SimpleTable";
import {Chart} from "../ui/Chart";
import {DateRangeInput, DateRangeInterval, DefaultMerchantDateRangeIntervals} from "../ui/input/DateRangeInput";
import {MONEY_COLUMN} from "../common/theme/TableStyle";
import {DashboardTitle} from "../common/DashboardTitle";


class MerchantRevenueOverviewSection extends UI.Element {
    // TODO doesn't need to be here
    categories = [
        {
            name: "Purchases",
            value: "purchases",
            color: Theme.props.CHART_BAR_BACKGROUND_COLOR_1
        }, {
            name: "Donations",
            value: data => data.oneTimeDonations + data.recurringDonations,
            color: Theme.props.CHART_BAR_BACKGROUND_COLOR_2
        }, {
            name: "Subscriptions",
            value: data => data.digitalSubscriptions + data.printAndDigitalSubscriptions,
            color: Theme.props.CHART_BAR_BACKGROUND_COLOR_3
        }
    ];

    async makeQuery() {
        const {startDate, endDate} = this.dateRangeFilter.getValue();
        const request = {
            startDate: startDate.unix(),
            endDate: endDate.unix(),
        }

        // TODO: handle error
        this.lastResponse = await apiClient.get("/merchant/get_revenue_breakdown/", request);
        this.redraw();
    }

    getResultsTable() {
        if (!this.lastResponse) {
            return null;
        }

        const {distribution} = this.lastResponse;
        const chartData = this.getChartData();
        const currency = merchantService.getMerchant().getCurrency();

        const columns = [
            ["Type", item => item[0]],
            ["Users", item => item[1].distinctUsers],
            ["Payments", item => item[1].paymentsCount],
            ["Income", item => new Money(item[1].revenue, currency), MONEY_COLUMN],
        ];

        let totalRevenue = 0;
        for (const item of Object.values(distribution)) {
            totalRevenue += (item.revenue || 0);
        }

        const entries = [
            ["Individual purchases", distribution.purchases],
            ["One-time donations", distribution.oneTimeDonations],
            ["Recurring donations", distribution.recurringDonations],
            ["Digital subscriptions", distribution.digitalSubscriptions],
            ["Print & Digital subscriptions", distribution.printAndDigitalSubscriptions],
            [<strong>Total</strong>, {revenue: totalRevenue}],
            <tr>
                <td colspan={999}>
                    <Chart {...chartData} style={{marginTop: 16, marginBottom: 16}} valueType={Chart.ValueType.MONEY} />
                </td>
            </tr>,
        ];

        return <SimpleTable columns={columns} entries={entries} />;
    }

    getChartData() {
        if (!this.lastResponse) {
            return;
        }
        const {startDate, endDate} = this.dateRangeFilter.getValue();
        const data = this.lastResponse.dateHistogram;
        for (let item of data) {
            item.date = new StemDate(item.date);
        }
        return {
            data,
            startDate,
            endDate,
            categories: this.categories,
        };
    }

    render() {
        return [
            <DashboardTitle
                title="Overviews"
                description="Select a date range to see a breakdown of your revenue."
            />,
            <div>
                <DateRangeInput
                    ref="dateRangeFilter"
                    style={{maxWidth: 350, display: "inline-block", paddingBottom: 24}}
                    options={DefaultMerchantDateRangeIntervals(merchantService.getMerchant())}
                    selectedInterval={DateRangeInterval.LAST_30_DAYS}
                    onChange={() => this.makeQuery()}
                />
            </div>,
            this.getResultsTable(),
        ]
    }

    onMount() {
        this.makeQuery();
    }
}


@loadBefore(() => apiClient.get("/merchant/get_revenue_overview/"))
export class MerchantRevenuePage extends UI.Element {
    getGeneralTable() {
        const generalStats = this.loaderResponse;
        const currency = merchantService.getMerchant().getCurrency();

        const lastQuarterStartDate = new StemDate(generalStats.lastQuarterRange.startDate);
        const lastQuarterEndDate = new StemDate(generalStats.lastQuarterRange.endDate);
        const entries = [
            ["All time income", new Money(generalStats.allTime, currency)],
            ["Monthly average", new Money(generalStats.monthlyAvg, currency)],
            [`Last quarter income (${formatDateRange(lastQuarterStartDate, lastQuarterEndDate)})`, new Money(generalStats.lastQuarter, currency)],
            ["Total paying users", generalStats.usersCount],
        ];

        return MakeSimpleSummaryTable(entries);
    }

    render() {
        return [
            <DashboardTitle
                title="General"
                description="Aggregate statistics for your all time revenue"
            />,
            this.getGeneralTable(),
            <MerchantRevenueOverviewSection />,
        ]
    }
}
