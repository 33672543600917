import {UI} from "../../../stem-core/src/ui/UIBase";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {
    apiMerchantCreateSubscriptionDiscount,
    SubscriptionDiscountApplyPolicy,
    SubscriptionDiscountStore,
    SubscriptionDiscountType
} from "../../../client/state/SubscriptionDiscountStore";
import {CENTER_COLUMN, LEFT_COLUMN} from "../../common/theme/TableStyle";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {MerchantSubscriptionDiscountInstancePage} from "./MerchantSubscriptionDiscountInstancePage";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {BlinkInputField} from "../../common/Input";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {NumberInput} from "../../../stem-core/src/ui/input/Input";
import {Switcher} from "../../../stem-core/src/ui/Switcher";
import {DashboardMoneyInput, DashboardPercentInput} from "../../ui/input/DashboardMoneyInput";
import {SubscriptionDiscountScopeInput} from "./MerchantSubscriptionDiscountInstancePage";


class DiscountValueInput extends UI.Element {
    getValue() {
        const discountType = this.discountTypeInput.getValue();
        if (discountType === SubscriptionDiscountType.DISCOUNT_PERCENT) {
            return {discountPercent: String(this.percentOffInput.getValue())};
        }
        if (discountType === SubscriptionDiscountType.DISCOUNT_AMOUNT) {
            return {discountAmount: this.discountAmountInput.getValue(false)};
        }
        return {discountFixedPrice: this.fixedPriceInput.getValue(false)};
    }

    getTypeSpecificInput() {
        if (!this.percentAmountOption) {
            const {currency} = this.options;

            // TODO percent input type
            this.percentAmountOption = <BlinkInputField ref="1" label="Percent off">
                <DashboardPercentInput min={1} max={100} initialValue={10} ref="percentOffInput"/> %
            </BlinkInputField>

            this.amountOption = <BlinkInputField ref="2" label="Discount Amount">
                <DashboardMoneyInput currency={currency} ref="discountAmountInput"/>
            </BlinkInputField>

            this.fixedPriceOption = <BlinkInputField ref="3" label="New price">
                <DashboardMoneyInput currency={currency} ref="fixedPriceInput"/>
            </BlinkInputField>;
        }

        const selectedType = this.discountTypeInput?.getValue() ||  SubscriptionDiscountType.DISCOUNT_PERCENT;
        if (selectedType === SubscriptionDiscountType.DISCOUNT_PERCENT) {
            return this.percentAmountOption;
        }
        if (selectedType === SubscriptionDiscountType.DISCOUNT_AMOUNT) {
            return this.amountOption;
        }
        return this.fixedPriceOption;
    }

    refreshVisibility() {
        this.inputSwitcher.setActive(this.getTypeSpecificInput());
    }

    render() {
        return [
            <BlinkInputField label="Discount type">
                <Select
                    onChange={() => this.refreshVisibility()}
                    options={SubscriptionDiscountType.all()} ref="discountTypeInput"/>
            </BlinkInputField>,
            <Switcher style={{display: "inline-block"}} ref="inputSwitcher">
                {this.getTypeSpecificInput()}
            </Switcher>
        ]
    }
}

class CreateSubscriptionDiscountDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            title: "New subscription discount",
            description: "Once a discount is created its type and amount can no longer be edited.",
            confirmLabel: "Create",
        }
    }

    getValue() {
        return {
            name: this.nameInput.getValue(),
            code: this.codeInput.getValue() || null,
            ...this.discountValueInput.getValue(),
            ...this.discountScopeInput.getValue(),
            applyPolicy: this.discountApplyPolicyInput.getValue(),
            numCyclesDiscounted: this.numCycledDiscountedInput.getValue(),
            startDate: this.startDateInput?.getValue(),
            expireDate: this.expiryDateInput?.getValue(),
        }
    }

    render() {
        const {merchant} = this.options;
        const currency = merchant.getCurrency();

        return [
            <div>
                <BlinkInputField label="Name">
                    <TextInput
                        style={{width: 320}}
                        placeholder="" ref="nameInput"/>
                </BlinkInputField>
            </div>,

            <div>
                <BlinkInputField label="Promotional code (optional, case insensitive)">
                    <TextInput
                        style={{width: 320}}
                        placeholder="Entered by the customer in the checkout flow"
                        ref="codeInput"/>
                </BlinkInputField>
            </div>,

            <DiscountValueInput currency={currency} ref="discountValueInput"/>,

            <div>
                <BlinkInputField label="Maximum discounted billing cycles">
                    <NumberInput
                        style={{width: 180}}
                        placeholder="0 (no limit)"
                        min={0} max={1000} ref="numCycledDiscountedInput"/>
                </BlinkInputField>
                {/*TODO maybe have a checkbox "Discount applies to shipping costs" */}
                <BlinkInputField label="Discount applies">
                    <Select options={SubscriptionDiscountApplyPolicy.all()} ref="discountApplyPolicyInput"/>
                </BlinkInputField>
            </div>,

            <div>
                <BlinkInputField label="Scope">
                    <SubscriptionDiscountScopeInput merchant={merchant} ref="discountScopeInput"/>
                </BlinkInputField>
            </div>,

            // TODO implement start date and expiration
            // <div>
            //     <BlinkInputField label="Expiry">
            //         <OptionalDateInput ref="expiryDateInput" />
            //     </BlinkInputField>
            // </div>
        ]
    }
}


export class MerchantSubscriptionDiscountsPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: SubscriptionDiscountStore,
            title: "Subscription Discounts",
            description: "Discounts for your subscriptions, that you can manually apply, or that users can apply with a discount code.",
        }
    }

    async createDiscount() {
        const {merchant} = this.options;
        const request = await CreateSubscriptionDiscountDialog.prompt({merchant});
        if (!request) {
            return;
        }
        const subscriptionDiscount = await apiMerchantCreateSubscriptionDiscount({
            merchantId: merchant.id,
            ...request,
        });
        if (subscriptionDiscount) {
            this.goToEntry(subscriptionDiscount);
        }
    }

    render() {
        const {merchant} = this.options;
        const {selectedEntry} = this;
        if (selectedEntry) {
            return [
                this.renderTitle(selectedEntry.name, null),
                <MerchantSubscriptionDiscountInstancePage subscriptionDiscount={selectedEntry}/>
            ]
        }

        const subscriptionDiscounts = SubscriptionDiscountStore.filterBy({merchantId: merchant.id});

        const columns = [
            ["Id", discount => discount.id, LEFT_COLUMN],
            ["Name", discount => discount.name, LEFT_COLUMN],
            ["Promo code", discount => discount.code, LEFT_COLUMN],
            ["Discount value", discount => discount.getDiscountValue(), CENTER_COLUMN],
            ["Status", discount => discount.getStatus(), CENTER_COLUMN],
        ]

        return [
            this.renderTitle(),
            <div style={{paddingBottom: 12}}>
                <Button
                    onClick={() => this.createDiscount()}
                    label="New Subscription Discount"
                />
            </div>,
            this.makeTable(subscriptionDiscounts, columns),
        ]
    }
}
