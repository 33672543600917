import {UI} from "../../../../stem-core/src/ui/UIBase";
import {MerchantPanelStore} from "../../../../client/state/merchant/MerchantPanelStore";
import {PanelEditorInput} from "./PanelEditorInput.jsx";
import {styleRule} from "../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {StyleSheet} from "../../../../stem-core/src/ui/Style";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw";
import {DashboardLineSeparator} from "../../../ui/DashbordLineSeparator";
import {PanelEditorPreview} from "./PanelEditorPreview";
import {PanelEditorState} from "./PanelEditorState.js";
import {SaveOrDiscardButtons} from "../../../common/input/SaveOrDiscardButtons.jsx";
import {Theme} from "../../../../stem-core/src/ui/style/Theme";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives.jsx";
import {ToggleInput} from "../../../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";

class MerchantPanelEditorStyle extends StyleSheet {
    @styleRule
    descriptionContainer = {
        display: "flex",
    };

    @styleRule
    descriptionText = {
        flex: 1,
        paddingRight: "8px",
    };
}

@registerStyle(MerchantPanelEditorStyle)
@autoredraw(MerchantPanelStore)
export class PanelEditor extends UI.Element {
    setOptions(options) {
        super.setOptions(options);
        this.state = PanelEditorState.instantiate(this.options.panel, this);
    }

    render() {
        const {state, styleSheet} = this;
        const {panel, parent} = this.options;
        const parentPanel = state.getParentPanel();

        return [
            parent.renderTitle(panel.name, <div className={styleSheet.descriptionContainer}>
                <div className={styleSheet.descriptionText}>
                    <div>
                        This panel can be displayed in multiple contexts. The context represents where the panel should
                        appear on the page, or what should appear right around the panel. When configuring a user
                        journey to display a panel on your website, you also choose the context in which to display it.
                    </div>
                    <div style={{paddingTop: 6}}>
                        Options for panels are inherited from your base panel. If you don't
                        explicitly overwrite an option for this panel, changing that option in the base panel will also
                        influence this panel.
                    </div>
                </div>
            </div>),
            <div style={{marginBottom: 12, fontSize: 16}}>
                Panel alias: <span style={{fontFamily: Theme.props.FONT_FAMILY_MONOSPACE}}>{panel.alias}</span>
                <span>
                    {" — "}
                    {parentPanel ?
                        ["Based on ", <Link href={`/panels/${parentPanel.alias}`}>{parentPanel.name || parentPanel.alias}</Link>]
                        : "Standalone panel"
                    }
                </span>
            </div>,
            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{display: "flex", paddingRight: 6}}>
                    <ToggleInput
                        initialValue={state.advancedMode}
                        onChange={(active) => state.setAdvancedMode(active)}
                    />
                </span> Advanced
                <SaveOrDiscardButtons state={state}/>
            </div>,
            <PanelEditorInput
                state={state}
                stateKey="panelOptions"
                style={{paddingTop: 8}}
                codeEditorHeight={400}
            />,
            <DashboardLineSeparator text="PREVIEW"/>,
            <PanelEditorPreview state={state}/>
        ];
    }
}
