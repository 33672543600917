// The merchant list is either wrapped in a card or in a modal

import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {BaseCard, BaseCardStyle} from "../../../blinkpay/ui/BaseCard";
import {Messages} from "../../../blinkpay/Messages";
import {Modal} from "../../../blinkpay/ui/Modal.jsx";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {merchantService} from "../../misc/MerchantService.js";
import {MerchantStore} from "../../../client/state/MerchantStore.js";
import {SearchInput} from "../../ui/input/SearchInput.jsx";
import {DividerPositions, List} from "../../../blinkpay/ui/List.jsx";

class ChooseMerchantListStyle extends StyleSheet {
    @styleRule
    option = {
        padding: "12px 6px",
        cursor: "pointer",
        fontSize: 16,
        width: "fit-content",
    };

    @styleRule
    listContainer = {
        height: 480,
        padding: "8px 16x",
        overflow: "auto",
    }
}

@registerStyle(ChooseMerchantListStyle)
class ChooseMerchantList extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            nameFilter: "",
        };
    }

    @wrapInSpinner
    changeMerchant(merchant) {
        merchantService.setCurrentMerchantId(merchant.id);
        if (location.href.endsWith("/choose-merchant")) {
            // For some reason, this can be canceled in this event loop.
            location.assign("/");
        } else {
            location.reload();
        }
    }

    getOptionElement(merchant) {
        const {styleSheet} = this;

        return <div className={styleSheet.option} onClick={() => this.changeMerchant(merchant)}>
            {merchant}
        </div>
    }

    render() {
        const {styleSheet} = this;
        const {nameFilter} = this.options;

        const merchants = MerchantStore.all().sort((a,b) => {
            if (a.name < b.name) {
                return -1;
            }
            return 1;
        }).filter(merchant => {
            return merchant.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0;
        });

        return [
            <div>
                <SearchInput ref="merchantSearch"
                             inputAttributes={{placeholder: "Search merchant by name"}}
                             onChange={() => this.updateOptions({nameFilter: this.merchantSearch.getValue()})}/>
            </div>,
            <div className={styleSheet.listContainer}>
                <List dividerOptions={{position: DividerPositions.BOTTOM}}>
                    {merchants.map(merchant => this.getOptionElement(merchant))}
                </List>
            </div>
        ];
    }
}


class ChooseMerchantCardStyle extends BaseCardStyle {
    @styleRuleInherit
    container = {
        margin: "36px 0px",
        width: 360,
    };
}

@registerStyle(ChooseMerchantCardStyle)
export class ChooseMerchantCard extends BaseCard {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            title: Messages.chooseYourMerchant,
        };
    }

    render() {
        return <ChooseMerchantList />;
    }
}

export class ChooseMerchantModal extends Modal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            title: Messages.chooseYourMerchant,
        };
    }

    render() {
        return <ChooseMerchantList/>;
    }
}
