import {deepCopy} from "../../../../stem-core/src/base/Utils.js";
import {composePanelOptions, evaluatePanelOptions, findByIdOrAlias} from "../../../../blink-sdk/PanelOptionsLogic";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {apiCreateMerchantPanel, apiEditMerchantPanel} from "../../../../client/state/merchant/MerchantPanelStore.js";
import {getMerchantPanelsForVersion} from "./Helpers";
import {SDKResourceEditorState} from "../components/BaseEditorState.js";
import {initMerchantBuiltinFunctions} from "../../../../blink-sdk/modules/merchant-state/initMerchantBuiltinFunctions.js";
import {merchantBuiltinFunctions} from "../../../../blink-sdk/modules/merchant-state/Builtins.js";
import {MOBILE_DEVICE_SCREEN_WIDTH_LIMIT} from "../../../../blinkpay/Constants.js";
import {apiClient} from "../../../../client/connection/BlinkApiClient.js";

export class PanelEditorState extends SDKResourceEditorState {
    constructor(panel) {
        super();
        this.panel = panel;
        this.advancedMode = false;
        this.haveLoadedHistory = false;
        this.load();
        this.loadHistory().then();
    }

    getInitialPanelOptions(panel = this.panel) {
        return deepCopy({}, panel.options);
    }

    getInitialDashboardOptions(panel = this.panel) {
        return deepCopy({
            formFields: [],
            variables: {},
            height: 480,
            width: 720,
        }, panel.dashboardOptions);
    }

    load(panel = this.panel) {
        this.panelOptions = this.getInitialPanelOptions(panel);
        this.dashboardOptions = this.getInitialDashboardOptions(panel);
    }

    async loadHistory() {
        const {panel} = this;
        if (panel.isMocked) {
            return;
        }
        await apiClient.get("/merchant/panel_history", {
            sdkSettingsId: panel.settingsVersionId, // TODO Fucking bullshit ca trebuie sa dau asta Darius
            panelId: panel.id,
        });
        this.haveLoadedHistory = true;
        this.dispatchChange();
    }

    getParentPanel() {
        if (this.panel.alias === "base") {
            return null;
        }
        const {merchant, settingsVersion} = this.panel;
        const parentAlias = this.panelOptions.parentPanel || this.panelOptions.type || "base";
        const currentVersionPanels = getMerchantPanelsForVersion(merchant, settingsVersion);
        return findByIdOrAlias(currentVersionPanels, parentAlias);
    }

    composePanelOptions() {
        // TODO @cleanup @branch temp duplicated code
        const {panelOptions, dashboardOptions} = this;
        const {merchant, settingsVersion} = this.panel;
        const mockedPanel = {
            ...this.panel, // Simulate the panel (needed for alias at least)
            id: 0, // We want a unique reference
            options: {
                ...panelOptions,
                ...dashboardOptions.extraOptions,
            },
            dashboardOptions: dashboardOptions,
        }
        const merchantPanels = [
            ...getMerchantPanelsForVersion(merchant, settingsVersion),
            mockedPanel,
        ];

        return composePanelOptions({panel: mockedPanel.id}, merchantPanels, dashboardOptions?.variables);
    }

    getPreviewPanelOptions() {
        const {panelOptions, dashboardOptions} = this;
        const {merchant, settingsVersion} = this.panel;
        const mockedPanel = {
            ...this.panel, // Simulate the panel (needed for alias at least)
            id: 0, // We want a unique reference
            options: {
                ...panelOptions,
                ...dashboardOptions.extraOptions,
            },
            dashboardOptions: dashboardOptions,
        }
        const merchantPanels = [
            ...getMerchantPanelsForVersion(merchant, settingsVersion),
            mockedPanel,
        ];

        initMerchantBuiltinFunctions(); // TODO @cleanup temp hack
        merchantBuiltinFunctions.screenWidth = () => this.dashboardOptions.width;
        merchantBuiltinFunctions.isSmallScreen = () => this.dashboardOptions.width < MOBILE_DEVICE_SCREEN_WIDTH_LIMIT;

        return evaluatePanelOptions({panel: mockedPanel.id}, merchantPanels, dashboardOptions?.variables);
    }

    isEdited() {
        return !isDeepEqual(this.dashboardOptions, this.getInitialDashboardOptions())
            || !isDeepEqual(this.panelOptions, this.getInitialPanelOptions());
    }

    @wrapInSpinner
    async saveChanges() {
        const {panelOptions, dashboardOptions} = this;

        if (this.panel.isMocked) {
            // This is a fake panel, create it instead of updating.
            this.panel = await apiCreateMerchantPanel({
                sdkSettingsId: this.panel.settingsVersionId,
                name: this.panel.name,
                alias: this.panel.alias,
                options: panelOptions,
                dashboardOptions,
            });
        } else {
            this.panel = await apiEditMerchantPanel({
                sdkSettingsId: this.panel.settingsVersionId,
                panelId: this.panel.id,
                options: panelOptions,
                dashboardOptions,
            });
        }

        this.discardChanges(); // Reload and dispatch change
    }
}