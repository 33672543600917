import {
    MerchantMailChimpInterestStore,
    MerchantMailChimpUserListStore,
    apiSyncMailChimpUserList
} from "../../client/state/merchant/external-providers/MerchantMailChimpUserListStore";
import {UI} from "../../stem-core/src/ui/UIBase";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {DashboardDescription, DashboardTitle} from "../common/DashboardTitle";
import {SimpleTable} from "../ui/SimpleTable";
import {MerchantAudienceStore} from "../../client/state/merchant/MerchantAudienceStore";
import {Button} from "../../stem-core/src/ui/button/Button";
import {Duration, TimeUnit} from "../../stem-core/src/time/Duration";
import {StemDate} from "../../stem-core/src/time/Date";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {TaskStatus} from "../../client/state/misc/GenericEnums";
import {ProgressBar} from "../../stem-core/src/ui/ProgressBar.jsx";
import {Level} from "../../stem-core/src/ui/Constants";
import {
    apiMerchantEditPaymentProcessor,
    PaymentProcessorStore,
    PaymentProcessorType
} from "../../client/state/PaymentProcessorStore.js";
import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal.jsx";
import {DashboardLabel} from "../common/DashboardLabel.jsx";
import {Theme} from "../../stem-core/src/ui/style/Theme.js";
import {ExternalAppStore, ExternalAppType} from "../../client/state/ExternalAppConfigStore.js";
import {TextInput} from "../../stem-core/src/ui/input/Input.jsx";
import {BlinkInputField} from "../common/Input.jsx";
import {apiMerchantSyncExternalUser, MerchantUser, MerchantUserStore} from "../../client/state/merchant/MerchantUserStore.js";
import {Toast} from "../../blinkpay/ui/Toast.jsx";
import {UserDetailsModal} from "../common/UserDetails.jsx";


@autoredraw(MerchantMailChimpUserListStore)
class MailChimpUserListElement extends UI.Element {
    renderMergeTagBlinkValue(mergeTag) {
        if (mergeTag.blinkField === "email_preferences_url") {
            return "Email preferences link";
        }
        if (mergeTag.blinkField === "is_audience_member") {
            return [
                "Is member of the audience: ",
                <Link href={`/email-newsletters/${mergeTag.blinkFieldParam}`}>{MerchantAudienceStore.get(mergeTag.blinkFieldParam).name}</Link>
            ]
        }
        return null;
    }

    getDurationSinceLastUpdate() {
        return new Duration(StemDate.now() - this.options.mcUserList.lastSyncUpdatedAt);
    }

    shouldDisplaySubmitButton() {
        const {mcUserList} = this.options;
        if (mcUserList.lastSyncStatus !== TaskStatus.IN_PROGRESS) {
            return true;
        }
        return this.getDurationSinceLastUpdate() >= 5 * TimeUnit.MINUTE;
    }

    renderLastSyncStatus(mcUserList) {
        if (mcUserList.lastSyncStatus === TaskStatus.IN_PROGRESS) {
            const currentSyncDuration = new Duration(StemDate.now() - mcUserList.lastSyncStartedAt);
            return [
                <p>Synchronization {(mcUserList.lastSyncProgress * 100).toFixed(1)}% done in {currentSyncDuration} (started {mcUserList.lastSyncStartedAt})</p>,
                <ProgressBar level={Level.PRIMARY} value={mcUserList.lastSyncProgress} />,
                <p>Last updated {this.getDurationSinceLastUpdate()} ago</p>,
            ];
        }
        const lastSyncDuration = new Duration(mcUserList.lastSyncFinishedAt - mcUserList.lastSyncStartedAt);
        return <p>Last synchronization on {mcUserList.lastSyncStartedAt} {mcUserList.lastSyncStatus.value} in {lastSyncDuration.format({maxEntries: 3})}</p>;
    }

    render() {
        const {mcUserList} = this.options;

        const interestsColumns = [
            ["Interest name", interest => interest.interestName ?? interest.audience?.name],
            ["Interest category", () => mcUserList.interestCategoryTitle],
            ["Blink Audience", interest => <Link href={`/email-newsletters/${interest.audienceId}`}>{interest.audience.name}</Link>],
        ];

        const mergeTagColumns = [
            ["Name", mergeTag => mergeTag.name],
            ["Merge Tag", mergeTag => <span style={{fontFamily: Theme.props.FONT_FAMILY_MONOSPACE}}>{mergeTag.key}</span>],
            ["Default value", mergeTag => mergeTag.defaultValue ?? ""],
            ["Blink generated value", mergeTag => this.renderMergeTagBlinkValue(mergeTag)],
        ];

        return [
            <h2>Mail Chimp User list {mcUserList.name}</h2>,
            this.renderLastSyncStatus(mcUserList),
            this.shouldDisplaySubmitButton() && <Button onClick={() => apiSyncMailChimpUserList(mcUserList.id)} style={{marginTop: 8}}>
                Start new synchronization
            </Button>,
            <h3>Interests</h3>,
            <SimpleTable columns={interestsColumns} entries={MerchantMailChimpInterestStore.filterBy({userListId: mcUserList.id})}/>,
            <h3>Merge tags</h3>,
            <SimpleTable columns={mergeTagColumns} entries={mcUserList.mergeTags} />,
        ];
    }
}


@autoredraw
export class MerchantPaymentProcessorEditor extends UI.Element {
    renderStatusController() {
        const {paymentProcessor} = this.options;
        const statusLabel = paymentProcessor.isPublic ? "The payment gateway can be used for future payments. To stop accepting future payments, disable it." : "The payment gateway can no longer accept payments, but is used for legacy recurring payments.";
        const buttonLabel = paymentProcessor.isPublic ? "Disable" : "Enable";
        const toggleOptions = paymentProcessor.isPublic ? {
            isPublic: false,
            title: "Disable and hide the payment gateway?",
            description: "This payment gateway will still be active for existing recurring payments but will no longer be given as an option to new customers.",
        } : {
            isPublic: true,
            title: "Disable and hide the payment gateway?",
            description: "This payment gateway will become an option for new customers",
        }

        const toggleStatus = async () => {
            const confirm = await ConfirmationModal.prompt(toggleOptions);
            if (!confirm) {
                return;
            }
            await apiMerchantEditPaymentProcessor({
                paymentProcessorId: paymentProcessor.id,
                isPublic: toggleOptions.isPublic,
            });
        }

        return [
            <DashboardDescription>{statusLabel}</DashboardDescription>,
            <Button disabled={!paymentProcessor.isEditable} onClick={toggleStatus}>{buttonLabel}</Button>
        ];
    }

    renderPPDescription() {
        const {paymentProcessor} = this.options;
        if (paymentProcessor.provider === PaymentProcessorType.STRIPE) {
            return [
                <div>Public key: <code>{paymentProcessor.publicKey}</code>
                </div>
            ]
        }
        return null;
    }

    render() {
        const {paymentProcessor} = this.options;
        return [
            <div style={{display: "flex", alignItems: "baseline"}}>
                <DashboardTitle title={`${paymentProcessor.provider} payment gateway`}/>
                <DashboardLabel
                    level={paymentProcessor.isPublic ? Level.PRIMARY : Level.INFO}
                    style={{marginLeft: 8}}
                >
                    {paymentProcessor.isPublic ? "Active" : "Inactive"}
                </DashboardLabel>
            </div>,
            <div style={{margin: "8px 0"}}>
                {this.renderPPDescription()}
            </div>,

            this.renderStatusController(),
        ]
    }
}

class FulcoSyncInput extends UI.Element {
    async handleUserSync() {
        const {externalApp} = this.options;
        const accountId = this.accountIdInput?.getValue();
        if (!accountId) {
            return;
        }

        const response = await apiMerchantSyncExternalUser({
            provider: externalApp.provider,
            appId: externalApp.id,
            externalUserId: accountId,
        });

        const merchantUser = MerchantUserStore.loadObject(response);
        if (!merchantUser) {
            Toast.showError("Failed to load user");
            return;
        }

        UserDetailsModal.show({merchantUser})
    }

    render() {
        return <div>
            <BlinkInputField label="Fulco Account Id">
                <TextInput ref="accountIdInput" />
            </BlinkInputField>
            <Button onClick={() => this.handleUserSync()}>Sync</Button>
        </div>
    }
}

class ExternalAppIntegration extends UI.Element {
    render() {
        const {externalApp} = this.options;
        const syncTasks = [];
        return [
            <div style={{display: "flex", alignItems: "baseline"}}>
                <DashboardTitle title={`${externalApp.provider} integration`}/>
                <DashboardLabel
                    level={externalApp.isActive ? Level.PRIMARY : Level.INFO}
                    style={{marginLeft: 8}}
                >
                    {externalApp.isActive ? "Active" : "Inactive"}
                </DashboardLabel>
            </div>,

            (externalApp.provider === ExternalAppType.FULCO) && <FulcoSyncInput externalApp={externalApp} />,

            syncTasks.map(task => <div>This is a {task}</div>)
        ]
    }
}

export class MerchantIntegrationsPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        const sections = [];

        const externalApps = ExternalAppStore.filterBy({merchantId: merchant.id});
        sections.push(externalApps.map(externalApp => <ExternalAppIntegration externalApp={externalApp} />));

        const mailChimpUserLists = MerchantMailChimpUserListStore.filterBy({merchantId: merchant.id});
        sections.push(mailChimpUserLists.map(mcUserList => <MailChimpUserListElement mcUserList={mcUserList} />));

        const paymentProcessors = PaymentProcessorStore.filterBy({merchantId: merchant.id});
        sections.push(paymentProcessors.map(paymentProcessor => <MerchantPaymentProcessorEditor paymentProcessor={paymentProcessor} />));

        return [
            <DashboardTitle title="Integrations"/>,
            ...sections,
        ];
    }
}
