import {UI} from "../../stem-core/src/ui/UIBase";
import {EndpointPaginator} from "../../client/state/EndpointPaginator";
import {PaymentStore} from "../../client/state/PaymentStore";
import {SimpleTable} from "../ui/SimpleTable";
import {Money} from "../../stem-core/src/localization/Money.js";
import {StemDate} from "../../stem-core/src/time/Date";
import {Button} from "../../stem-core/src/ui/button/Button";
import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {MONEY_COLUMN} from "./theme/TableStyle";
import {DashboardLabel} from "./DashboardLabel";
import {Level} from "../../stem-core/src/ui/Constants";
import {MerchantReportType} from "../../client/state/merchant/MerchantReportStore.js";


class MerchantRefundPaymentConfirmation extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Refund payment",
            description: "Do you want to refund this payment back to the user though the payment method they originally used? This action is not reversible.",
            cancelLabel: "Cancel",
            confirmLabel: "Refund payment",
            confirmAction: () => this.refundPayment(),
        };
    }

    render() {
        const {payment} = this.options;

        return [
            // TODO: support to cancel the subscription here as well.
            <div>Note that this merely refunds the payment, and resource ownership (e.g. canceling the subscription) is handled manually.</div>,
        ]
    }

    async refundPayment() {
        const {payment} = this.options;

        await this.makeRequest("/merchant/revert_payment/", {
            paymentId: payment.id,
        });
    }
}


@autoredraw
class PaymentRefundButton extends UI.Element {
    render() {
        const {payment} = this.options;
        if (payment.isRefunded()) {
            return <DashboardLabel level={Level.INFO}>Refunded</DashboardLabel>
        }

        return <Button label="Refund" onClick={() => MerchantRefundPaymentConfirmation.show({payment})} />
    }
}

export class MerchantUserPaymentsTable extends UI.Element {
    paginator = new EndpointPaginator(PaymentStore, "/merchant/get_payments/");

    render() {
        const {merchantUser} = this.options;
        const {paginator} = this;
        paginator.updateFilter({
            merchantId: merchantUser.merchantId,
            userFilter: {
                type: MerchantReportType.USERS,
                userIds: [merchantUser.userId],
            },
        });

        const columns = [
            ["Date", payment => new StemDate(payment.createdAt)],
            ["Amount", payment => new Money(payment), MONEY_COLUMN],
            ["Type", payment => payment.getType()],
            ["Actions", payment => <PaymentRefundButton payment={payment} />],
        ]

        return <SimpleTable columns={columns} paginator={paginator}/>;
    }
}
