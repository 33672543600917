import {UI} from "../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {BlinkNumberInput, DashboardTimeDelta} from "./Base";
import {Button} from "../../stem-core/src/ui/button/Button";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {
    apiMerchantCancelSubscription,
    apiMerchantUpdateSubscriptionGracePeriod,
    apiMerchantProcessSubscriptionSentToOffline,
} from "../../client/state/SubscriptionStore";
import {DashboardStaticText} from "./DashboardStaticText";
import {MerchantUserStore} from "../../client/state/merchant/MerchantUserStore";
import {formatDateRange} from "./Utils.js";


export class MerchantCancelSubscriptionModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Cancel subscription",
            description: "Stop auto-renewal for this subscription.\n" +
                "It will still be active until the end of the paid period.",
            cancelLabel: "Back",
            confirmLabel: "Cancel future payments",
            confirmAction: () => this.cancelSubscription(),
        };
    }

    render() {
        const {subscription} = this.options;
        const merchantUser = subscription.getMerchantUser();
        return [
            <div>{merchantUser.getName()} — {merchantUser.getEmail()}</div>,
            <div>Subscription type: {subscription.coverage.name} - {subscription.formatPriceShortened()}</div>,
            <div>Paid until <strong>{subscription.getPaidUntil()}</strong></div>,
            <div style={{margin: "1em 0"}}>Make sure you want to cancel the subscription, as this action can only be undone by the customer.</div>,
        ]
    }

    async cancelSubscription() {
        const {subscription} = this.options;
        const request = {
            subscriptionId: subscription.id,
            merchantId: subscription.merchantId,
        }

        await this.makeRequest(apiMerchantCancelSubscription, request);
    }
}

// TODO the days and months are not calculated properly, just set grace end manually
export class UpdateSubscriptionGracePeriodModal extends ConfirmationModal {
    monthsInitialValue = 0;
    daysInitialValue = 0;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Edit subscription grace period",
            description: "Edit the complimentary period added to the subscription, on top of what the user already paid for. " +
                "This will also change the next billing date to match.",
            confirmLabel: "Confirm",
            confirmAction: () => this.updateGracePeriod(),
        };
    }

    getNewActiveUntil() {
        const {subscription} = this.options;
        let days = this.daysInput?.getValue();
        let months = this.monthsInput?.getValue();

        if (isNaN(days)) {
            days = this.daysInitialValue;
        }
        if (isNaN(months)) {
            months = this.monthsInitialValue;
        }
        const newActiveUntil = subscription.getPaidUntil().add({days, months});

        return newActiveUntil.equals(subscription.getPaidUntil()) ? null : newActiveUntil;
    }

    render() {
        const {subscription} = this.options;
        const diff = subscription.getActiveUntil().diff(subscription.getPaidUntil());
        // TODO breaking down in months and days has to be done in the same function call
        this.monthsInitialValue = diff.toMonths()
        this.daysInitialValue = diff.subtract(this.monthsInitialValue * TimeUnit.MONTH).toDays();

        const inputOptions = {
            onChange: () => this.redraw(),
            style: {
                width: 120,
                display: "inline-block",
            }
        };

        return [
            <div>
                <BlinkNumberInput initialValue={this.daysInitialValue} ref="daysInput" {...inputOptions} /> days.
            </div>,
            <div>
                <BlinkNumberInput initialValue={this.monthsInitialValue} ref="monthsInput" {...inputOptions} /> months.
            </div>,
            <div>{this.getGracePeriodDetailsLine()}</div>
        ]
    }

    getGracePeriodDetailsLine() {
        const {subscription} = this.options;
        const newActiveUntil = this.getNewActiveUntil();

        const gracePeriodDetails = newActiveUntil ? formatDateRange(subscription.getPaidUntil(), newActiveUntil) : "No grace period";

        return `Subscription grace period: ${gracePeriodDetails}.`
    }

    async updateGracePeriod() {
        const {subscription} = this.options;
        const newActiveUntil = this.getNewActiveUntil();
        const request = {
            subscriptionId: subscription.id,
            merchantId: subscription.merchantId,
            activeUntil: newActiveUntil,
        }

        await this.makeRequest(apiMerchantUpdateSubscriptionGracePeriod, request);
    }
}

@autoredraw
export class SubscriptionCancelButton extends Button {
    getDefaultOptions(options) {
        return {
            onClick: () => MerchantCancelSubscriptionModal.show({subscription: this.options.subscription}),
            label: "Cancel Subscription",
        }
    }

    redraw() {
        // TODO @Mihai how to better tie in disable without overwriting redraw?
        const {subscription} = this.options;
        this.options.disabled = subscription.isCanceled() || subscription.isFinished();
        super.redraw();
    }
}

export class SubscriptionEditGracePeriodButton extends Button {
    getDefaultOptions(options) {
        const subscription = options.subscription || this.options?.subscription;

        return {
            onClick: () => UpdateSubscriptionGracePeriodModal.show({subscription: this.options.subscription}),
            label: !subscription.isActive() ? "Reactivate & add grace period" :
                (subscription.hasGracePeriod() ? "Edit grace period" : "Add grace period")
        }
    }
}

@autoredraw()
class CDSOfflineResolver extends UI.Element {
    async refreshStatus() {
        const {subscription} = this.options;
        if (this.cdsResponse) {
            this.cdsResponse = null;
            this.redraw();
        }
        const response = await apiMerchantProcessSubscriptionSentToOffline({subscriptionId: subscription.id});
        if (!response.isSuccess) {
            this.cdsResponse = response.cdsResponse;
            this.redraw();
        } else {
            // TODO this would be easier with a Mobx-style reactive component
            // TODO @Mihai refactor when the subscription points to the merchantUser
            const merchantUser = MerchantUserStore.getByUserAndMerchant(subscription.userId, subscription.merchantId);
            merchantUser?.dispatch("change", {});
        }
    }

    render() {
        const {subscription} = this.options;
        const transactionId = this.constructor.getTransactionId(subscription);
        if (!transactionId) {
            return "CDS Offline resolved successfully!";
        }
        return [
            <p>
                Subscription sent to offline by CDS with transaction ID <code>{transactionId}</code>
            </p>,
            <div>
                <Button onClick={() => this.refreshStatus()}>Resync with CDS</Button>
            </div>,
            this.cdsResponse && <div>
                <p>Could not match order. Third party response:</p>
                <DashboardStaticText value={this.cdsResponse} type="json" />
            </div>
        ]
    }

    static getTransactionId(subscription) {
        const {externalProviderId} = subscription;
        if (!externalProviderId?.startsWith("cds-offline-")) {
            return null;
        }
        return externalProviderId.split("-")[3];
    }

    static optionally(subscription) {
        if (!this.getTransactionId(subscription)) {
            return null;
        }
        return <this subscription={subscription} />
    }
}

@autoredraw
export class SubscriptionDetailsPanel extends UI.Element {
    render() {
        const {subscription} = this.options;
        const {coverage, billingPlan, createdAt, status} = subscription;
        const discount = subscription.getDiscount();
        const deliveryAddress = subscription.getShippingAddress();

        return [
            <div>Subscription id {subscription.id}</div>,
            <div>Subscription tier: {coverage}</div>,
            // TODO Also say the plan name/id
            <div>
                Billing plan: {billingPlan.formatBasePrice()}
            </div>,
            <div>
                Created at {createdAt} ({new DashboardTimeDelta(createdAt)})
            </div>,
            <div>
                Status: {status}
            </div>,
            discount && <div>
                Discount applied: {discount.code} ({discount.getDiscountValue()})
            </div>,
            // TODO: Edit user address
            deliveryAddress && <div>
                Delivery address: {deliveryAddress.recipientName}, {deliveryAddress}
            </div>,
            <div>
                Paid until: {subscription.getPaidUntil()}
            </div>,
            subscription.hasGracePeriod() && <div>
                Grace period: {formatDateRange(subscription.getPaidUntil(), subscription.getActiveUntil())}
            </div>,
            <div>
                <SubscriptionCancelButton subscription={subscription}/>
                <SubscriptionEditGracePeriodButton subscription={subscription} />
            </div>,
            CDSOfflineResolver.optionally(subscription),
        ]
    }
}
