import {MultiselectInput} from "../MultiselectInput";
import {SubscriptionCoverageStore} from "../../../client/state/SubscriptionCoverageStore";
import {SubscriptionOfferStore} from "../../../client/state/SubscriptionOfferStore.js";
import {TreeViewCheckbox} from "../../../stem-core/src/ui/input/checkbox/TreeViewCheckbox.jsx";


export class SubscriptionCoverageMultiselectInput extends MultiselectInput {
    getAllOptions() {
        const {merchant, ignore, includeInactive} = this.options;
        let entries = SubscriptionCoverageStore.filterBy({merchantId: merchant.id}).filter(sub => sub != ignore);
        if (!includeInactive) {
            entries = entries.filter(entry => entry.isAvailable());
        }
        return entries;
    }

    getSelectedSubscriptionCoverageIds() {
        return this.getValue().map(coverage => coverage.id);
    }
}

export class SubscriptionOffersMultiselectInput extends TreeViewCheckbox {
    getDefaultEntries(options) {
        const {merchant, includeInactive, subscriptionOffers} = options;
        const subscriptionCoverages = SubscriptionCoverageStore.filterBy({merchantId: merchant.id});
        let entries = [];
        for (const coverage of subscriptionCoverages) {
            let coverageOffers = (subscriptionOffers || SubscriptionOfferStore.all())
                .filter(offer => offer.coverageId === coverage.id);
            if (!includeInactive) {
                coverageOffers = coverageOffers.filter(plan => plan.isAvailable());
            }
            if (coverageOffers.length) {
                entries.push({
                    label: `${coverage} — ${coverageOffers.length} plan${coverageOffers.length > 1 ? "s" : ""}`,
                    value: null, // Means ignored
                    children: coverageOffers.map(o => ({
                        label: o.formatBasePrice(),
                        value: o,
                        checked: true,
                    })),
                });
            }
        }

        return entries;
    }

    getDefaultOptions(options) {
        return {
            entries: this.getDefaultEntries(options),
        }
    }

    // TODO @cleanup a bit unclean how we preserve state here
    getPreservedOptions() {
        return {
            entries: this.options.entries
        };
    }

    allSelected() {
        return this.allChecked();
    }

    getSelectedSubscriptionOfferIds() {
        const selectedSubscriptionOffers = this.getValue();
        return selectedSubscriptionOffers.map(offer => offer.id);
    }
}
