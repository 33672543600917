import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {ProgressBar} from "../../../stem-core/src/ui/ProgressBar.jsx";
import {Level} from "../../../stem-core/src/ui/Constants.js";


class TaskProgressStatusDescriptorStyle extends StyleSheet {
    @styleRule
    container = {
        width: 480,
    }

    @styleRule
    messageArea = {
        fontWeight: "bold",
        display: "flex",
        alignItems: "center"
    }
}

@autoredraw
@registerStyle(TaskProgressStatusDescriptorStyle)
export class TaskProgressStatusDescriptor extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {status} = this.options;

        return [
            <div className={styleSheet.messageArea}>
                <div>{status.getStatusMessage()}</div>
                <div style={{flex: 1}}/>
                <div>{status.getProgressMessage()}</div>
            </div>,
            <ProgressBar level={status.errorMessage ? Level.PRIMARY : Level.ERROR} value={status.getProgressValue()}/>
        ];
    }
}

// export function RenderSummaryListEntry(entry, depth, {color=Theme.props.COLOR_PRIMARY}={}) {
//
// }
//
// export function RenderSummaryList(entries, depth = 0) {
//     entries.map(entry => {
//         if (Array.isArray(entry)) {
//             return RenderSummaryList(entries, depth + 1);
//         }
//     })
// }