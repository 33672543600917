import {StoreObject, MakeStore} from "../../../stem-core/src/state/Store";
import {apiClient} from "../../connection/BlinkApiClient.js";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {TaskStatus} from "../misc/GenericEnums";
import {FileResource} from "./FileResourceStore";


@makeEnum
export class MerchantReportType extends BaseEnum {
    static PAYMENTS;
    static PURCHASES;
    static SUBSCRIPTIONS;
    static DONATIONS;
    static DONORS;
    static USERS;
    static AUDIENCE_MEMBERS;
}


class MerchantReport extends StoreObject {
    @field(String) name;
    @field(MerchantReportType) type;
    @field(TaskStatus) status;
    @field(Object) filters;
    @field(Date) createdAt;
    @field(Number) fileSize;
    @field(FileResource) s3File;

    isSucceeded() {
        return this.status === TaskStatus.SUCCEEDED;
    }

    isInProgress() {
        return this.status === TaskStatus.IN_PROGRESS;
    }
}

export const MerchantReportStore = MakeStore("MerchantReport", MerchantReport);

export async function apiMerchantCreateReport(merchantId, type, filters) {
    const request = {
        merchantId,
        type,
        filters,
    }

    const response = await apiClient.post("/merchant/create_report", request);
    if (MerchantReportStore.loadObject(response)) {
        MerchantReportStore.dispatch("newOwnObject"); // Dispatch for the table to re-fetch
    }
    return response;
}

export async function apiMerchantDeleteReport(reportId) {
    return apiClient.post("/merchant/delete_report", {reportId});
}
