import {CodeEditor} from "../../stem-core/src/ui/CodeEditor.jsx";

export class HTMLEditor extends CodeEditor {
    oldInitialValue = null;

    applyAceOptions() {
        super.applyAceOptions();
        if (this.options.initialValue && this.options.initialValue !== this.oldInitialValue) {
            this.oldInitialValue = this.options.initialValue;
            this.setValue(this.options.initialValue, false);
        }
    }

    addAceChangeListener(callback) {
        return super.addAceChangeListener((...args) => {
            // Ignore when we're setting these values in code.
            // TODO: This should probably be in CodeEditor. CS Academy does the same thing on the listener side.
            if (!this.apiChange) {
                // In case there's an error in the callback, Ace breaks down
                setTimeout(() => callback(...args));
            }
        });
    }
}
