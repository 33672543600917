import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {DashboardMoneyInput} from "../../../ui/input/DashboardMoneyInput.jsx";
import {Money} from "../../../../stem-core/src/localization/Money.js";
import {Button} from "../../../../stem-core/src/ui/button/Button.jsx";
import {DashboardSection, DashboardDescription} from "../../../common/DashboardSection.jsx";
import {SimpleTable} from "../../../ui/SimpleTable.jsx";
import {TrashCanIcon} from "../../../SVGElements";
import {DashboardRadio} from "../../../../core/ui/input/radio/RadioListInput.jsx";


export class DonationAmountOptionsInput extends DashboardSection {
    getDefaultOptions() {
        return {
            title: "Amount options",
        }
    }

    render() {
        const {state} = this.options;
        const predefinedAmounts = state.value.predefinedAmounts;
        const currency = state.donationOffer.currency;

        const tableColumns = [
            ["Suggested amount", (value, index) => <DashboardMoneyInput
                initialValue={new Money(value, currency)}
                style={{width: 120}}
                onChange={(value) => {
                    state.setPredefinedAmount(index, value);
                }}
            />],
            ["Default", (value, index) => <div style={{display: "flex", margin: "auto"}}>
                <DashboardRadio
                    selected={state.value.defaultPredefinedAmountIndex === index}
                    onClick={() => state.update({defaultPredefinedAmountIndex: index})}
                />
                <TrashCanIcon
                    size={24}
                    style={{cursor: "pointer"}}
                    onClick={() => state.removePredefinedAmount(index)}
                />
            </div>]
        ];

        return [
            <CheckboxInput
                label="Allow custom amount"
                initialValue={state.value.allowCustomAmount}
                onChange={(value) => state.update({allowCustomAmount: value})}
            />,

            <DashboardDescription>
                Edit below the suggested amounts the user can choose. You can also edit which of these is selected by default.
            </DashboardDescription>,

            <SimpleTable
                style={{width: 240}}
                entries={predefinedAmounts}
                columns={tableColumns}
            />,

            <Button
                disabled={predefinedAmounts.length >= 9}
                onClick={() => state.addNewPredefinedAmount()}
                label="New Amount" />,
        ]
    }
}