import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";

@makeEnum
export class ExternalAppType extends BaseEnum {
    static FULCO;
    static CDS;
    static AMAZON_PAY;
}

class ExternalAppConfig extends StoreObject {
    @field(ExternalAppType) provider;
    @field(Boolean) isActive;
    @field(Boolean) isPublic;

    toString() {
        return String(this.provider);
    }
}

export const ExternalAppStore = MakeStore("ExternalAppConfig", ExternalAppConfig);
