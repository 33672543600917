import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {DashboardSectionTitle} from "../../../common/DashboardSection.jsx";

export class DonationOfferMiscInput extends UI.Element {
    render() {
        const {state} = this.options;

        return [
            <DashboardSectionTitle>
                General settings
            </DashboardSectionTitle>,

            <CheckboxInput
                label="Collect address"
                initialValue={state.value.collectAddress}
                onChange={(collectAddress) => state.update({collectAddress})}
            />
        ]
    }
}
