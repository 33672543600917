import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTablePage, MakeEmailEntry} from "../common/DashboardTablePage";
import {MerchantReportType} from "../../client/state/merchant/MerchantReportStore";
import {PaymentStore} from "../../client/state/PaymentStore";
import {BlinkInputField} from "../common/Input";
import {Money} from "../../stem-core/src/localization/Money.js";
import {MerchantUserStore} from "../../client/state/merchant/MerchantUserStore";
import {InfoBlockGroup} from "../common/InfoBlock";
import {DateRangeInput, DateRangeInterval, DefaultMerchantDateRangeIntervals} from "../ui/input/DateRangeInput";
import {merchantService} from "../misc/MerchantService";
import {MONEY_COLUMN, CENTER_COLUMN, DATE_COLUMN, LEFT_COLUMN} from "../common/theme/TableStyle";
import {InputContainer} from "../ui/input/Base";
import {CheckboxInput} from "../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {DashboardBaseInput} from "../../core/ui/input/BaseInput.js";
import {UserSearchInput} from "../ui/input/UserSearchInput.jsx";

// TODO this should use PaymentType enum
class PaymentTypeFilter extends DashboardBaseInput {
    render() {
        const onChange = () => this.dispatch("change", this, this.getValue());
        return <BlinkInputField label="Payment type">
            <InputContainer>
                <CheckboxInput ref="purchases" initialValue={true} onChange={onChange} label={"Purchases"} style={{marginRight: 20}}/>
                <CheckboxInput ref="donations" initialValue={true} onChange={onChange} label={"Donations"} style={{marginRight: 20}}/>
                <CheckboxInput ref="subscriptions" initialValue={true} onChange={onChange} label={"Subscriptions"}/>
            </InputContainer>
        </BlinkInputField>
    }

    getValue() {
        let result = [];

        if (this.purchases?.getValue()) result.push("purchase");
        if (this.donations?.getValue()) result.push("donation");
        if (this.subscriptions?.getValue()) result.push("subscription");

        return result;
    }
}


export class MerchantPaymentsPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: "Payments",
            store: PaymentStore,
            endpoint: "/merchant/get_payments/",
            reportType: MerchantReportType.PAYMENTS,
        }
    }

    getFilterSection() {
        return <div style={{display: "flex"}}>
            <UserSearchInput
                onChange={() => this.applyFilters()}
                ref="userSearchInput"/>

            <BlinkInputField label="Date Range" style={{flexShrink: 0}}>
                <DateRangeInput
                    ref="dateRangeFilter"
                    style={{maxWidth: 360}}
                    options={DefaultMerchantDateRangeIntervals(merchantService.getMerchant())}
                    selectedInterval={DateRangeInterval.LAST_30_DAYS}
                    onChange={() => this.applyFilters()}
                />
            </BlinkInputField>

            <PaymentTypeFilter style={{flexShrink: 0}}
                onChange={() => this.applyFilters()}
                ref={"paymentTypeFilter"} />
        </div>
    }

    getFilters() {
        const {startDate, endDate} = this.dateRangeFilter.getValue();
        return {
            userFilter: this.userSearchInput.getValue(),
            resourceTypes: this.paymentTypeFilter.getValue(),
            startDate,
            endDate,
        }
    }

    summaryContent(summaryLastResponse) {
        const {count, totalAmount, uniqueUsersCount, distribution} = summaryLastResponse;
        const {purchase, donation, subscription} = distribution

        return [
            <InfoBlockGroup label="Overall" entries={[
                ["payments", count],
                ["total amount", new Money(totalAmount, this.getCurrency())],
                ["unique users", uniqueUsersCount],
            ]}/>,
            <InfoBlockGroup label="Breakdown" entries={[
                ["purchases", purchase || 0],
                ["subscription payments", subscription || 0],
                ["donations", donation || 0],
            ]}/>
        ];
    }

    getColumns() {
        return [
            ["Email", payment => MakeEmailEntry(MerchantUserStore.getFromObject(payment)), LEFT_COLUMN],
            ["Name", payment => MerchantUserStore.getFromObject(payment).getName(), LEFT_COLUMN],
            ["Type", payment => payment.getType(), CENTER_COLUMN],
            ["Category", payment => payment.getCategory(), CENTER_COLUMN],
            ["Amount", payment => payment.getAmount(), MONEY_COLUMN],
            ["Date", payment => payment.createdAt, DATE_COLUMN],
        ]
    }
}
