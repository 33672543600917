import {UI} from "../../../stem-core/src/ui/UIBase";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore";
import {DashboardTablePage, MakeEmailEntry, DashboardTableColumnManager} from "../../common/DashboardTablePage";
import {
    AggregateDataViewManager,
    AnalyticsAnnotation,
    Annotation, ExtraColumnAnnotation,
    GroupByField
} from "../../common/AggregateDataViewManager";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {BlinkInputField} from "../../common/Input";
import {StemDate} from "../../../stem-core/src/time/Date";
import {InfoBlockGroup} from "../../common/InfoBlock";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../common/theme/TableStyle";
import {DateRangeInput, DefaultMerchantDateRangeIntervals, MakeMerchantAllTimeInterval} from "../../ui/input/DateRangeInput";
import {DonationStore} from "../../../client/state/DonationStore.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants";
import {RecurringPaymentStatusLabel} from "../subscriptions/MerchantSubscribersPage";
import {CycleUnit} from "../../../client/state/RecurringPaymentStoreObject";
import {AnalyticsEventType} from "../../../blink-sdk/utils/AnalyticsClient";
import {UserSearchInput} from "../../ui/input/UserSearchInput.jsx";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel.jsx";
import {JourneysFilter} from "../../common/JourneysFilter.jsx";
import {formatPercentFromRatio} from "../../../stem-core/src/base/Formatting.js";


export class MerchantDonationsPage extends DashboardTablePage {
    columnManager = new DashboardTableColumnManager("donations-table-columns", [
        ["Email", donation => MakeEmailEntry(MerchantUserStore.getFromObject(donation)), LEFT_COLUMN],
        ["Name", donation => MerchantUserStore.getFromObject(donation)?.getName(), LEFT_COLUMN],
        ["Amount", donation => donation.getPrice(), MONEY_COLUMN],
        ["Frequency", donation => donation.frequency.getFrequencyName()],
        ["Created At", donation => donation.createdAt, CENTER_COLUMN],
        ["Status", donation => donation.isRecurring() && <RecurringPaymentStatusLabel recurringPayment={donation} />, CENTER_COLUMN],
    ], {tablePage: this});

    groupsManager = new AggregateDataViewManager([
        new AnalyticsAnnotation("Flow starts", "flowStarts", AnalyticsEventType.FLOW_START.key, {
            flowType: PANEL_TYPE.donation,
        }),
        new ExtraColumnAnnotation("Conversion rate", entry => {
            if (!entry.flowStarts) {
                return "N/A";
            }
            return formatPercentFromRatio(Math.min(entry.count, entry.flowStarts + 1), entry.flowStarts);
        }),
        new Annotation("Total amount", "total_amount", "sum", "amount", value => new Money(value, this.getCurrency()), MONEY_COLUMN),
    ], [
        new GroupByField("Amount", "amount", value => new Money(value, this.getCurrency()), MONEY_COLUMN),
        new GroupByField("Frequency", "frequency", value => CycleUnit.fromValue(value)?.getFrequencyName() ?? value),
    ]);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Donations",
            description: "All donations. Users can appear multiple times, if they make multiple one time donations for instance.",
            store: DonationStore,
            endpoint: "/merchant/get_donations/",
            reportType: MerchantReportType.DONATIONS,
        }
    }

    getFilterSection() {
        const merchant = this.getMerchant();

        merchant.createdAt = new StemDate(2010, 0, 1); // TODO @cleanup meh

        return [
            <div>
                <UserSearchInput
                    ref="userSearchInput"
                    onChange={() => this.applyFilters()}/>

                <BlinkInputField label="Created at">
                    <DateRangeInput
                        ref="createdAtDateRangeInput"
                        style={{maxWidth: 350, display: "inline-block"}}
                        options={DefaultMerchantDateRangeIntervals(merchant)}
                        selectedInterval={MakeMerchantAllTimeInterval(merchant)}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>
            </div>,
            <div>
                <CollapsiblePanel collapsed={true} title="Filter by active journeys">
                    <JourneysFilter ref="journeysFilter" merchant={merchant} onChange={() => this.applyFilters()}/>
                </CollapsiblePanel>
            </div>,
        ]
    }

    getFilters() {
        return {
            userFilter: this.userSearchInput.getValue(),
            createdAt: this.createdAtDateRangeInput.valueOf(),
            metadata: this.columnManager.getMetadataFilters(),
            ...this.journeysFilter?.getValue(),
        };
    }

    summaryContent(summaryLastResponse) {
        const {count, statusDistribution, frequencyDistribution} = summaryLastResponse;

        return [
            <InfoBlockGroup label="Overview" entries={[
                ["Donations", count],
            ]} />,
            <InfoBlockGroup label="Status breakdown" entries={[
                ["One-time", statusDistribution.oneTime],
                ["Active", statusDistribution.active],
                ["Canceled", statusDistribution.canceled],
                ["Failed", statusDistribution.failed],
            ]} />,
            <InfoBlockGroup label="Frequency breakdown"
                            entries={CycleUnit.all().map(cycleUnit => [
                                cycleUnit.getFrequencyName(),
                                frequencyDistribution[cycleUnit.isOneTime() ? "oneTime" : cycleUnit.value],
                            ])} />,
        ];
    }
}
