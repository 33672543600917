import {UI} from "../../../../stem-core/src/ui/UI.js";
import {ConfirmationModal, ConfirmationModalStyle, InputConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal.jsx";
import {apiCreateMerchantUserJourney, apiDeleteMerchantUserJourney, MerchantUserJourneyStore} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {AVAILABLE_PANELS} from "../panels/Helpers";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../../../stem-core/src/decorators/Style.js";
import {apiCreateMerchantPanel, apiDeleteMerchantPanel, MerchantPanelStore} from "../../../../client/state/merchant/MerchantPanelStore.js";
import {isString} from "../../../../stem-core/src/base/Utils.js";
import {PANEL_TYPE} from "../../../../blink-sdk/Constants.js";
import {BlinkInput} from "../../../../blinkpay/ui/Input.jsx";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives.jsx";
import {JourneyEntrypointState} from "../active-journeys/JourneyEntrypointState.js";
import {DropdownInput} from "../../../../core/ui/input/dropdown/DropdownInput.jsx";
import {NON_BREAKING_SPACE} from "../../../../stem-core/src/base/Formatting.js";


export function generateAliasFromName(name) {
    if (!isString(name)) {
        return "";
    }
    return name.toLowerCase().split(/[^a-z0-9]/i).filter(Boolean).join("_");
}


export class CreateSDKResourceModal extends InputConfirmationModal {
    render() {
        return [
            super.render(),
            <BlinkInput
                ref="aliasInput"
                label="Enter a developer alias. One will be automatically generated from the name if you don't."
                initialValue=""
                inputAttributes={{placeholder: generateAliasFromName(this.input?.getValue() || "alias")}}
            />,
        ];
    }

    onMount() {
        super.onMount();
        this.input.input.addInputListener(() => this.aliasInput.input.updateOptions({
            placeholder: generateAliasFromName(this.input.getValue() || ""),
        }));
    }
}


export class CreatePanelModal extends CreateSDKResourceModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Create new panel",
            confirmLabel: "Create",
            inputLabel: "Enter a name for the new panel",
            inputPlaceholder: "Name",
            inputInitialValue: "",
            confirmAction: () => {
                const name = this.input.getValue();
                this.resolveWithAsyncAction(() => apiCreateMerchantPanel({
                    sdkSettingsId: this.options.sdkSettings.id,
                    name,
                    alias: this.aliasInput.getValue() || generateAliasFromName(name),
                    options: {
                        type: this.panelTypeInput.getValue(),
                    },
                }));
            },
        };
    }

    render() {
        return [
            super.render(),
            <DropdownInput
                ref="panelTypeInput"
                initialValue={PANEL_TYPE.custom}
                formatter={panelType => AVAILABLE_PANELS[panelType]}
                options={Object.keys(AVAILABLE_PANELS)}
            />,
        ];
    }
}


export class CreateUserJourneyModal extends CreateSDKResourceModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Create new user journey",
            description: "Create a new user journey. The newly created journey will initially be inactive so it can be edited. You can enable it when ready from the User Journeys page.",
            confirmLabel: "Create",
            inputLabel: "Enter a name for the new user journey",
            inputPlaceholder: "Name",
            inputInitialValue: "",
            confirmAction: () => {
                const name = this.input.getValue();
                this.resolveWithAsyncAction(() => apiCreateMerchantUserJourney({
                    sdkSettingsId: this.options.sdkSettings.id,
                    name,
                    alias: this.aliasInput.getValue() || generateAliasFromName(name),
                }));
            },
        };
    }
}

class DeleteConfirmationModalStyle extends ConfirmationModalStyle {
    @styleRule
    listItem = {
        padding: "8px 16px",
    };
}

@registerStyle(DeleteConfirmationModalStyle)
export class DeleteUserJourneyModal extends ConfirmationModal {
    getDefaultOptions(options) {
        const {userJourney} = options;
        return {
            ...super.getDefaultOptions(),
            title: `Delete user journey ${userJourney}`,
            description: "Are you sure you want to delete this user journey?",
            confirmLabel: "Delete",
            confirmAction: () => this.resolveWithAsyncAction(() => apiDeleteMerchantUserJourney({
                sdkSettingsId: this.options.userJourney.settingsVersionId,
                userJourneyId: this.options.userJourney.id,
            })),
        };
    }

    render() {
        const {userJourney} = this.options;
        const isReferenced = JourneyEntrypointState.isJourneyReferenced(userJourney.settingsVersion, userJourney);

        return isReferenced && <div className={this.styleSheet.bodyTextStyle}>
            This journey is among the currently active journeys, and deleting it might affect other journeys.
            We recommend going to the <Link href="/active-journeys">Active{NON_BREAKING_SPACE}Journeys</Link> page to manually remove this journey from active before deleting it.
        </div>;
    }
}

@registerStyle(DeleteConfirmationModalStyle)
export class DeletePanelModal extends ConfirmationModal {
    getDefaultOptions(options) {
        const {panel} = options;
        return {
            ...super.getDefaultOptions(),
            title: `Delete panel ${panel}`,
            description: "Are you sure you want to delete this panel?",
            confirmLabel: "Delete",
            confirmAction: () => this.resolveWithAsyncAction(() => apiDeleteMerchantPanel({
                sdkSettingsId: panel.settingsVersionId,
                panelId: panel.id,
            })),
        };
    }

    render() {
        const impactedPanels = MerchantPanelStore.filter(panel => panel.settingsVersionId === this.options.panel.settingsVersionId && panel.usesPanel(this.options.panel));
        const impactedUserJourneys = MerchantUserJourneyStore.filter(userJourney => userJourney.settingsVersionId === this.options.panel.settingsVersionId && userJourney.usesPanel(this.options.panel));
        if (!impactedPanels.length && !impactedUserJourneys.length) {
            return null;
        }
        return [
            <div className={this.styleSheet.bodyTextStyle}>
                This panel is used by the following panels or user journeys:
            </div>,
            impactedPanels.map(panel => <div className={this.styleSheet.listItem}>
                Panel <Link href={`/panels/${panel.id}`}>{panel}</Link>
            </div>),
            impactedUserJourneys.map(journey => <div className={this.styleSheet.listItem}>
                Journey <Link href={`/journeys/${journey.id}`}>{journey}</Link>
            </div>),
            <div className={this.styleSheet.bodyTextStyle}>
                Deleting it will invalidate journeys that use it, and panels that inherit from it will lose
                the inherited options. If this is not your intention, click the links above to update
                these panels and journeys manually before deleting this panel.
            </div>,
        ];
    }
}
