import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {Level} from "../../../stem-core/src/ui/Constants";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {
    apiEditMerchantUserJourney,
    MerchantUserJourneyStore
} from "../../../client/state/merchant/MerchantUserJourneyStore";
import {CreateUserJourneyModal, DeleteUserJourneyModal} from "./components/Modals.jsx";
import {InputConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {CENTER_COLUMN, LEFT_COLUMN, RESOURCE_ACTIONS_COLUMN} from "../../common/theme/TableStyle";
import {NoClickPropagate} from "../../ui/SimpleTable";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {
    getEditingOrLiveSettingsVersion,
    MerchantSDKSettingsStore
} from "../../../client/state/merchant/MerchantSDKSettingsStore";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {JourneyEditor} from "./journeys/JourneyEditor.jsx";
import {NON_BREAKING_SPACE} from "../../../stem-core/src/base/Formatting.js";
import {LabelFilter} from "../../ui/LabelFilter.jsx";
import {DashboardLabel} from "../../common/DashboardLabel.jsx";


@autoredraw(MerchantUserJourneyStore, MerchantSDKSettingsStore)
export class MerchantJourneysPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: MerchantUserJourneyStore,
            title: "Journeys",
            useAliasInURL: true,
            description: () => <div>
                Edit your user journeys here. A user journey consists of an action (usually displaying a <Link href="/panels">panel</Link>)
                and a condition that the user must meet for that action to happen. After configuring your user journeys, you can
                choose which of them are enabled on the <Link href="/active-journeys">Active{NON_BREAKING_SPACE}Journeys</Link> page.
            </div>,
        }
    }

    async promptCreateJourneyModal() {
        const journey = await CreateUserJourneyModal.prompt({
            sdkSettings: getEditingOrLiveSettingsVersion(this.options.merchant),
        });
        if (journey != null) {
            this.goToEntry(journey);
        }
    }

    async promptDeleteJourneyModal(userJourney) {
        await DeleteUserJourneyModal.prompt({userJourney});
    }

    async promptRenameJourneyModal(userJourney) {
        const response = await InputConfirmationModal.prompt({
            title: `Rename user journey ${userJourney}`,
            inputLabel: "Enter a new name for this user journey",
            inputPlaceholder: "Name",
            inputInitialValue: userJourney.name,
            confirmAction: (modal) => modal.resolveWithAsyncAction(() => apiEditMerchantUserJourney({
                sdkSettingsId: userJourney.settingsVersionId,
                userJourneyId: userJourney.id,
                name: modal.input.getValue(),
            })),
        });
        if (response != null) {
            this.redraw();
        }
    }

    // TODO Deduplicate some label logic
    renderLabels(userJourney) {
        const labels = userJourney.dashboardOptions.labels;
        return [
            labels && <div>
                {labels.map(label => <DashboardLabel label={label} autocolor />)}
            </div>,
        ]
    }

    render() {
        if (this.selectedEntry) {
            return <JourneyEditor parent={this} userJourney={this.selectedEntry}/>;
        }

        const labelFilterOptions = {
            merchant: this.options.merchant,
        }

        const allEntries = MerchantUserJourneyStore.filterBy({
            settingsVersionId: getEditingOrLiveSettingsVersion(this.options.merchant).id,
        });

        const filteredEntries = LabelFilter.filterEntries(allEntries, this.labelFilter, labelFilterOptions);

        const columns = [
            ["Name", userJourney => userJourney.name, LEFT_COLUMN],
            ["Alias", userJourney => userJourney.alias],
            ["Type", userJourney => this.renderLabels(userJourney), CENTER_COLUMN],
            ["Actions", userJourney => <NoClickPropagate>
                <Button
                    label="Rename"
                    onClick={() => this.promptRenameJourneyModal(userJourney)}
                />
                <Button
                    label="Delete"
                    level={Level.SECONDARY}
                    onClick={() => this.promptDeleteJourneyModal(userJourney)}
                />
            </NoClickPropagate>, RESOURCE_ACTIONS_COLUMN],
        ];

        return [
            this.renderTitle(),
            <div style={{paddingBottom: 12}}>
                <LabelFilter
                    ref="labelFilter"
                    {...labelFilterOptions}
                    onChange={() => this.redraw()}
                    style={{display: "inline-block"}}
                />
                <Button
                    label="New journey"
                    onClick={() => this.promptCreateJourneyModal()}
                />
            </div>,
            this.makeTable(filteredEntries, columns),
        ];
    }
}
