import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {BlinkSDKInputField, SDKArrayInput} from "../../components/Primitives.jsx";
import {SubscriptionOfferStore} from "../../../../../blinkpay/State";
import {VisibilityStatus} from "../../../../../client/state/misc/GenericEnums";
import {MerchantAudienceStore} from "../../../../../client/state/merchant/MerchantAudienceStore";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {DropdownInput} from "../../../../../core/ui/input/dropdown/DropdownInput.jsx";


@registerStyle(PanelEditorTabStyle)
export class PanelOffersEditor extends UI.Element {
    render() {
        const {state} = this.options;
        const {merchantId} = state.panel;

        const subscriptionOffers = SubscriptionOfferStore.filterBy({merchantId: merchantId})
            .filter(({visibility}) => visibility === VisibilityStatus.HIDDEN || visibility === VisibilityStatus.PUBLIC);
        const newsletterOffers = MerchantAudienceStore.filterBy({merchantId: merchantId, isPublic: true});

        return [
            <BlinkSDKInputField
                label="Subscription offers"
                optional
                stateKey={["panelOptions", "subscriptionOffer"]}
                state={state}
                InputClass={SDKArrayInput}
                inputOptions={{
                    box: false,
                    addButtonLabel: "Add subscription offer",
                    ElementInputClass: DropdownInput,
                    elementInputOptions: {
                        formatter: id => SubscriptionOfferStore.get(id),
                        options: subscriptionOffers.map(offer => offer.id),
                    }
                }}/>,

            <div />, // To align them on top of another

            <BlinkSDKInputField
                label="Newsletters"
                optional
                stateKey={["panelOptions", "newsletter"]}
                state={state}
                InputClass={SDKArrayInput}
                inputOptions={{
                    box: false,
                    addButtonLabel: "Add newsletter",
                    ElementInputClass: DropdownInput,
                    elementInputOptions: {
                        formatter: id => MerchantAudienceStore.get(id),
                        options: newsletterOffers.map(offer => offer.id),
                    }
                }}/>,
        ]
    }
}
