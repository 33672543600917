import {UI} from "../../stem-core/src/ui/UIBase";
import {CheckboxInput} from "../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";

export class MultiselectInput extends UI.Element {
    getAllOptions() {
        return this.options.options;
    }

    getValue() {
        return this.getAllOptions().filter((entry, index) => this["checkbox" + index].getValue());
    }

    setValue(values) {
        const valuesSet = new Set(values || []);
        this.getAllOptions().forEach((entry, index) => {
            this["checkbox" + index].setValue(valuesSet.has(entry));
        });
    }

    allSelected() {
        return this.getAllOptions().length === this.getValue().length;
    }

    render() {
        return this.getAllOptions().map((entry, index) => <div>
            <CheckboxInput
                label={entry}
                onChange={() => this.dispatch("change")}
                ref={"checkbox" + index}
            />
        </div>)
    }

    onMount() {
        const {initialValue} = this.options;
        this.setValue(initialValue);
    }
}
