import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardModal} from "./modals/DashboardModal";
import {apiClient} from "../../client/connection/BlinkApiClient.js";
import {PaymentStore} from "../../client/state/PaymentStore.js";
import {DonationStore} from "../../client/state/DonationStore.js";
import {SubscriptionStore} from "../../client/state/SubscriptionStore.js";
import {Panel, Link} from "../../stem-core/src/ui/UIPrimitives";
import {SubscriptionDetailsPanel} from "./SubscriptionDetails";
import {DonationDetailsPanel} from "./DonationDetails";
import {MerchantUserPaymentsTable} from "./MerchantUserPaymentsTable";
import {TopLevelTabArea} from "./theme/TabAreaStyle";
import {Button} from "../../stem-core/src/ui/button/Button";
import {MerchantGrantSubscriptionModal} from "./modals/MerchantGrantSubscriptionModal";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {SocialAccountProvider, SocialAccountStore} from "../../client/state/SocialAccountStore";
import {DashboardStaticText} from "./DashboardStaticText";
import {LoadingSpinner, wrapInSpinner} from "../../core/ui/LoadingSpinner.jsx";
import {
    apiMerchantDisableUser,
    apiMerchantEditUser,
    apiMerchantGenerateUserToken, apiMerchantLogoutUser,
    MerchantUserSessionStore
} from "../../client/state/merchant/MerchantUserStore.js";
import {base64Encode, capitalize} from "../../blinkpay/UtilsLib.js";
import {CopyTextToClipboard} from "./Utils.js";
import {MakeInfoElement} from "./InfoTooltip.jsx";
import {ExternalLinkIcon, EditIcon} from "../../core/ui/SVGElements.jsx";
import {EmailInput} from "../../stem-core/src/ui/input/Input.jsx";
import {apiMerchantSyncExternalUser} from "../../client/state/merchant/MerchantUserStore.js";
import {Table} from "../../stem-core/src/ui/table/Table.jsx";
import {Theme} from "../../stem-core/src/ui/style/Theme.js";
import {BorderedLabel} from "./BorderedLabel.jsx";
import {ExternalAppType} from "../../client/state/ExternalAppConfigStore.js";


export function MakeDeviceInfoLabel(label) {
    const options = {
        borderColor: Theme.props.INPUT_BORDER_COLOR,
        style: {
            margin: "3px",
            borderRadius: 2,
            whiteSpace: "nowrap",
        }
    }
    return <BorderedLabel {...options}>{label || "Unknown"}</BorderedLabel>
}

export function RenderSessionDeviceInfo(deviceInfo) {
    return deviceInfo && <div style={{textAlign: "center"}}>
        {MakeDeviceInfoLabel(capitalize(deviceInfo.type))}
        {MakeDeviceInfoLabel(deviceInfo.os)}
        {MakeDeviceInfoLabel(deviceInfo.browser)}
    </div>
}

export function RenderGeoIPInfo(geoIPInfo) {
    return geoIPInfo && <div>
        {geoIPInfo.ip} {[location.city, location.region, location.countryCode].filter(x => !!x).join(", ")}
    </div>
}


@autoredraw(SubscriptionStore)
export class UserSubscriptionsList extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            merchantUser: null,
        }
    }

    render() {
        const subscriptions = SubscriptionStore.filterBy({userId: this.options.merchantUser.userId});

        // Current and past subscriptions
        let listItems = subscriptions.map(subscription =>
            [
                <SubscriptionDetailsPanel subscription={subscription} merchantUser={this.options.merchantUser}/>,
                <hr/>,
            ]
        );
        if (listItems.length == 0) {
            listItems = [
                <div>
                    <strong>No past or current subscriptions to show.</strong>
                    <Button
                        onClick={() => MerchantGrantSubscriptionModal.show({merchantUser: this.options.merchantUser})}>
                        Grant subscription
                    </Button>
                </div>,
                <hr/>
            ];
        }

        return listItems;
    }
}


@autoredraw
class CDSAccountTabPanel extends Panel {
    getTitle() {
        const {socialAccount} = this.options;
        return `CDS ${socialAccount.getCDSProductId()} - ${socialAccount.getCDSAccountNumber()}`;
    }

    @wrapInSpinner
    async resyncCDSProfile() {
        const {socialAccount} = this.options;
        const response = await apiMerchantSyncExternalUser({
            provider: ExternalAppType.CDS,
            appId: socialAccount.getCDSProductId(),
            externalUserId: socialAccount.getCDSAccountNumber(),
        });
    }

    render() {
        // TODO add a button to refresh this user here
        const {socialAccount} = this.options;
        return [
            <h2>CDS Profile <Button label="Resync" onClick={() => this.resyncCDSProfile()}/></h2>,
            <DashboardStaticText type="json" value={socialAccount.extraData}/>
        ]
    }
}


class FulcoSocialAccountTabPanel extends Panel {
    getTitle() {
        // const {socialAccount} = this.options;
        return "External Data";
    }

    @wrapInSpinner
    async resyncFulcoProfile() {
        const {socialAccount} = this.options;
        const response = await apiMerchantSyncExternalUser({
            provider: socialAccount.provider,
            externalUserId: socialAccount.extraData.id,
        });
    }
    render() {
        const {socialAccount} = this.options;
        return [
            <h2>Fulco Profile <Button label="Resync" onClick={() => this.resyncFulcoProfile()}/></h2>,
            <DashboardStaticText type="json" value={socialAccount.extraData}/>
        ];
    }
}

function GenerateUserTokenHelp() {
    return MakeInfoElement(() => <div>
        <p>Generates a link to set the authentication cookie as if logged in with this user on your website.</p>
        <p>The user that generated the original session is stored in the session information.</p>
        <p>Open in a private/incognito browsing session for best use.</p>
        <p><b>Warning:</b> Use for customer support on the user's behalf. All actions available to the user are
            possible, including purchases, with the exception of auto-payments (should these be enabled).</p>
    </div>)
}

export function MakeExternalLinkWithCopy(url, text) {
    return [
        <Link newTab href={url}><ExternalLinkIcon /> {text} (link)</Link>,
        <Button onClick={() => CopyTextToClipboard(url)}>Copy link</Button>,
    ]
}

export class MerchantUserEmailOptionalEdit extends UI.Element {
    editMode = false;

    extraNodeAttributes(attr) {
        attr.setStyle({
            display: "flex",
            alignItems: "center"
        })
    }

    setEditMode(editMode) {
        this.editMode =  editMode;
        this.redraw();
    }

    async changeEmail() {
        const {merchantUser} = this.options;
        const emailAddress = this.emailInput.getValue().trim();
        if (emailAddress != merchantUser.getEmail()) {
            await apiMerchantEditUser(merchantUser, {emailAddress});
        }
        this.setEditMode(false);
    }

    render() {
        const {merchantUser} = this.options;
        if (!this.editMode) {
            return [
                "Email ", merchantUser.getEmail(),
                <EditIcon style={{cursor: "pointer", marginLeft: 6}} size="1.5em" onClick={() => this.setEditMode(true)} />
            ]
        }
        return [
            "Email ",
            <EmailInput ref="emailInput" initialValue={merchantUser.getEmail()} />,
            <Button onClick={() => this.changeEmail()}>Update</Button>,
            <Button onClick={() => this.setEditMode(false)}>Cancel</Button>
        ]
    }
}


@autoredraw
export class UserDetailsPanel extends UI.Element {
    getMerchantUser() {
        return this.options.merchantUser;
    }

    getTitle() {
        const {merchantUser} = this.options;
        const emailAddress = merchantUser.getEmail();
        const userId = merchantUser.userId;

        // TODO have a link here to show this in it's own page if in a modal
        return <div style={{textAlign: "center", fontSize: 20, fontWeight: 300}}>
            {merchantUser.getName()} | {emailAddress} | {userId}
        </div>
    }

    async generateUserToken() {
        const {merchantUser} = this.options;

        // Put a temporary field on the merchant user
        merchantUser.merchantUserSession = await apiMerchantGenerateUserToken({userId: merchantUser.userId});
        this.redraw();
    }

    getUserTokenLink() {
        const {merchantUser} = this.options;
        const {merchantUserSession} = merchantUser;
        const cookieToken = merchantUserSession?.key && {
            t: merchantUserSession.key,
            e: merchantUserSession.expiresAt,
        };

        if (cookieToken) {
            const merchantURL = new URL(merchantUser.merchant.getUrl());
            // TODO @Mihai it's blinkAction, as God intends it
            merchantURL.searchParams.set("blinkaction", `setToken\!${base64Encode(cookieToken)}`);

            return [
                MakeExternalLinkWithCopy(merchantURL.toString(), "Set user session cookie"),
                GenerateUserTokenHelp(),
            ];
        }

        return [<Button onClick={() => this.generateUserToken()}>Login as this user</Button>, GenerateUserTokenHelp()];
    }

    renderUserSessions() {
        const {sessions} = this;
        if (!sessions || sessions.length === 0) {
            return null;
        }

        const activeSessions = sessions.filter(session => !session.revokedAt);

        const logoutUser = async (userSession) => {
            if (!userSession) {
                const {merchantUser} = this.options;
                userSession = {userId: merchantUser.userId};
            }
            await apiMerchantLogoutUser(userSession);
            this.redraw();
        }

        const columns = [
            ["Created at", session => session.createdAt],
            ["GeoIP", session => RenderGeoIPInfo(session.location)],
            ["Device", session => RenderSessionDeviceInfo(session.device)],
            ["Status", session => {
                if (session.revokedAt) {
                    return `Logout at ${session.revokedAt}`;
                }
                return <Button label="Logout" onClick={() => logoutUser(session)} />;
            }],
        ]

        return [
            <h3>
                Sessions {activeSessions.length > 0 && <Button label="Logout all active" onClick={() => logoutUser()} />}
            </h3>,
            <Table entries={sessions} columns={columns} />
        ]
    }

    renderUserActiveStatus() {
        const {merchantUser} = this.options;
        const {isActive} = merchantUser;

        const flipUserDisabled = async () => {
            const response = await apiMerchantDisableUser(merchantUser, !isActive);
        }

        return <div>
            User status: {isActive ? "Active" : "Disabled"} <Button onClick={flipUserDisabled} label={isActive ? "Disable" : "Activate"} />
            {MakeInfoElement("Designates if the user can login. ")}
        </div>
    }

    getUserSummary() {
        const {merchantUser} = this.options;

        const lifetimeRevenue = merchantUser.getLifetimeRevenue();

        return [
            <div>
                Name: {merchantUser.getName()}
            </div>,
            <MerchantUserEmailOptionalEdit merchantUser={merchantUser}/>,
            <div>
                ID: {merchantUser.userId}
            </div>,
            <div>
                Lifetime revenue: <strong>{lifetimeRevenue}</strong>
            </div>,
            <div>
                First authenticated: {merchantUser.consentDate}
            </div>,
            <div>
                Last accessed: {merchantUser.lastAccessDate || "Never"}
            </div>,
            <div>
                {this.getUserTokenLink()}
            </div>,
            <div>
                {MakeExternalLinkWithCopy(merchantUser.getEmailPreferencesLink(), "Email preferences control")}
            </div>,
            this.renderUserSessions(),
            this.renderUserActiveStatus(),
        ]
    }

    getDonations() {
        const donations = DonationStore.load(this.response);
        // TODO: first show active recurring (if exists) and the sort descending by date
        let donationsPanel = donations.map(donation => [
            <DonationDetailsPanel donation={donation} merchantUser={this.getMerchantUser()}/>,
            <hr/>
        ]);
        if (donations.length == 0) {
            donationsPanel = <strong>The user has not donated yet.</strong>
        }
        // TODO: add bookkeeping option to manually enter donation
        return donationsPanel;
    }

    getPayments() {
        const {merchantUser} = this.options;
        return <MerchantUserPaymentsTable merchantUser={merchantUser} />
    }

    getSocialAccountTabs() {
        const {merchantUser} = this.options;
        const socialAccounts = SocialAccountStore.filterBy({userId: merchantUser.userId});

        return socialAccounts.map(socialAccount => {
            if (socialAccount.provider === SocialAccountProvider.CDS) {
                return <CDSAccountTabPanel socialAccount={socialAccount} />
            }
            if (socialAccount.provider === SocialAccountProvider.FULCO) {
                return <FulcoSocialAccountTabPanel socialAccount={socialAccount} />
            }
            return null;
        })
    }

    render() {
        if (this.error) {
            return (
                <div>
                    <h1>Error loading user data: {this.error.message}</h1>
                </div>
            );
        }

        if (!this.response) {
            return <LoadingSpinner />;
        }

        return [
            this.getTitle(),
            <TopLevelTabArea style={{height: "100%"}}>
                <Panel title="Summary">{this.getUserSummary()}</Panel>
                <Panel title="Subscriptions">
                    <UserSubscriptionsList merchantUser={this.getMerchantUser()}/>
                </Panel>
                <Panel title="Donations">{this.getDonations()}</Panel>
                <Panel title="Payments & Refunds">{this.getPayments()}</Panel>
                {this.getSocialAccountTabs()}
            </TopLevelTabArea>
        ]
    }

    processResponse(response) {
        this.response = response;
        this.subscriptions = SubscriptionStore.load(this.response);
        this.donations = DonationStore.load(this.response);
        this.payments = PaymentStore.load(this.response);
        this.socialAccounts = SocialAccountStore.load(this.response);
        this.sessions = MerchantUserSessionStore.load(this.response);
        this.redraw();
    }

    //@onMount -- TODO: have decorator?
    async loadState() {
        const merchantUser = this.getMerchantUser();
        try {
            const request = {
                userId: merchantUser.userId,
                merchantId: merchantUser.merchantId,
            }
            this.response = await apiClient.get("/merchant/get_user_data", request);
        } catch (error) {
            this.error = error;
            this.redraw();
            return;
        }

        this.processResponse(this.response);
    }

    onMount() {
        super.onMount();
        this.loadState();
    }
}


export class UserDetailsModal extends DashboardModal {
    render() {
        // TODO: have a link to open the panel in a separate tab (see code above)
        return [
            <UserDetailsPanel {...this.options} style={{minWidth: "80%", height: 600}}/>
        ];
    }
}
